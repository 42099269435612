import { createSlice } from '@reduxjs/toolkit';

export const userUpdateSlice = createSlice({
    name: 'userUpdate',
    initialState: {
        data: null,
    },
    reducers: {
        setUserUpdate: (state, { payload }) => {
            state.data = payload;
        },
    },
});
export const { setUserUpdate } = userUpdateSlice.actions;

export default userUpdateSlice.reducer;
