import React from 'react';
import './tournament-bg.scss';

const TournamentBg = ({ children }) => {
    return (
        <div className="tournament-bg">
            <div className="tournament-bg-pattern">
                <div className="tournament-bg-block"></div>
                {children}
            </div>
        </div>
    );
};

export default TournamentBg;
