import axios from 'axios';
import userSingleton from '../singletons/user.singleton';

const baseURL = 'https://game.belotkings.com/api';
const tournamentURL = 'https://game.belotkings.com/api/v1/tournament/';
// process.env.REACT_APP_BUILD_ENV !== 'production'
//     ? 'https://testgame.belotkings.com/api'
//     : 'https://game.belotkings.com/api';

export const axiosInstance = axios.create({
    baseURL: baseURL,
    timeout: 60000,
});
export const axiosInstanceTournament = axios.create({
    baseURL: tournamentURL,
    timeout: 60000,
});

const setTeamHeaders = (reqConfig) => {
    reqConfig.headers['Content-Type'] = 'application / json';
    // if (userSingleton.jwt) {
    //     reqConfig.headers.jwt = userSingleton.jwt;
    // }
    return reqConfig;
};

const setTournamentHeaders = (reqConfig) => {
    // reqConfig.headers['Content-Type'] = 'application / json';
    if (userSingleton.jwt) {
        reqConfig.headers.authorization = userSingleton.jwt;
    }
    return reqConfig;
};

const successResponse = (response) => {
    return response;
};

const errorResponse = (error) => {
    return Promise.reject(error);
};

// axiosInstance.interceptors.request.use(setHeaders);
axiosInstance.interceptors.response.use(successResponse, errorResponse);

axiosInstanceTournament.interceptors.request.use(setTournamentHeaders);
