import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cdnImages } from '../../../../assets/cdn.assets';

import routesEnum from '../../../../enums/routes.enum';
import { navigate } from '../../../../redux/global.slice';
import Timer from '../../../timer/timer';
import './tour-user-panel.scss';

const TourUserPanel = ({ data }) => {
    const [distance, setDistance] = useState(0);
    const dispatch = useDispatch();
    const tourData = useSelector((state) => state.global.navigateData);
    const tourInfoData = tourData?.tourData;
    const refInterval = useRef(null);
    const backTourList = () => {
        dispatch(navigate(routesEnum.TOURNAMENT));
    };
    const goTourInfo = () => {
        dispatch(
            navigate({
                route: routesEnum.TOURNAMENT_INFO,
                navigateData: tourData,
                joined: true,
            })
        );
    };

    function tourImg(endDate, startDate) {
        const endDateData = new Date(endDate);
        const endDateMill = endDateData.getTime();
        const startDateData = new Date(startDate);
        const startDateMill = startDateData.getTime();

        const fullDate = endDateMill - startDateMill;

        const data = fullDate * 0.33;
        const firstDate = endDateMill - data * 2;
        const secondDate = endDateMill - data;
        const currentDate = endDateMill - distance;
        if (currentDate <= firstDate) {
            return <img src={cdnImages.tour_timer_yellow} alt="#" />;
        }

        if (currentDate >= firstDate && currentDate <= secondDate) {
            return <img src={cdnImages.tour_timer_green} alt="#" />;
        }
        if (currentDate >= secondDate) {
            return <img src={cdnImages.tour_timer_red} alt="#" />;
        }
    }
    function tourTimeImg(data) {
        const endDate = data.endDate;

        const countDown = new Date(endDate).getTime();

        refInterval.current = setInterval(() => {
            const now = new Date().getTime();
            const distanceTime = countDown - now;
            setDistance(distanceTime);
            if (distanceTime < 0) {
                clearInterval(refInterval.current);
            }
        }, 1000);
    }

    useEffect(() => {
        tourTimeImg(data);
        return () => clearInterval(refInterval.current);
    }, []);

    return (
        <div className="tour-user-panel">
            <div className="tour-user-panel-left">
                <div className="tour-img-panel" onClick={goTourInfo}>
                    <img src={cdnImages.tour_panel} alt="#" />
                </div>

                <span>
                    {tourInfoData && tourInfoData[0] && tourInfoData[0].name}
                </span>
            </div>
            <div className="tour-user-panel-right">
                <div className="tour-panel-timer">
                    <div className="tour-panel-timer-img">
                        {tourImg(data?.endDate, data?.startDate)}
                    </div>

                    <div className="tour-user-panel-timer">
                        <Timer endDate={data?.endDate} />
                    </div>
                </div>
                <div className="tour-home-icon" onClick={backTourList}>
                    <img src={cdnImages.tour_home_icon} alt="#" />
                </div>
            </div>
        </div>
    );
};
export default TourUserPanel;
