import React, { useState, useEffect } from 'react';
import './level.bar.scss';

const LevelBar = ({ percent }) => {
    const [animating, setAnimating] = useState(false);
    const [animatedPercent, setAnimatedPercent] = useState(0);
    const percentData = Math.floor(percent);

    useEffect(() => {
        setTimeout(() => {
            setAnimating(true);
            setAnimatedPercent(percentData);
            setTimeout(() => {
                setAnimating(false);
            }, 500);
        }, 1000);
    }, []);

    return (
        <div className={'level-bar'}>
            <div
                className={`level-full${animating ? ' animating' : ''}`}
                style={{ width: animatedPercent + '%' }}
            />
        </div>
    );
};

export default LevelBar;
