import React from 'react';
import './dashboard-tab.scss';
import { useIntl } from 'react-intl';
import { cdnImages } from '../../../../assets/cdn.assets';

const TournamentDashboardTab = ({ tourTabIndex, dashboardTab }) => {
    const intl = useIntl();
    return (
        <div className="dashboard-tab">
            <div
                className={`leaderboard-tab ${
                    tourTabIndex === 0 ? ' active' : ''
                }`}
                onClick={() => dashboardTab(0)}
            >
                <span
                    className={`tab-text ${
                        tourTabIndex === 0 ? ' active-text' : ''
                    }`}
                >
                    {intl.messages.tournament.leaderboard}
                </span>
            </div>
            <div
                className={`leaderboard-tab ${
                    tourTabIndex === 1 ? ' active' : ''
                }`}
                onClick={() => dashboardTab(1)}
            >
                <span
                    className={`tab-text ${
                        tourTabIndex === 1 ? ' active-text' : ''
                    }`}
                >
                    {intl.messages.tournament.following}
                </span>
            </div>
            <div className="dashboard-tab-img">
                <img src={cdnImages.user_navigator} alt="#" />
            </div>
        </div>
    );
};
export default TournamentDashboardTab;
