import React from 'react';
import './game-chip-info.scss';

const GameChipInfo = ({ imageId, count = '', text = '' }) => {
    return (
        <div className="game_chip_info">
            <img src={imageId} alt="" />
            <div className="game_chip_info_main">
                <span className="game_chip_count">{count}</span>
                <span className="game_chip_text">{text}</span>
            </div>
        </div>
    );
};

export default GameChipInfo;
