import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { cdnImages } from '../../../assets/cdn.assets';

import BackgroundPopup from '../../../components/background/background-popup/background-popup';
import ButtonTreeD from '../../../components/buttons/button_3d/button-3d';
import routesEnum from '../../../enums/routes.enum';
import { navigate } from '../../../redux/global.slice';
import { closePopup } from '../../../redux/popup.slice';
import './not-enough-red.scss';

const NotEnoughRed = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const buyNow = () => {
        dispatch(closePopup());
        dispatch(navigate(routesEnum.STORE));
    };
    const closeRedPopup = () => {
        dispatch(closePopup());
    };
    const getFree = () => {
        dispatch(closePopup());
        dispatch(navigate(routesEnum.STORE));
    };
    return (
        <div className="not-enough-red">
            <BackgroundPopup>
                <div className="close-icon" onClick={closeRedPopup}>
                    <img src={cdnImages.settings_close} alt="close" />
                </div>
                <div className="not-enough-block">
                    <div className="red-block">
                        <img src={cdnImages.red_popup} alt="#" />
                    </div>
                    <div className="red-block-text">
                        <p className="red-text-first">
                            {
                                intl.messages.popups.red_chips_popup
                                    .not_enough_red
                            }
                        </p>
                        <span className="red-text-second">
                            {intl.messages.store.red_chips}
                        </span>
                        <p className="red-text-third">
                            {
                                intl.messages.popups.red_chips_popup
                                    .not_enough_red_game
                            }
                        </p>
                    </div>
                </div>
                <div className="vip_block_button">
                    <div className="vip-info-popup" onClick={getFree}>
                        <ButtonTreeD text={intl.messages.store.free} />
                    </div>
                    <div className="vip-buy-now" onClick={buyNow}>
                        <ButtonTreeD text={intl.messages.store.buyNow} />
                    </div>
                </div>
            </BackgroundPopup>
        </div>
    );
};
export default NotEnoughRed;
