import { axiosInstanceTournament } from '../config';

export const tournamentList_req = async (lang) => {
    const response = await axiosInstanceTournament.get(`/active?lang=${lang}`);
    return response.data;
};
export const tournamentGetUser_req = async (userId) => {
    const response = await axiosInstanceTournament.post('/getUser', {
        userId: userId,
    });
    return response.data;
};
export const tournamentInfo_req = async (id, lang) => {
    const response = await axiosInstanceTournament.get(
        `/info?id=${id}&lang=${lang}`
    );
    return response.data;
};
export const tournamentLeaderboard_req = async (id) => {
    const response = await axiosInstanceTournament.get(
        `/leaderboard?tournamentId=${id}`
    );
    return response.data;
};
export const tournamentJoin_req = async (tourId) => {
    const response = await axiosInstanceTournament.post('/join', {
        tournamentId: tourId,
    });
    return response.data;
};

export const tournamentUserPosition_req = async (tourId) => {
    const response = await axiosInstanceTournament.get(
        `/position?tournamentId=${tourId}`
    );
    return response.data;
};

export const tournamentUserTop_req = async (tourId) => {
    const response = await axiosInstanceTournament.get(
        `/top?tournamentId=${tourId}`,
        {
            skip: 0,
            limit: 300,
            tourId: tourId,
        }
    );
    return response.data;
};
export const tournamentUserParticipants_req = async (tourId) => {
    const response = await axiosInstanceTournament.get(
        `participants?tournamentId=${tourId}&&position=0&&direction=-1`
    );
    return response.data;
};
export const tournamentUserFollow_req = async (tourId, userId) => {
    const response = await axiosInstanceTournament.post('follow', {
        tournamentId: tourId,
        followingId: userId,
    });
    return response.data;
};
export const tournamentUserGetFollow_req = async (tourId) => {
    const response = await axiosInstanceTournament.get(
        `/followings?tournamentId=${tourId}`
    );
    return response.data;
};
export const tournamentUserUnfollow_req = async (tourId, userId) => {
    const response = await axiosInstanceTournament.post(`unfollow`, {
        tournamentId: tourId,
        followingId: userId,
    });
    return response.data;
};
export const tournamentPrizeInfo_req = async () => {
    const response = await axiosInstanceTournament.get(
        `getTournamentPrizeInfo`
    );
    return response.data;
};

export const tournamentHistory_req = async (tourId) => {
    const response = await axiosInstanceTournament.get(
        `history?tournamentId=${tourId}`
    );
    return response.data;
};
export const tournamentReward_req = async (templateId) => {
    const response = await axiosInstanceTournament.get(
        `rewards?templateId=${templateId}`
    );
    return response.data;
};

export const tournamentPopup_req = async () => {
    const response = await axiosInstanceTournament.get(`popups`);
    return response.data;
};

export const tournamentPlayedGames_req = async (tourId) => {
    const response = await axiosInstanceTournament.get(
        `played-games?tournamentId=${tourId}`
    );
    return response.data;
};

export const tournamentCloset_req = async (tourId) => {
    const response = await axiosInstanceTournament.get(
        `closest-users-in-leaderboard/?tournamentId=${tourId}`
    );
    return response.data;
};

export const tournamentGetReward_req = async (popupId) => {
    const response = await axiosInstanceTournament.post(`get-rewards`, {
        popupId,
    });
    return response.data;
};
