import React from 'react';
import './tournament-result-score.scss';

const TournamentResultScore = ({
    text,
    score,
    imageId,
    imageChip,
    position,
    textChip,
    prize,
}) => {
    return (
        <div className="tournament-result-score">
            <div className="result-img-block">
                <img src={imageId} alt="#" />
                <span>{position}</span>
            </div>
            <div className="result-text-content">
                <div className="result-text-block">
                    <div className="result-score-text-block">
                        <span>{text}</span>
                    </div>
                    <div className="result-score-block">
                        <span>{score}</span>
                    </div>
                </div>
                <div className="result-chip-block">
                    <div className="result-chip-img">
                        <img src={imageChip} alt="#" />
                    </div>
                    <div className="result-chip-text">
                        <span>{textChip}</span>
                    </div>
                    <div className="result-chip-text-number">
                        <span>{prize}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TournamentResultScore;
