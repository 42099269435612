const routesEnum = {
    LOADING: 0,
    LANGUAGE: 1,
    LOBBY: 2,
    GAMEPLAY: 3,
    BADGES: 4,
    STORE: 5,
    WHEEL: 6,
    TOURNAMENT: 7,
    TOURNAMENT_DASHBOARD: 8,
    TOURNAMENT_INFO: 9,
    TOURNAMENT_PARTISIPATION: 10,
    LOGIN_USER: 11,
    LOGIN: 12,
};

Object.freeze(routesEnum);

export default routesEnum;
