import { axiosInstance } from './config';

export const guest_req = async (username) => {
    const response = await axiosInstance.post(
        `/auth/web-guest`,
        {
            username,
        },
        {
            headers: {
                from: 'website',
            },
        }
    );
    return response.data;
};
