import React from 'react';
import { useIntl } from 'react-intl';
import { cdnImages } from '../../../assets/cdn.assets';

import './chip-navbar-tab.scss';

const ChipNavbarTab = ({ currentTab, setCurrentTab }) => {
    const intl = useIntl();

    const data = [
        {
            text: intl.messages.store.red_chips,
            imgSrc: cdnImages.red_tabs,
        },
        { text: intl.messages.store.yellow_chips, imgSrc: cdnImages.gold },

        // { text: intl.messages.store.free, imgSrc: wheel },
    ];
    return (
        <div className={`chip-navbar-tab chip-navbar-tab-${currentTab}`}>
            {data.map((item, i) => (
                <div
                    className={`navbar${currentTab === i ? ' active' : ''}`}
                    onClick={() => setCurrentTab(i)}
                >
                    <img src={item.imgSrc} alt="img" />
                    <p className="store-chip-text">{item.text}</p>
                </div>
            ))}
        </div>
    );
};

export default ChipNavbarTab;
