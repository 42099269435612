import React, { useState } from 'react';
import './bazar-window.scss';
import { useIntl } from 'react-intl';
import SayButton from '../../../../components/buttons/say-button/say-button';
import Suit from '../../suit/suit';
import { RoomChannelEmittingEvents } from '../../../../socket/socket.events';
import socketBridge from '../../../../socket';
import { useSelector } from 'react-redux';

import SayRecontra from '../../../../components/buttons/say-recontra/say-recontra';
import { cdnImages } from '../../../../assets/cdn.assets';

const BazarWindow = ({
    setBazar,
    bazarValue,
    stateIndex,
    allowContra,
    allowReContra,
    bazarSuit,
}) => {
    const intl = useIntl();
    const [selected, setSelected] = useState(-1);

    const me = useSelector((state) => state.me);

    const allowUser = allowContra[me.data.userId];
    const allowReContraUser = allowReContra[me.data.userId];

    const suit = [
        { imageId: cdnImages.clubs_icon, id: 0, text: intl.messages.club },
        { imageId: cdnImages.diamonds_icon, id: 1, text: intl.messages.dimond },
        { imageId: cdnImages.hearts_icon, id: 2, text: intl.messages.heart },
        { imageId: cdnImages.spade_icon, id: 3, text: intl.messages.spade },
        { imageId: cdnImages.boy, id: 4, text: intl.messages.noTrump },
        { imageId: cdnImages.deep_icon, id: 5, text: intl.messages.allTrump },
    ];

    const take = (selected) => {
        if (bazarSuit < selected) {
            socketBridge.emitToRoomChannel({
                event: RoomChannelEmittingEvents.TALK,
                bazarInfo: {
                    cardType: selected,
                    talkNumber: bazarValue,
                },

                state: stateIndex || 0,
            });
            setBazar();
        }
    };
    const sayRecontra = (sayIsRecontra) => {
        socketBridge.emitToRoomChannel({
            event: RoomChannelEmittingEvents.TALK,
            bazarInfo: {
                isRecontra: sayIsRecontra || undefined,
            },

            userId: me.data.userId,
            state: stateIndex || 0,
        });
    };
    const sayContra = (sayIsContra) => {
        socketBridge.emitToRoomChannel({
            event: RoomChannelEmittingEvents.TALK,
            bazarInfo: {
                isContra: sayIsContra || undefined,
            },
            state: stateIndex || 0,
        });
        setBazar();
    };
    const sayPass = (pass) => {
        socketBridge.emitToRoomChannel({
            event: RoomChannelEmittingEvents.TALK,
            bazarInfo: {
                Pass: pass || undefined,
            },
            state: stateIndex || 0,
        });
        setBazar();
    };
    return (
        <div className="bazar-window">
            <div className="bazar-suit">
                {suit.map((item, index) => (
                    <Suit
                        key={index}
                        index={item.id}
                        imageId={item.imageId}
                        selected={item.id === selected}
                        setSelected={index}
                        onClick={() => take(item.id)}
                        text={item.text}
                        bazarSuit={bazarSuit}
                        item={item.id}
                    />
                ))}
            </div>

            <div className="bazar-say-button">
                {allowUser ? (
                    <div className="contra-button">
                        <SayButton
                            text={'x2'}
                            onClick={() => sayContra(true)}
                            allowUser={allowUser}
                        />
                    </div>
                ) : (
                    <div className="contra-button">
                        <SayButton text={'x2'} />
                    </div>
                )}
                {allowReContraUser ? (
                    <div className={`contra-button`}>
                        <SayRecontra
                            text={'x4'}
                            allowReContraUser={allowReContraUser}
                            onClick={() => sayRecontra(true)}
                        />
                    </div>
                ) : (
                    <div className="contra-button">
                        <SayRecontra text={'x4'} />
                    </div>
                )}

                <div className="pass-button" onClick={() => sayPass(true)}>
                    <span> {intl.messages.gamePlay.pass}</span>
                </div>
            </div>
        </div>
    );
};

export default BazarWindow;
