import { configureStore } from '@reduxjs/toolkit';
import giftsSlice from './gifts.slice';
import globalSlice from './global.slice';
import meSlice from './me.slice';
import popupSlice from './popup.slice';
import userMenuGameSlice from './menu-game.slice';
import userInfoSlice from './userinfo.slice';
import WelcomeUserCbSlice from './welcome.user.cb.slice';
import notEnoughSlice from './notEnough.slice';
import chipInfoSlice from './chip.info.slice';
import userUpdateSlice from './user.update.slice';

import themeColorChange from './theme.change.slice';
import gameConfigSlice from './game-config.slice';
import rewardSlice from './tournament/reward-group.slice';
import gamePlayChipSlice from './game-play-chip.slice';
import usersInfoSlice from './user.info.game.slice';
import SocialLoginSlice from './social-login.slice';
import userMeTournamentSlice from './tournament/user-me-tournament.slice';

export default configureStore({
    reducer: {
        global: globalSlice,
        popup: popupSlice,
        me: meSlice,
        userMenuGame: userMenuGameSlice,
        gifts: giftsSlice,
        welcomeUserCb: WelcomeUserCbSlice,
        userInfo: userInfoSlice,
        usersInfo: usersInfoSlice,
        notEnough: notEnoughSlice,
        chipInfo: chipInfoSlice,
        userUpdate: userUpdateSlice,
        themeChange: themeColorChange,
        gameConfig: gameConfigSlice,
        rewardData: rewardSlice,
        gamePlayChip: gamePlayChipSlice,
        social: SocialLoginSlice,
        userMeTour: userMeTournamentSlice,
    },
});
