import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import './send-gift.scss';

const SendGift = ({
    fromUser,
    toUser,
    userSeatOffsets,
    giftId,
    removeGift,
}) => {
    const allGifts = useSelector((state) => state.gifts.allGifts.gifts);

    useEffect(() => {
        if (Object.keys(userSeatOffsets).length) {
            setTimeout(() => {
                removeGift();
            }, 1000);
        }
    }, []);

    if (Object.keys(userSeatOffsets).length === 0) {
        return null;
    }
    const data = allGifts && allGifts.filter((el) => el.id === giftId);

    return (
        <div className={`send-gift from-${fromUser}-to-${toUser}`}>
            {data && data[0] && <img src={data[0].src} />}
        </div>
    );
};

export default SendGift;
