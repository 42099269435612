import React from 'react'
import { useIntl } from 'react-intl'
import { cdnImages } from '../../../assets/cdn.assets'

import "./unlock-3d.scss"

const UnlockThreeD = ({onClick}) => {
    const intl = useIntl()
    return (
        <div className='unlock-three'>
            <button>
                <div className="unlock-img">
                    <img src={cdnImages.tour_locked} alt="#" />
                </div>

                <div className='unlock-text'>
                    <span>{intl.messages.tournament.unlock_level}</span>

                    <span>
                        {intl.messages.tournament.get_access}
                    </span> 
                </div> 

                <div className='unlock-btn' onClick={onClick}>
                    <span>{intl.messages.tournament.close}</span>
                </div>          
            </button>
            
            <div className="button-tree-under"></div>

        </div>
        
    )
}

export default UnlockThreeD