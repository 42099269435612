import React from 'react';
import { useIntl } from 'react-intl';
import { cdnImages } from '../../../assets/cdn.assets';

import './yellow-tab.scss';

const YellowTab = ({ data, buyPurchase }) => {
    const intl = useIntl();
    if (!data || data.length < 1) {
        return null;
    }

    const filter = data.packs.chips.filter((el) => el.chipType === 'yellow');

    return (
        <div className="yellow-tab">
            {filter.map((data, index) => (
                <div className="yellow-tab-item" key={data.id}>
                    <div className="yellow-tab-bg">
                        <img
                            src={cdnImages.yellow_bg}
                            className="yellow-tab-chip"
                            alt="#"
                        />
                        <div className="yellow-tab-chip-block">
                            <p className="yellow-tab-text">{data.chipAmount}</p>
                            {index === 0 && (
                                <img src={cdnImages.yellow_30} alt="#" />
                            )}
                            {index === 1 && (
                                <img src={cdnImages.yellow_110} alt="#" />
                            )}
                            {index === 2 && (
                                <img src={cdnImages.yellow_chest} alt="#" />
                            )}

                            <div
                                className="yellow-tab-item-buy-now"
                                onClick={() =>
                                    buyPurchase(
                                        data.id,
                                        true,
                                        data.chipAmount,
                                        data.chipType,
                                        data.amount
                                    )
                                }
                            >
                                <p>{intl.messages.store.buyNow}</p>
                                <span>
                                    {data.currency} {data.amount}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default YellowTab;
