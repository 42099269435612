import { createSlice } from '@reduxjs/toolkit';

export const userMenuGameSlice = createSlice({
    name: 'userMenuGame',
    initialState: {
        gift: { openedState: 3, userId: '' },
        chat: { chatOpenedState: 3 },
        smile: { smileOpenedState: 3 },
        rightMenu: { rightMenuOpenedState: 3 },
    },
    reducers: {
        openCloseGift: (state, action) => {
            state.gift = {
                openedState: action.payload.openedState,
                userId: action.payload.userId,
            };
        },
        openCloseChat: (state, action) => {
            state.chat = {
                chatOpenedState: action.payload.chatOpenedState,
            };
        },
        openCloseSmile: (state, action) => {
            state.smile = {
                smileOpenedState: action.payload.smileOpenedState,
            };
        },
        openGamePlayMenu: (state, action) => {
            state.rightMenu = {
                rightMenuOpenedState: action.payload.rightMenuOpenedState,
            };
        },
    },
});

export const {
    openCloseGift,
    openCloseChat,
    openCloseSmile,
    openGamePlayMenu,
} = userMenuGameSlice.actions;

export default userMenuGameSlice.reducer;
