import React from 'react';
import './round-score-line.scss';

const RoundScoreLine = ({
    text = '',
    valueUs,
    valueThem,
    scoreUs,
    scoreThem,
}) => {
    return (
        <tbody className="round-score-line">
            <tr className="round-score-one-line">
                <td className={text ? 'round-score-text' : 'round-score-empty'}>
                    {text}
                </td>

                {/*{team === 0 &&*/}
                {/*<>*/}
                {/*    <td className="round-score-item">*/}
                {/*        {scoreUs || (valueUs ? valueUs : '-')}*/}
                {/*    </td>*/}
                {/*    <td className="round-score-item">*/}
                {/*        {scoreThem || (valueThem ? valueThem : '-')}*/}
                {/*    </td>*/}
                {/*</>*/}
                {/*}*/}

                {/*{team === 1 &&*/}
                {/*<>*/}
                {/*    <td className="round-score-item">*/}
                {/*        {scoreUs || (valueThem ? valueThem : '-')}*/}
                {/*    </td>*/}
                {/*    <td className="round-score-item">*/}
                {/*        {scoreThem || (valueUs ? valueUs : '-')}*/}
                {/*    </td>*/}
                {/*</>*/}
                {/*}*/}

                <td className="round-score-item">
                    {scoreUs || (valueUs ? valueUs : '-')}
                </td>

                <td className="round-score-item">
                    {scoreThem || (valueThem ? valueThem : '-')}
                </td>
            </tr>
        </tbody>
    );
};
export default RoundScoreLine;
