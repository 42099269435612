class UserSingleton {
    constructor() {
        this._jwt = '';
        this._userId = 0;
        this._zone = '';
        this._fbUserData = null;
        this._firstUser = 0;
        this._accessToken = '';
        this._zoneStorage = '';
    }

    set zoneStorage(_zoneStorage) {
        this._zoneStorage = _zoneStorage;
    }

    get zoneStorage() {
        return this._zoneStorage;
    }

    set accessToken(_accessToken) {
        this._accessToken = _accessToken;
    }

    get accessToken() {
        return this._accessToken;
    }

    set firstUser(_firstUser) {
        this._firstUser = _firstUser;
    }

    get firstUser() {
        return this._firstUser;
    }

    set userId(_userId) {
        this._userId = _userId;
    }

    get userId() {
        return this._userId;
    }

    set zone(_zone) {
        this._zone = _zone;
    }

    get zone() {
        return this._zone;
    }

    set jwt(_jwt) {
        this._jwt = _jwt;
    }

    get jwt() {
        return this._jwt;
    }

    set fbUserData(_fbUserData) {
        this._fbUserData = _fbUserData;
    }

    get fbUserData() {
        return this._fbUserData;
    }
}
export default new UserSingleton();
