import PIXI from '../wrappers/pixi.wrapper';

import imageAssets from '../assets/pixi.images.json';

class PixiLoader {
    constructor() {
        this.sprites = {};
        this.loader = PIXI.Loader.shared;
    }

    load() {
        this.loadImages();

        this.loader.load();
    }

    loadImages() {
        if (imageAssets && imageAssets.length) {
            imageAssets.forEach((imageAsset) => {
                this.loader.add(imageAsset.id, imageAsset.fileName);
            });
        }
    }

    getImage(imageId) {
        return this.loader.resources[imageId];
    }
}

export default new PixiLoader();
