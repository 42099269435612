import React from 'react';
import { cdnImages } from '../../assets/cdn.assets';

import './splash-screen.scss';

const SplashScreen = ({ goGuestPage }) => {
    return (
        <div className="splash-screen">
            <div className="container">
                <div className="splash-screen-block">
                    <div className="left-block">
                        <div className="left-media-card">
                            <img src={cdnImages.ace_heart} alt="#" />
                        </div>
                        <div className="left-first-card">
                            <img src={cdnImages.jack_heart} alt="#" />
                        </div>
                        <div className="left-second-card">
                            <img src={cdnImages.king_brick} alt="#" />
                        </div>
                        <div className="left-third-card">
                            <img src={cdnImages.nine_black_heart} />
                        </div>
                    </div>
                    <div className="center-block">
                        <span className="welcome-text">WELCOME TO</span>
                        <div className="candy-wings-logo">
                            <img
                                src={cdnImages.belot_kings_logo}
                                alt="candy-wings"
                            />
                        </div>
                        <div className="center-card-block">
                            <img src={cdnImages.jack_black_heart} alt="#" />
                        </div>
                        <div
                            className="center-button-block"
                            onClick={goGuestPage}
                        >
                            <span>play the game</span>
                        </div>
                        <div className="center-button-block-mobile">
                            <a href="http://onelink.to/ztkcw8">
                                <span>play the game</span>
                            </a>
                        </div>

                        <div className="app-play-market">
                            <div className="app-store download_link">
                                <a href="http://onelink.to/ztkcw8">
                                    {' '}
                                    <img src={cdnImages.app_store} alt="#" />
                                </a>
                            </div>
                            <div className="play-market download_link">
                                <a href="http://onelink.to/ztkcw8">
                                    <img src={cdnImages.google_play} alt="#" />
                                </a>
                            </div>
                        </div>
                        <div className="splash-candy-logo-center">
                            <img src={cdnImages.splash_candy} alt="#" />
                        </div>
                    </div>
                    <div className="right-block">
                        <div id="fb-root">
                            <div
                                id="fb-customer-chat"
                                className="right-question-icon "
                            >
                                <img src={cdnImages.splash_question} alt="#" />
                            </div>
                        </div>

                        <div className="right-card-block">
                            <div className="right-media-first-card">
                                <img src={cdnImages.king_brick} alt="#" />
                            </div>
                            <div className="right-media-second-card">
                                <img src={cdnImages.jack_black_heart} alt="#" />
                            </div>
                            <div className="right-first-card">
                                <img src={cdnImages.jack_black_heart} alt="#" />
                            </div>
                            <div className="right-second-card">
                                <img src={cdnImages.ace_heart} alt="#" />
                            </div>
                        </div>

                        <div className="right-bottom-block">
                            <span>Created by</span>

                            <div className="splash-candy-logo">
                                <a
                                    href="https://www.candywings.com/"
                                    target="#"
                                >
                                    <img src={cdnImages.splash_candy} alt="#" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="coins-chips">
                <img src={cdnImages.coins_chips} alt="#" />
            </div>
        </div>
    );
};

export default SplashScreen;
