import { createSlice } from '@reduxjs/toolkit';

export const chipInfoSlice = createSlice({
    name: 'chipInfo',
    initialState: {
        red: null,
        yellow: null,
        green: null,
        chipBoolean: false,
    },
    reducers: {
        setChip: (state, action) => {
            action.payload.map((item) => {
                state[item.chip] = item.value;
            });
        },
        setUpdateBoolean: (state, { payload }) => {
            state.chipBoolean = payload;
        },
    },
});
export const { 
    setChip,
    setUpdateBoolean
} = chipInfoSlice.actions;

export default chipInfoSlice.reducer;
