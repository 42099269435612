module.exports = {
    MODE: 'test',
    // LOCAL_HOST: 'https://game.belotkings.com/api',
    LOCAL_HOST: 'https://game.belotkings.com/api',
    // process.env.REACT_APP_BUILD_ENV !== 'production'
    //     ? 'https://testgame.belotkings.com/api'
    //     : 'https://game.belotkings.com/api',
    // API_HOST: 'https://game.belotkings.com/api',
    API_HOST: 'https://game.belotkings.com/api',
    // process.env.REACT_APP_BUILD_ENV !== 'production'
    //     ? 'https://testgame.belotkings.com/api'
    //     : 'https://game.belotkings.com/api',
    API_PORT: '3300',
    // SOCKET_HOST: 'game.belotkings.com/socket',
    SOCKET_HOST: 'game.belotkings.com/socket',
    // process.env.REACT_APP_BUILD_ENV !== 'production'
    //     ? 'testgame.belotkings.com/socket'
    //     : 'game.belotkings.com/socket',
    SOCKET_PORT: '',
};
