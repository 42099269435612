import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
    tournamentCloset_req,
    tournamentGetReward_req,
} from '../../../api/tournament/tournament.api';
import { userInfo_req } from '../../../api/user.api';

import { useIntl } from 'react-intl';

import { cdnImages } from '../../../assets/cdn.assets';
import {
    tournamentPrizeCopper,
    tournamentPrizeGold,
    tournamentPrizeIron,
    tournamentPrizeSilver,
} from '../../../assets/image.assets';

import TournamentBg from '../../../components/background/tournament-bg/tournament-bg';
import { closePopup } from '../../../redux/popup.slice';
import { setUserUpdate } from '../../../redux/user.update.slice';
import TournamentResultScore from './tournament-result-score/tournament-result-score';
import TournamentResultUser from './tournament-result-user/tournament-result-user';
import './tournament-result.scss';

const TournamentResult = ({ popupData }) => {
    const intl = useIntl();
    const dataPopup = popupData && popupData.data && popupData.data[0];
    const [tabIndex, setTabIndex] = useState(0);
    const dispatch = useDispatch();
    const [userData, setUserData] = useState([]);
    const resultTab = (tabIndex) => {
        setTabIndex(tabIndex);
    };
    const me = useSelector((state) => state.me.data);
    const tournamentPrizeImg = (position) => {
        switch (position) {
            case 1:
                return cdnImages.tournamentPrizeGold;
            case 2:
                return cdnImages.tournamentPrizeSilver;
            case 3:
                return cdnImages.tournamentPrizeCopper;
            default:
                return cdnImages.tournamentPrizeIron;
        }
    };
    const tournamentClosetUsers = async () => {
        const tournamentId = dataPopup.tournamentId;
        const tournamentClosetData = await tournamentCloset_req(tournamentId);
        setUserData(tournamentClosetData);
    };
    const tournamentGetReward = async (tourId) => {
        const tourRewards = await tournamentGetReward_req(tourId);
        if ((tourRewards.status = 'success')) {
            const userUpdate = await userInfo_req(me.userId);
            dispatch(setUserUpdate(userUpdate));
        }
    };
    useEffect(() => {
        tournamentClosetUsers();
    }, []);

    const closeTourFinish = () => {
        const tourId = dataPopup._id;
        tournamentGetReward(tourId);
        dispatch(closePopup());
    };
    return (
        <div className="tournament-result">
            <div className="tournament-result-tab">
                <div className="tournament-result-tab-index">
                    <span
                        onClick={() => resultTab(0)}
                        className={`tab-data active `}
                    >
                        {intl.messages.tournament.leaderboard}
                    </span>
                    {/* <span
                        onClick={() => resultTab(1)}
                        className={`tab-data ${
                            tabIndex === 1 ? ' active' : ''
                        }`}
                    >
                        Follow
                    </span> */}
                </div>
                <div className="close-icon">
                    <img
                        src={cdnImages.settings_close}
                        alt="#"
                        className="settings-close"
                        onClick={closeTourFinish}
                    />
                </div>
            </div>
            <div className="tournament-result-block">
                <TournamentBg>
                    <div className="tournament-result-content">
                        <div className="tournament-result-title">
                            <span>
                                {dataPopup.name}{' '}
                                {intl.messages.tournament.tour_result}
                            </span>
                            <div className="menu-gradline"></div>
                        </div>
                        <div className="tournament-result-section">
                            <div className="tournament-result-left-block">
                                <TournamentResultScore
                                    text={intl.messages.tournament.your_score}
                                    score={dataPopup.score}
                                    imageId={tournamentPrizeImg(
                                        dataPopup.position
                                    )}
                                    position={dataPopup.position}
                                    imageChip={cdnImages.red}
                                    textChip={
                                        intl.messages.tournament.red_chips
                                    }
                                    prize={dataPopup.prize}
                                />
                                {/* <TournamentResultScore
                                    text={'Last Personal score'}
                                    score={'56005'}
                                /> */}
                            </div>
                            <div className="tournament-result-right-block">
                                <div className="result-block">
                                    <TournamentResultUser
                                        data={userData.data}
                                    />
                                    <div className="more-level-bottom"></div>
                                    <div className="more-level">
                                        <div className="unlock-text">
                                            <span>
                                                {
                                                    intl.messages.tournament
                                                        .more_tours
                                                }
                                            </span>
                                        </div>

                                        <div
                                            className="unlock-btn"
                                            onClick={closeTourFinish}
                                        >
                                            {intl.messages.tournament.close}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </TournamentBg>
            </div>
        </div>
    );
};

export default TournamentResult;
