import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { tournamentUserGetFollow_req } from '../../../../api/tournament/tournament.api';
import LevelBar from '../../../../components/bars/level-bar/level.bar';
import './followed-content.scss';

const FollowedContent = ({ tournament, unfollow, success }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [followedUser, setFollowedUser] = useState([]);

    const followedList = async () => {
        const tourId = tournament.id;
        const followedListData = await tournamentUserGetFollow_req(tourId);
        setFollowedUser(followedListData);
    };
    useEffect(() => {
        followedList();
    }, [success]);

    if (!followedUser) return null;

    return (
        <div className="dashboard-content">
            {followedUser?.data?.userFollowings?.map((item, index) => {
                return (
                    <div className="content-line">
                        <div className="user-leaderboard-img-block">
                            <div className="user-leaderboard-img">
                                {item.avatar ? (
                                    <img src={item.avatar} alt="#" />
                                ) : (
                                    <img
                                        src="https://cdn.blotclub.am/avatars/enky1.png"
                                        alt="#"
                                    />
                                )}
                            </div>
                            <div className="user-leaderboard-level">
                                <span>{item.level}</span>
                            </div>
                        </div>
                        <div className="user-position-block-follow">
                            <span className="user-position-name">
                                {item.username
                                    ? item.username.slice(0, 7)
                                    : 'agantagexos'}
                            </span>

                            <div className={`position-btn`}>
                                <span>{index + 1}</span>
                            </div>
                        </div>
                        <div className="user-score-block">
                            <span>{intl.messages.tournament.scores}</span>
                            <p>{item.score}</p>
                        </div>
                        <div className="user-game-count">
                            <span>
                                {item.playedGames}/{tournament.maxGames}
                            </span>
                            <div className="user-game-count-rating">
                                <div className="user-game-progress"></div>
                            </div>
                            <LevelBar
                                percent={
                                    (item.playedGames * 100) /
                                    tournament.maxGames
                                }
                            />
                        </div>
                        <div
                            className="tournament-followed-btn"
                            onClick={() =>
                                unfollow(item.tournamentId, item.userId)
                            }
                        >
                            <span>{intl.messages.tournament.unfollow}</span>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
export default FollowedContent;
