import React from 'react';

import './standard-label.scss';

const StandardLabel = ({
    text,
    fontSize,
    color,
    fromColor,
    toColor,
    additionalClass,
}) => {
    const style = {
        fontSize: (fontSize || 21) + 'px',
    };

    if (color) {
        style.color = color;
    } else if (fromColor && toColor) {
        style.background = `-webkit-linear-gradient(${fromColor}, ${toColor})`;
        style['WebkitBackgroundClip'] = 'text';
        style['WebkitTextFillColor'] = 'transparent';
        style.color = 'transparent';
    }

    if (additionalClass) {
        additionalClass = ' ' + additionalClass;
    }
    return (
        <span
            className={'standard_label' + (additionalClass || '')}
            style={style}
        >
            {text}
        </span>
    );
};

export default StandardLabel;
