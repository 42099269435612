/* eslint-disable import/no-dynamic-require, global-require */

import React, { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { checkNotification_req } from './api/user.api';

import './app.scss';
import themeEnum from './enums/theme.enum';

// INIT
import './loaders/audio.loader';

import BasePage from './pages/base.page';
import PopupBase from './popups/popups.base';
import userSingleton from './singletons/user.singleton';
import { UserChannelHandlingEvents } from './socket/socket.events';
import SocialWrapper from './wrappers/social.wrapper';
import socketBridge from './socket';
import { openPopups } from './redux/popup.slice';
import PopupsEnum from './enums/popups.enum';

function getLocaleString(locale) {
    switch (locale) {
        case 1:
            return 'bg';
        case 3:
            return 'en';
        case 2:
            return 'ru';
        default:
            return 'bg';
    }
}

function App() {
    const locale = useSelector((state) => state.global.locale);

    const route = useSelector((state) => state.global.route);

    const currentPopupIndex = useSelector(
        (state) => state.popup.currentPopupIndex
    );
    const popupIds = useSelector((state) => state.popup.popupIds);
    const popupData = useSelector((state) => state.popup.popupData);
    const data = useSelector((state) => state.themeChange);

    const localeString = getLocaleString(locale);
    const changeLocaleState = useSelector(
        (state) => state.global.connectionSocket
    );
    const me = useSelector((state) => state.me.data);
    const firstUser = useSelector((state) => state.global.firstUser);

    const dispatch = useDispatch();
    useEffect(() => {
        if (changeLocaleState && route !== 0 && route !== 1 && route !== 3) {
            function watcher({ event, ...args }) {
                switch (event) {
                    case UserChannelHandlingEvents.ADMIN_CHIP:
                        const userId = me.userId;

                        if (args && args.userId) {
                            const checkNotification = async () => {
                                const notificationAdmin =
                                    await checkNotification_req(userId);

                                if (
                                    notificationAdmin &&
                                    notificationAdmin.notifications &&
                                    notificationAdmin.notifications.length > 0
                                ) {
                                    dispatch(
                                        openPopups({
                                            popupIds: [
                                                PopupsEnum.ADMIN_CHIP_POPUP,
                                            ],
                                            popupData: [notificationAdmin],
                                        })
                                    );
                                }
                            };
                            checkNotification();
                        }

                        break;
                    default:
                        break;
                }
            }

            socketBridge.watchUserChannel(watcher);

            return () => {
                socketBridge.unwatchUserChannel(watcher);
            };
        }
    }, [changeLocaleState, route]);

    if (me && me.jwt && me.userId) {
        userSingleton.jwt = me.jwt;
        userSingleton.userId = me.userId;
    }

    const tournament = (data) => {
        switch (data.theme) {
            case themeEnum.LOBBY:
                return '';
            case themeEnum.TOURNAMENT:
                return 'tournament-temp';
            default:
                return '';
        }
    };
    return (
        <IntlProvider
            locale={localeString}
            messages={require(`./localization/${localeString}.json`)}
        >
            {/* <div className={`app ${tournament(data)}`}> */}
            <div
                // className={`app ${data.tournament === 2 && 'tournament-temp'}`}
                className={`${
                    firstUser > 0 || localStorage.beloteKings ? 'app' : ''
                }  ${tournament(data)}`}
            >
                <SocialWrapper />
                <BasePage />
                {popupIds &&
                    popupIds.length !== 0 &&
                    popupIds[currentPopupIndex] && (
                        <PopupBase
                            popupId={popupIds[currentPopupIndex]}
                            popupData={popupData[currentPopupIndex]}
                        />
                    )}
            </div>
        </IntlProvider>
    );
}

export default App;
