import React from 'react';
import './background-popup.scss';

const BackgroundPopup = ({ children }) => {
  return (
    <div className="background_popup">
      <div className="background_pattern_popup">{children}</div>
    </div>
  );
};

export default BackgroundPopup;
