import React from 'react';
import { useIntl } from 'react-intl';
import { cdnImages } from '../../assets/cdn.assets';
import { groupByAnnounce } from '../../helpers/groupBy.helper';
import './tooltip.scss';

function getTooltipSuit(suit) {
    switch (suit) {
        case 0:
        return cdnImages.clubs_icon;
        case 1:
            return cdnImages.diamonds_icon;
        case 2:
            return cdnImages.hearts_icon;
        case 3:
            return cdnImages.spade_icon;
        case 4:
            return cdnImages.boy;
        case 5:
            return cdnImages.deep_icon;

        default:
            break;
    }
}
const Tooltip = ({
    seat,
    userSaySuit,
    showAnnouncement,
    text,
    sayPassAnnounce,
}) => {
    const intl = useIntl();
    const anounce = showAnnouncement && groupByAnnounce(showAnnouncement);
    const anouncement = [];
    for (const type in anounce) {
        anouncement.push({
            type,
            value: anounce[type],
        });
    }
    return (
        <div className={`tooltip-${seat} tooltip`}>
            <div className="tooltip-suit">
                {text && <span className="contra-text">{text}</span>}
            </div>

            {anounce && (
                <div className="tooltip-suit">
                    {anouncement.map((item) => (
                        <React.Fragment key={item.type}>
                            {item.value > 1 ? (
                                <div className="pass-say-value">
                                    {item.type} x {item.value}
                                </div>
                            ) : (
                                <div className="item-text">
                                    <span> {item.type}</span>
                                </div>
                            )}
                        </React.Fragment>
                    ))}
                </div>
            )}

            {userSaySuit > -1 ? (
                <div className="tooltip-suit">
                    <img src={getTooltipSuit(userSaySuit)} alt="#" />
                </div>
            ) : null}
            {sayPassAnnounce && (
                <div className="tooltip-suit">
                    <span className="pass-say">
                        {intl.messages.gamePlay.pass}
                    </span>
                </div>
            )}
        </div>
    );
};

export default Tooltip;
