import React from 'react';
import Toggle from '../../../components/toggle/toggle';

import './menu-item.scss';

const MenuItem = ({ imageId, text, toggleActive, setToggleActive }) => {
    const toggle = () => {
        setToggleActive(!toggleActive);
    };
    return (
        <div className={`menu_item${toggleActive ? '_active' : ''}`}>
            <div className="menu_item_block">
                {imageId && <img src={imageId} />}
                <span
                    className={`menu_item_text${toggleActive ? '_active' : ''}`}
                >
                    {text}
                </span>
            </div>

            <Toggle toggleActive={toggleActive} _onClick={() => toggle()} />
        </div>
    );
};

export default MenuItem;
