import { createSlice } from '@reduxjs/toolkit';
export const gameConfigSlice = createSlice({
    name: 'gameConfig',
    initialState: {
        gameConfigData: [],
        roomId: '',
    },
    reducers: {
        setGameConfigData: (state, action) => {
            state.gameConfigData = action.payload;
        },
        setGameRoomId: (state, action) => {
            state.roomId = action.payload;
        },
    },
});

export const { setGameConfigData, setGameRoomId } = gameConfigSlice.actions;

export default gameConfigSlice.reducer;
