import React from 'react';
import { useSelector } from 'react-redux';
import { cdnImages } from '../../../assets/cdn.assets';

import Timer from '../../timer/timer';
import './discount.scss';

function discountTypeImg(discountType) {
    if (discountType === 2) {
        return <img src={cdnImages.x2_icon} />;
    } else if (discountType === 3) {
        return <img src={cdnImages.x3_icon} />;
    } else if (discountType === 4) {
        return <img src={cdnImages.x4_icon} />;
    } else if (discountType === 5) {
        return <img src={cdnImages.x5_icon} />;
    }
}
const Discount = ({ discountType, discountDate }) => {
    const navigateRoute = useSelector((state) => state.global.route);
    return (
        <div className="discount">
            {navigateRoute !== 7 && (
                <img src={cdnImages.discount_offer} alt="discount_offer" />
            )}

            {navigateRoute === 7 && (
                <img src={cdnImages.discount_tournament} alt="discount_offer" />
            )}
            <div className="discount_count">
                <div>{discountTypeImg(discountType)}</div>
                <div className="discount-timer">
                    <div className="discount-time-block">
                        <Timer endDate={discountDate} />
                    </div>
                    <img src={cdnImages.discount_gradLine} />
                </div>
            </div>
        </div>
    );
};

export default Discount;
