import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { cdnImages } from '../../../../../assets/cdn.assets';

import TourRewardsTooltip from '../tour-rewards-tooltip/tour-rewards-tooltip';
import './tour-rewards-line.scss';

function vipDescription(type, intl) {
    switch (type) {
        case 111:
            return <span>1 {intl.messages.month}</span>;
        case 112:
            return <span>1 {intl.messages.weel}</span>;
        case 113:
            return <span>1 {intl.messages.year}</span>;
        default:
            return <span>1 {intl.messages.year}</span>;
    }
}
const getImageReward = (index) => {
    switch (index) {
        case 0:
            return <img src={cdnImages.reward_first} alt="#" />;
        case 1:
            return <img src={cdnImages.reward_second} alt="#" />;
        case 2:
            return <img src={cdnImages.reward_third} alt="#" />;
        default:
            return <img src={cdnImages.reward_four} alt="#" />;
    }
};
const redImgIcon = (index) => {
    switch (index) {
        case 0:
            return (
                <div className="red-first">
                    <img
                        src={cdnImages.red}
                        alt="#"
                        className={`red-first-img-first`}
                    />
                    <img
                        src={cdnImages.red}
                        alt="#"
                        className={`red-first-img-second`}
                    />
                    <img
                        src={cdnImages.red}
                        alt="#"
                        className={`red-first-img-third`}
                    />
                    <img
                        src={cdnImages.red}
                        alt="#"
                        className={`red-first-img-four`}
                    />
                </div>
            );
        case 1:
            return (
                <div className="red-second">
                    <img
                        src={cdnImages.red}
                        alt="#"
                        className="red-second-img-first"
                    />
                    <img
                        src={cdnImages.red}
                        alt="#"
                        className="red-second-img-second"
                    />
                    <img
                        src={cdnImages.red}
                        alt="#"
                        className="red-second-img-third"
                    />
                </div>
            );
        case 2:
            return (
                <div className="red-third">
                    <img
                        src={cdnImages.red}
                        alt="#"
                        className="red-third-img-first"
                    />
                    <img
                        src={cdnImages.red}
                        alt="#"
                        className="red-third-img-second"
                    />
                </div>
            );
        default:
            return <img src={cdnImages.red} alt="#" />;
    }
};
const TourRewardsLine = ({ item, joined, index }) => {
    const intl = useIntl();
    const userPositionData = useSelector((state) => state.userMeTour);

    return (
        <div className="tour-rewards-line">
            <div className="rewards-left-block">
                <div className="item-img">{getImageReward(index)}</div>
                <div className="rewards-img">{item.places}</div>
                <div className="last-reward-user">
                    {item.min !== item.max &&
                        item.min <= userPositionData.position &&
                        userPositionData.position <= item.max &&
                        joined && (
                            <>
                                <img
                                    src={cdnImages.user_navigator}
                                    className="user-navigator"
                                    alt="#"
                                />
                                <TourRewardsTooltip />
                            </>
                        )}
                    <span>
                        {item.min !== item.max
                            ? `${item.min} - ${item.max}`
                            : item.min}
                    </span>
                </div>
            </div>
            <div className="rewards-center-block">
                {item.amount && (
                    <div className="reward-red-block">
                        {' '}
                        <span>{item.amount}</span>
                        {redImgIcon(index)}
                    </div>
                )}
                {item.vip && (
                    <div>
                        {' '}
                        <span>{vipDescription(item.amount, intl)} </span>
                        <img src={cdnImages.tour_vip} alt="#" />
                    </div>
                )}
            </div>
            <div className="rewards-right-block"></div>
        </div>
    );
};
export default TourRewardsLine;
