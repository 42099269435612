import { createSlice } from '@reduxjs/toolkit';

export const userMeTournamentSlice = createSlice({
    name: 'userMeTour',
    initialState: {
        userMeTournament: '',
        rewardTournament: ''
    },
    reducers: {
        setUserMeTournament: (state, action) => {
            state.userMeTournament = action.payload;
        },
        setRewardTournament: (state, action) => {
            state.rewardTournament = action.payload;
        },
    },
});
export const { setUserMeTournament, setRewardTournament } = userMeTournamentSlice.actions;

export default userMeTournamentSlice.reducer;
