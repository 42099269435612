import React from 'react';
import { useIntl } from 'react-intl';
import { cdnImages } from '../../../assets/cdn.assets';

import './lobby-bet.scss';

const getRoomChip = (type) => {
    switch (type) {
        case 1:
            return cdnImages.redchip_small;

        case 2:
            return cdnImages.greenchip_small;

        default:
            return cdnImages.redchip_small;
    }
};
const LobbyBet = ({ bet, win, type }) => {
    const intl = useIntl();
    return (
        <div className="lobby_bet">
            <img src={getRoomChip(type)} alt="#" className="yellow_rectangle" />

            <p>
                {intl.messages.bet} {bet}
            </p>
            <span>
                {intl.messages.win} {win}
            </span>
        </div>
    );
};

export default LobbyBet;
