import React, { useState } from 'react';
import './user-login-first.scss';

import ButtonTreeD from '../../buttons/button_3d/button-3d';
import Background from '../../background/background';
import { useDispatch } from 'react-redux';
import { navigate } from '../../../redux/global.slice';
import routesEnum from '../../../enums/routes.enum';
import { guest_req } from '../../../api/guest.api';
import { cdnImages } from '../../../assets/cdn.assets';

const UserLoginFirst = () => {
    const [state, setState] = useState({ userName: '' });
    const dispatch = useDispatch();
    const goFirstLogin = () => {
        dispatch(navigate(routesEnum.LOGIN));
    };
    const handleChange = (evt) => {
        const value = evt.target.value;
        setState({
            ...state,
            [evt.target.name]: value,
        });
    };
    const guestUser = async () => {
        const username = state.userName;
        const userGuest = await guest_req(username);
        localStorage.beloteKings = userGuest.jwt;
        const guestUser = userGuest.uid;

        const userLogin = guestUser.split('_guest');

        localStorage.beloteKingsUserId = userLogin[0];

        if (userGuest) {
            dispatch(navigate(routesEnum.LOADING));
        }
    };
    return (
        <div className="user-login-first">
            <Background>
                <div className="user-login-first-content">
                    <div className="user-login-left">
                        <div className="user-login-left-belot-logo">
                            <img src={cdnImages.belot_kings_logo} />
                        </div>
                    </div>
                    <div className="user-login-right">
                        <span className="nickname">enter your nickname</span>
                        <input
                            type="text"
                            placeholder="your name"
                            name="userName"
                            value={state.userName}
                            onChange={handleChange}
                        />
                        <div className="user-login-btn-block">
                            <div
                                className="user-login-cancel"
                                onClick={goFirstLogin}
                            >
                                <ButtonTreeD text="cancel" />
                            </div>
                            <div
                                className="user-login-confirm"
                                onClick={guestUser}
                            >
                                <ButtonTreeD text="confirm" />
                            </div>
                        </div>
                    </div>
                </div>
            </Background>
        </div>
    );
};

export default UserLoginFirst;
