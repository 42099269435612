import React from 'react';
import './user-contra.scss';

const UserContra = ({ seat = '1' }) => {
    return (
        <div className={`user-contra user-contra-${seat}`}>
            <span>C</span>
        </div>
    );
};

export default UserContra;
