export const AllEvents = {
    WELCOME_USER: 0,
    FIND_ROOM: 1,
    JOIN_ROOM: 2,
    ANNOUNCEMENT_PRIVATE: 3,
    GET_CARDS: 4,
    VALID_CARDS: 5,
    UPDATE_GLOBAL_SCORE: 6,
    PLAYED_CARD: 7,
    PLAY: 8,
    UPDATE_ROUND_SCORE: 11,
    TALK: 12,
    LAST_HAND_CARDS: 13,
    WELCOME_USER_CB: 14,
    TAKE_GAME_ID: 15,
    PLAYERS_INFO: 16,
    ANNOUNCEMENT: 17,
    ANNOUNCEMENT_PUBLIC: 19,
    ALLOW_PLAY: 21,
    FIRST_PLAY: 23,
    LEAVE_ROOM: 24,
    SEND_GIFT: 25,
    SEND_EMOJI: 34,
    ADMIN_CHIP: 34,
    TAKE_GIFT: 26,
    TAKE_EMOJI: 35,
    FORCE_LEAVE_USER: 27,
    METRICS: 28,
    EMOTION: 29,
    NEXT: 30,
    REPLAY: 31,
    PARTNER_DISCONNECTED: 32,
    // **admin
    ADMIN_CHARGE: 33,
    CHARGE_USER: 36,
    UPDATE_TOURNAMENT_LIST: 57,
};

Object.freeze(AllEvents);

export const UserChannelEmittingEvents = {
    WELCOME_USER: 0,
    FIND_ROOM: 1,
    ANNOUNCEMENT: 17,
    FORCE_LEAVE_USER: 27,
    REPLAY: 31,
    SEND_EMOJI: 34,
};

Object.freeze(UserChannelEmittingEvents);

export const UserChannelEmittingEventsName = {
    FIND_ROOM: 'userChannel.findRoom',
    REPLAY: 'userChannel.replay',
    GIVE_VALID_CARDS: 'userChannel.validCards',
    GET_STATE: 'userChannel.getState',
    SEND_GIFT: 'userChannel.sendGift',
    LEAVE_USER: 'userChannel.leaveUser',
    REQUEST_GAME_STATE: 'userChannel.requestGameState',
};

Object.freeze(UserChannelEmittingEventsName);

export const UserChannelHandlingEvents = {
    JOIN_ROOM: 2,
    GET_CARDS: 4,
    VALID_CARDS: 5,
    PLAY: 8,
    PLAYERS_INFO: 16,
    WELCOME_USER_CB: 14,
    ALLOW_PLAY: 21,
    SEND_GIFT: 25,
    LEAVE_USER: 27,
    REPLAY: 31,
    GAME_END_DATA: 32,
    ADMIN_CHIP: 34,
    CHARGE_USER: 36,
    RECONNECT_GAME_STATE: 37,
    INVALID_ACTION: 38,
    GAME_STATE_RESPONSE: 40,

    REQUEST_DISCONNECT: 41,
};

Object.freeze(UserChannelHandlingEvents);

export const RoomChannelHandlingEvents = {
    UPDATE_GLOBAL_SCORE: 6,
    PLAYED_CARD: 7,
    GAME_START_DATA: 9,
    UPDATE_ROUND_SCORE: 11,
    TALK: 12,
    ANNOUNCEMENT: 17,
    ANNOUNCEMENT_PUBLIC: 19,
    FIRST_PLAY: 23,
    LEAVE_ROOM: 24,
    SEND_GIFT: 25,
    TAKE_GIFT: 26,
    NEXT: 30,
    TAKE_EMOJI: 35,
    USER_LEFT: 39,
    SEND_EMOJI: 34,
};

Object.freeze(RoomChannelHandlingEvents);

export const RoomChannelEmittingEvents = {
    PLAY: 8,
    TALK: 12,
    ANNOUNCEMENT: 17,
    ANNOUNCEMENT_PUBLIC: 19,
    LEAVE_ROOM: 24,
    SEND_GIFT: 25,
    TAKE_GIFT: 26,
    SAY_HELLO: 33,
    SAY_HELLO_RECONNECT: 34,
    SEND_EMOJI: 34,
};

Object.freeze(RoomChannelEmittingEvents);

export const RoomChannelEmittingEventNames = {
    PLAY: 'roomChannel.play',
    LEAVE_ROOM: 'roomChanel.leave',
    SAY_HELLO: 'roomChannel.sayHello',
    SAY_HELLO_RECONNECT: 'roomChannel.sayHelloReconnect',
    SEND_EMOJI: 'roomChannel.sendEmoji',
};

Object.freeze(RoomChannelEmittingEventNames);
