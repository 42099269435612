import React from 'react';

import './secondary-label.scss';

const SecondaryLabel = ({ text, fontSize }) => {
    return (
        <span
            className="secondary-label"
            style={{
                fontSize: (fontSize || 16) + 'px',
            }}
        >
            {text}
        </span>
    );
};

export default SecondaryLabel;
