import React from 'react';
import './round-score-contra.scss';

const RoundScoreContra = ({
    text = '',
    scoreContra = 0,
    scoreContraValue = 0,
    contraContractSuit = 0,
}) => {
    return (
        <tbody className="round_score_line">
            <tr className="round_score_block">
                <td className="round_score_text_contra">
                    <span>{text}</span>
                </td>
                <td className="order_contra">
                    <span>{scoreContra}</span>
                    <span className="order_contra_high">*</span>
                    <span>{scoreContraValue}</span>
                    <span>= {contraContractSuit}</span>
                </td>
            </tr>
        </tbody>
    );
};

export default RoundScoreContra;
