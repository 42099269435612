import React from 'react';
import { cdnImages } from '../../../assets/cdn.assets';

import './back-button.scss';

const BackButton = ({ _onClose = () => {}, text }) => {
    return (
        <div className="back_lobby" onClick={_onClose}>
            <img src={cdnImages.arrow_icon} />
            <span>{text}</span>
        </div>
    );
};
export default BackButton;
