import React, { useEffect } from 'react';
import LobbyName from '../lobby-name/lobby-name';
import './lobby-cash-game.scss';

import LobbyBet from '../lobby-bet/lobby-bet';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from '../../../redux/global.slice';
import routesEnum from '../../../enums/routes.enum';
import socketBridge from '../../../socket';
import {
    UserChannelEmittingEvents,
    UserChannelHandlingEvents,
} from '../../../socket/socket.events';
import { openPopups } from '../../../redux/popup.slice';
import PopupsEnum from '../../../enums/popups.enum';
import roomSingleton from '../../../singletons/room.singleton';
import { useIntl } from 'react-intl/lib';
import { cdnImages } from '../../../assets/cdn.assets';

function roomType(type) {
    switch (type) {
        case 'micro':
            return 51;
        case 'big':
            return 101;
        case 'proffesional':
            return 101;
        case 'bonus':
            return 51;
        default:
            break;
    }
}
const LobbyCashGame = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const me = useSelector((state) => state.me);
    const gameRoom = (type) => {
        socketBridge.emitToUserChannel({
            event: UserChannelEmittingEvents.FIND_ROOM,
            roomType: type,
            userId: me.data.userId,
            partnerId: null,
            scoreEnd: roomType(type),
        });
    };
    const configData = useSelector(
        (state) => state.gameConfig.gameConfigData.rooms
    );

    useEffect(() => {
        function watcher({ event, ...args }) {
            switch (event) {
                case UserChannelHandlingEvents.ALLOW_PLAY:
                    roomSingleton.roomType = args.roomType;
                    if (args.allowPlay) {
                        dispatch(navigate(routesEnum.GAMEPLAY));
                    } else {
                        if (!args.allowPlay && args.message === 1) {
                            dispatch(
                                openPopups({
                                    popupIds: [PopupsEnum.NOT_ENOUGH_RED],
                                })
                            );
                        }
                        // switch (args.message) {
                        //   case AllowPlayMessages.
                        // }
                    }

                    break;
                default:
                    break;
            }
        }

        socketBridge.watchUserChannel(watcher);

        return () => {
            socketBridge.unwatchUserChannel(watcher);
        };
    }, [dispatch]);
    if (!configData) {
        return null;
    }
    return (
        <div className="lobby-cashe-game">
            <div
                className="lobby-cash-game-block"
                onClick={() => gameRoom('micro')}
            >
                <img
                    className="cash_game micro-game"
                    src={cdnImages.micro_game}
                    alt="tournament"
                />
                <div className="cash-game-score">
                    <span>
                        {configData.micro.scoreEnd} {intl.messages.points}
                    </span>
                </div>
                <LobbyName text={intl.messages.table.beginners} type={2} />
                <LobbyBet
                    bet={configData.micro.amount + configData.micro.rake}
                    win={configData.micro.amount * 2}
                    type={1}
                />
            </div>
            <div
                className="lobby-cash-game-block"
                onClick={() => gameRoom('big')}
            >
                <img
                    className="cash_game big-game"
                    src={cdnImages.big_game}
                    alt="tournament"
                />
                <div className="cash-game-score">
                    <span>
                        {configData.big.scoreEnd} {intl.messages.points}
                    </span>
                </div>
                <LobbyName text={intl.messages.table.bigGame} type={2} />
                <LobbyBet
                    bet={configData.big.amount + configData.big.rake}
                    win={configData.big.amount * 2}
                    type={1}
                />
            </div>
            <div
                className="lobby-cash-game-block"
                onClick={() => gameRoom('proffesional')}
            >
                <img
                    className={`cash_game prof-game ${
                        configData.professional.minLevel > me.data.level
                            ? 'lock-table'
                            : ''
                    }`}
                    src={cdnImages.proffesional}
                    alt="tournament"
                />
                {configData.professional.minLevel > me.data.level ? (
                    <div className="lock-img-bonus">
                        <img src={cdnImages.lock} alt="#" />
                    </div>
                ) : null}
                <div
                    className={`cash-game-score ${
                        configData.professional.minLevel > me.data.level
                            ? 'cash-game-score-prof'
                            : ''
                    }`}
                >
                    {configData.professional.minLevel > me.data.level ? (
                        <p>
                            {intl.messages.unlock}{' '}
                            {configData.professional.minLevel}{' '}
                            {intl.messages.level}
                        </p>
                    ) : (
                        <span>
                            {configData.professional.scoreEnd}{' '}
                            {intl.messages.points}
                        </span>
                    )}
                </div>
                <LobbyName text={intl.messages.table.professionals} type={2} />
                <LobbyBet
                    bet={
                        configData.professional.amount +
                        configData.professional.rake
                    }
                    win={configData.professional.amount * 2}
                    type={1}
                />
            </div>
            <div
                className="lobby-cash-game-block"
                onClick={() => gameRoom('bonus')}
            >
                <img
                    className={`cash_game bonus-game${
                        configData.bonus.minLevel > me.data.level
                            ? ' lock-table'
                            : ''
                    }`}
                    src={cdnImages.bonus_game}
                    alt="tournament"
                />
                {configData.bonus.minLevel > me.data.level ? (
                    <div className="lock-img-bonus">
                        <img src={cdnImages.lock} alt="#" />
                    </div>
                ) : null}

                <div
                    className={`cash-game-score-bonus ${
                        configData.bonus.minLevel > me.data.level
                            ? 'cash-game-bonus'
                            : ''
                    }`}
                >
                    <span>
                        {configData.bonus.minLevel > me.data.level ? (
                            <p>
                                {intl.messages.unlock}{' '}
                                {configData.bonus.minLevel}{' '}
                                {intl.messages.level}
                            </p>
                        ) : (
                            <span>
                                {configData.bonus.scoreEnd}{' '}
                                {intl.messages.points}
                            </span>
                        )}
                    </span>
                </div>

                <LobbyName text={intl.messages.table.bonusGame} type={1} />
                <LobbyBet
                    bet={configData.bonus.amount}
                    win={configData.bonus.win}
                    type={2}
                />
            </div>
        </div>
    );
};

export default LobbyCashGame;
