import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
    tournamentLeaderboard_req,
    tournamentUserFollow_req,
} from '../../../../api/tournament/tournament.api';

import LevelBar from '../../../../components/bars/level-bar/level.bar';
import {
    setRewardTournament,
    setUserMeTournament,
} from '../../../../redux/tournament/user-me-tournament.slice';
import { cdnImages } from '../../../../assets/cdn.assets';
function getUserPlace(pos) {
    switch (pos) {
        case 1:
            return <img src={cdnImages.gold_rec} alt="#" />;
        case 2:
            return <img src={cdnImages.silver_rec} alt="#" />;
        case 3:
            return <img src={cdnImages.bronze_rec} alt="#" />;
        case 4:
            return <img src={cdnImages.grey_rec} alt="#" />;
        case 5:
            return <img src={cdnImages.black_rec} alt="#" />;
        default:
            return <img src={cdnImages.black_rec} alt="#" />;
    }
}

const LeaderBoardContent = ({ tournament, data, unfollow, success }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [leaderBoard, setLeaderBoard] = useState([]);
    const [followData, setFollowData] = useState([]);
    const tourId = tournament.id;
    const me = useSelector((state) => state.me.data);

    const tournamentLeaderBoard = async () => {
        const tourLeaderBoard = await tournamentLeaderboard_req(tourId);
        setLeaderBoard(tourLeaderBoard);

        const userMeTourData = tourLeaderBoard.data.leaderboard.filter(
            (item) => item.userId === me.userId
        );
        const rewardTournament = tourLeaderBoard?.data?.config?.prizes;

        dispatch(setUserMeTournament(userMeTourData));
        dispatch(setRewardTournament(rewardTournament));
    };
    useEffect(() => {
        tournamentLeaderBoard();
    }, [success]);

    const followingUser = async (userId) => {
        try {
            const followingUserData = await tournamentUserFollow_req(
                tourId,
                userId
            );
            setFollowData(followingUserData);
            if (followingUserData.status === 'success') {
                tournamentLeaderBoard();
            }
        } catch (e) {}
    };

    return (
        <div className="dashboard-content">
            {leaderBoard?.data?.leaderboard?.map((item, index) => {
                return (
                    <div className="content-line">
                        <div className="user-position-block">
                            <span className="user-position-name">
                                {item.username
                                    ? item.username.slice(0, 7)
                                    : 'agantagexos'}
                            </span>

                            <div className={`position-btn`}>
                                {getUserPlace(item.position)}
                                <span>{index + 1}</span>
                            </div>
                        </div>
                        <div className="user-score-block">
                            <span>{intl.messages.tournament.scores}</span>
                            <p>{item.score}</p>
                        </div>
                        <div className="user-game-count">
                            <span>
                                {item.playedGames}/{tournament.maxGames}
                            </span>
                            <div className="user-game-count-rating">
                                <div className="user-game-progress"></div>
                            </div>
                            <LevelBar
                                percent={
                                    (item.games * 100) / tournament.maxGames
                                }
                            />
                        </div>
                        {item.Follow === 0 && me.userId !== item.userId ? (
                            <div
                                className="tournament-followed-btn"
                                onClick={() => followingUser(item.userId)}
                            >
                                <span>{intl.messages.tournament.follow}</span>
                            </div>
                        ) : (
                            me.userId !== item.userId && (
                                <div
                                    className="tournament-followed-follow"
                                    onClick={() =>
                                        unfollow(tourId, item.userId)
                                    }
                                >
                                    <span>{intl.messages.tournament.unfollow}</span>
                                </div>
                            )
                        )}
                    </div>
                );
            })}
        </div>
    );
};
export default LeaderBoardContent;
