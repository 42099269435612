import React from 'react';
import './language.page.scss';
import { useDispatch, useSelector } from 'react-redux';
import { changeLocale, navigate } from '../../redux/global.slice';
import routesEnum from '../../enums/routes.enum';
import localeEnum from '../../enums/locale.enum';

import { lang_req } from '../../api/lang.api';
import { cdnImages } from '../../assets/cdn.assets';

const LanguagePage = () => {
    const dispatch = useDispatch();
    const me = useSelector((state) => state.me.data);
    const selectLanguage = (lang) => {
        const langReq = async () => {
            try {
                const langData = await lang_req(me.userId, lang);
            } catch (e) {}
        };
        langReq();
        dispatch(changeLocale(lang));
        dispatch(navigate(routesEnum.LOBBY));
    };
    const locale = useSelector((state) => state.global.locale);

    localStorage.setItem('locale', locale);

    return (
        <div id="language-page" className="page">
            <div className="language_wrapper">
                <img
                    src={cdnImages.bazar_blot_logo}
                    alt="candy wings logo"
                    className="logo_language"
                />

                <div className="language_block">
                    <div
                        className="language_ru"
                        onClick={() => selectLanguage(localeEnum.RU)}
                    >
                        РУС
                    </div>
                    <div
                        className="language_ru"
                        onClick={() => selectLanguage(localeEnum.BG)}
                    >
                        BG
                    </div>
                    <div
                        className="language_en"
                        onClick={() => selectLanguage(localeEnum.EN)}
                    >
                        ENG
                    </div>
                </div>
            </div>
            <div className="language_bg_border"></div>
            <div className="language_bg"></div>
        </div>
    );
};
export default LanguagePage;
