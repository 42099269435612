import React, { useState, useEffect } from 'react';
import './base.page.scss';
import { useDispatch, useSelector } from 'react-redux';

import routesEnum from '../enums/routes.enum';

import LoadingPage from './loading/loading.page';
import HomePage from './home/home.page';

import LanguagePage from './language/language.page';

import GamePlay from './game-play/game.play';
import UserInfo from '../global-components/userInfo/user-info';
import MenuGamePlay from '../global-components/menu-game-play/menu-game-play';
import Gifts from './game-play/gifts/gifts';
import Smiles from './game-play/smiles/smiles';
import Chat from './game-play/chat/chat';
import StorePage from './store-page/store-page';
import { getGameConfig_req } from '../api/game.config.api';

import { goldChipsData, vipChipsData } from '../redux/notEnough.slice';
import { setGameConfigData } from '../redux/game-config.slice';

import UserPanel from '../components/panels/userPanel/user.panel';
import Tournament from './tournament/tournament';
import TournamentDashboard from './tournament/tournament-dashboard/tournament-dashboard';
import TournamentInfo from './tournament/tournament-info/tournament-info';
import { setUserUpdate } from '../redux/user.update.slice';
import { userInfo_req } from '../api/user.api';
import WheelPage from '../temp/wheelPage/wheel.page';
import UserLoginFirst from '../components/user-login/user-login-first/user-login-first';
import UserLogin from '../components/user-login/user-login';

const BasePage = () => {
    const dispatch = useDispatch();
    const [configData, setConfigData] = useState([]);
    const [userInfoUpdate, setUserInfoUpdate] = useState([]);
    const me = useSelector((state) => state.me.data);

    const route = useSelector((state) => state.global.route);

    const userInfoData = useSelector((state) => state.userInfo.data);
    const rightMenu = useSelector((state) => state.userMenuGame.rightMenu);
    const giftMenu = useSelector((state) => state.userMenuGame.gift);
    const smileOpened = useSelector((state) => state.userMenuGame.smile);
    const chatOpened = useSelector((state) => state.userMenuGame.chat);

    const gameConfig = async () => {
        try {
            const configGame = await getGameConfig_req(me.userId, me.zone);
            setConfigData(configGame);
            dispatch(setGameConfigData(configGame));
        } catch (e) {}
    };
    const userUpdateReq = async () => {
        const userId = me && me.userId;

        const userUpdate = await userInfo_req(userId);

        dispatch(setUserUpdate(userUpdate));
    };

    useEffect(() => {
        if (me?.userId) {
            gameConfig();
            userUpdateReq();
        }
    }, [me]);
    const yellowData =
        configData &&
        configData.packs &&
        configData.packs.chips.filter((el) => el.chipType === 'yellow');

    const vipPacks = configData && configData.vipPacks;
    if (vipPacks) {
        dispatch(vipChipsData(vipPacks));
    }
    dispatch(goldChipsData(yellowData));
    const renderPage = () => {
        switch (route) {
            case routesEnum.LOADING:
                return <LoadingPage />;
            case routesEnum.LOBBY:
                return <HomePage />;
            case routesEnum.GAMEPLAY:
                return <GamePlay />;
            case routesEnum.LANGUAGE:
                return <LanguagePage />;
            case routesEnum.STORE:
                return <StorePage />;
            case routesEnum.TOURNAMENT:
                return <Tournament />;
            case routesEnum.TOURNAMENT_DASHBOARD:
                return <TournamentDashboard />;
            case routesEnum.TOURNAMENT_INFO:
                return <TournamentInfo />;
            case routesEnum.LOGIN:
                return <UserLogin />;
            case routesEnum.LOGIN_USER:
                return <UserLoginFirst />;
            default:
                return <LoadingPage />;
        }
    };

    return (
        <>
            <div id="base-page">
                {route !== 0 && route !== 1 && route !== 9 && route !== 8 && (
                    <UserPanel userInfoUpdate={userInfoUpdate} />
                )}

                {renderPage()}

                {userInfoData && <UserInfo userInfo={userInfoData} />}
                {rightMenu.rightMenuOpenedState !== 3 && <MenuGamePlay />}
                {giftMenu.openedState !== 3 && <Gifts />}
                {smileOpened.smileOpenedState !== 3 && <Smiles />}
                {chatOpened.chatOpenedState !== 3 && <Chat />}
                {/* <MenuGamePlay /> */}
            </div>
        </>
    );
};

export default BasePage;
