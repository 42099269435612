import React from 'react';
import { useIntl } from 'react-intl';
import './best-score.scss';

const BestScore = ({ bestScore }) => {
    const intl = useIntl();
    return (
        <div className="best_score">
            <div className="best_third">
                <div className="best_second">
                    <div className="best_yellow">
                        <div className="score_end">
                            <span className="best_score_title">
                                {intl.messages.gamePlay.endGame.bestScore}
                            </span>
                            <span className="best_score_number">
                                {bestScore}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BestScore;
