import { createSlice } from '@reduxjs/toolkit';

export const rewardSlice = createSlice({
    name: 'rewardData',
    initialState: {
        data: [],
    },
    reducers: {
        setReward: (state, action) => {},
    },
});
export const { setReward } = rewardSlice.actions;

export default rewardSlice.reducer;
