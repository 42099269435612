import { createSlice } from '@reduxjs/toolkit';

export const WelcomeUserCbSlice = createSlice({
    name: 'welcomeUserCb',
    initialState: {
        data: null,
    },
    reducers: {
        welcomeUserRefresh: (state, { payload }) => {
            if (!payload) {
                return state;
            }
            state.data = payload.data;
        },
    },
});
export const { welcomeUserRefresh } = WelcomeUserCbSlice.actions;

export default WelcomeUserCbSlice.reducer;
