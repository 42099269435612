import React from 'react';
import { useIntl } from 'react-intl';
import './tour-rewards-tooltip.scss';

const TourRewardsTooltip = () => {
    const intl = useIntl()
    return (
        <div className="tour-rewards-tooltip">
            <p>{intl.messages.tournament.keep_position}</p>
        </div>
    );
};

export default TourRewardsTooltip;
