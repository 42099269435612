import gsap, { TweenLite } from 'gsap';
import EventEmitter from 'eventemitter3';

import PIXI from '../wrappers/pixi.wrapper';
import Card from './card';
import { addDelay } from '../helpers/timings.helper';

gsap.ticker.lagSmoothing(false);

export const pixiCardsEvents = {
    PLAYED_CARD: 0,
};

class PixiCards {
    constructor(viewRef, totalUsers = 4, totalCardsPerUser = 8) {
        this.totalUsers = totalUsers;
        this.totalCardsPerUser = 5;
        this.secondaryDealCards = 3;

        this.deckCount = totalUsers * totalCardsPerUser;

        this.cardSample = new Card();

        this.playedCards = [];
        this.emitter = new EventEmitter();

        this.point = new PIXI.Point(0, 0);
        this.playedCardSpace = 0.2;

        this.myDeckData = {
            radius: 80,
            scale: 1,
        };

        this.theirDeckData = {
            radius: 50,
            scale: 0.8,
        };

        this.playedCardData = {
            scale: 0.8,
        };

        this.app = new PIXI.Application({
            width: 1000,
            height: 580,
            transparent: true,
        });

        this.cardContainers = [];
        this.lastNormalizedData = [];

        this.createContainers(totalUsers);
        // this.createDeck();
        viewRef.current.appendChild(this.app.view);

        this.handleTabChange();
    }

    handleTabChange = () => {
        document.addEventListener('visibilitychange', () => {
            // if(document.visibilityState === 'hidden') {
            //     gsap.ticker.lagSmoothing(0);
            // } else {
            //     gsap.ticker.lagSmoothing(500, 33);
            // }
        });
    };

    createContainers() {
        this.deckContainer = new PIXI.Container();
        this.app.stage.addChild(this.deckContainer);

        if (this.totalUsers) {
            for (let user = 0; user < this.totalUsers; user++) {
                this.cardContainers[user] = new PIXI.Container();
                this.cardContainers[user].user = user;
                this.app.stage.addChild(this.cardContainers[user]);

                const containerPosition = this.getContainerPosition(user);
                this.cardContainers[user].x = containerPosition.x;
                this.cardContainers[user].y = containerPosition.y;
                this.cardContainers[user].angle = containerPosition.angle;
            }
        }
    }
    clearDeck() {
        for (let user = 0; user < this.cardContainers.length; user++) {
            this.cardContainers[user].removeChildren();
        }
    }
    deactivateUser(user) {
        const userCards = this.getCardsFromContainer(user);

        for (const card of userCards) {
            card.tint = 0xffffff;

            card.mouseover = () => {};
            card.mouseout = () => {};
            card.removeAllListeners();
            // card.on('touchstart', () => {});
        }
    }
    getContainerPosition(user) {
        const positions = [
            {
                x: this.app.view.width / 2,
                y: this.app.view.height - 20,
                angle: 0,
            },
            {
                x: this.app.view.width + 20,
                y: this.app.view.height / 2,
                angle: 270,
            },
            {
                x: this.app.view.width / 2,
                y: -20,
                angle: 180,
            },

            {
                x: -20,
                y: this.app.view.height / 2,
                angle: 90,
            },
        ];

        return positions[user];
    }
    announceCards(cardGroup) {
        if (cardGroup && cardGroup.length) {
            let joinedCards = [];
            cardGroup.forEach((cards) => {
                joinedCards = joinedCards.concat(cards);
            });
            const userCards = this.getCardsFromContainer(0, true);
            if (userCards && userCards.length) {
                for (const userCard of userCards) {
                    if (joinedCards.includes(userCard.cardId)) {
                        TweenLite.to(userCard, 0.3, {
                            y: Number(userCard.y - 10),
                        });
                    }
                }
            }
        }
    }
    createDeck(isBazar) {
        let user = -1;
        for (let i = 0; i < (isBazar ? 12 : this.deckCount); i++) {
            const card = new Card();
            card.x = 50;
            card.y = 50;
            card.angle = -20;

            if ((isBazar && i % 3 === 0) || (!isBazar && i % 8 === 0)) {
                user++;
            }

            card.user = user;
            this.deckContainer.addChild(card);
        }
    }

    async dealCards(users) {
        this.clearDeck();
        this.removeAllPlayedCards();

        if (users && users.length) {
            const promises = [];
            for (let user = users.length - 1; user >= 0; user--) {
                promises.push(
                    this.dealToUser(user, (users.length - user) * 1200, false)
                );
            }

            await Promise.all(promises);
        }
    }

    removeTempValidCards() {
        if (this.validCards) {
            this.validCards = null;
        }
    }

    getCardPoint(angle, distance, user) {
        const radius = user === 0 ? 650 : 300;
        const x = radius * Math.cos((-angle * Math.PI) / 180) * distance;
        const y =
            radius + radius * Math.sin((-angle * Math.PI) / 180) * distance;

        return { x, y };
    }

    async dealToUser(user, delay, isSecondary) {
        if (delay) {
            await addDelay(delay);
        }

        const userCards = this.getUserCardsFromDeck(user, !isSecondary && 5);

        userCards.reverse().forEach((card, index) => {
            TweenLite.to(card, 0.3, {
                x: card.x + 120,
                y: card.y + 120,
            }).delay(index * 0.1);
        });

        this.moveToUserHand(user, userCards, isSecondary);
    }

    async moveToUserHand(user, userCards) {
        await addDelay(userCards.length * 140);
        // setTimeout(() => {
        userCards.forEach((card) => {
            this.moveChild(card, this.cardContainers[user]);
        });

        const cardsAngleData = this.getCardsAngle(user);

        userCards.reverse().forEach((card, index) => {
            const angle = cardsAngleData[index];
            const cardPosition = this.getCardPoint(90 + angle, 1, user);

            TweenLite.to(card, 0.5, {
                x: user === 0 ? cardPosition.x : 0,
                y: user === 0 ? cardPosition.y : 95,
                angle:
                    user === 0
                        ? cardsAngleData[userCards.length - 1 - index]
                        : card.angle,
            }).delay(index * 0.02);

            TweenLite.to(card.anchor, 0.5, {
                x: 0.5,
                y: user === 0 ? 1 : 0.5,
            }).delay(index * 0.02);
        });

        if (user !== 0) {
            setTimeout(() => {
                userCards.forEach((card, index) => {
                    const angle = cardsAngleData[index];
                    const cardPosition = this.getCardPoint(90 + angle, 1, user);
                    card.angle = 0;
                    card.scale.set(this.theirDeckData.scale);

                    TweenLite.to(card.anchor, 0.3, {
                        x: 0.5,
                        y: 1,
                    }).delay(index * 0.01);

                    TweenLite.to(card, 0.3, {
                        x: 0,
                        y: 0,
                        onComplete() {
                            TweenLite.to(card, 0.3, {
                                x: cardPosition.x,
                                y: cardPosition.y,
                                angle: cardsAngleData[
                                    userCards.length - 1 - index
                                ],
                            });
                        },
                    }).delay(index * 0.01);
                });
            }, userCards.length * 100);
        }
        // }, userCards.length * 140);
    }

    async secondaryDealt(user, userCardIds, cardIndexes) {
        await addDelay(50);

        const cardsInDeck = this.getUserCardsFromDeck(user);

        cardsInDeck.forEach((card, index) => {
            TweenLite.to(card, 0.05, {
                x: card.x + 120,
                y: card.y + 120,
            }).delay(index * 0.05);
        });

        await addDelay(100);

        setTimeout(() => {
            cardsInDeck.forEach((cardInDeck, index) => {
                this.moveChild(cardInDeck, this.cardContainers[user]);
                if (user === 0) {
                    cardInDeck.cardId = userCardIds[index];
                    cardInDeck.changeCard(userCardIds[index]);
                }
            });

            const cardsAngleData = this.getCardsAngle(user);
            let userCards = this.getCardsFromContainer(user, true);
            if (cardIndexes) {
                [0, 1, 3, 5, 7].forEach((cardIndex, index) => {
                    if (userCards[index]) {
                        userCards[index].cardIndex = cardIndex;
                    }
                });
            }

            userCards = userCards.slice(userCards.length - 3, userCards.length);

            if (cardIndexes) {
                userCards.forEach((card, index) => {
                    card.cardIndex = cardIndexes[index];
                });

                this.cardContainers[user].children.sort(
                    (a, b) => a.cardIndex - b.cardIndex
                );
            }

            if (user === 0) {
                const multipliers = [
                    -this.cardSample.width,
                    0,
                    this.cardSample.width,
                ];
                userCards.forEach((card, index) => {
                    card.anchor.set(0);
                    const cardAngleMemo = Number(card.angle);
                    card.angle = 0;
                    const localPosition = card.toLocal(
                        {
                            x: this.app.view.width / 2 + multipliers[index],
                            y: this.app.view.height / 2,
                        },
                        this.app.stage,
                        this.point
                    );

                    card.angle = cardAngleMemo;

                    TweenLite.to(card, 0.4, {
                        x: card.x + localPosition.x,
                        y: card.y + localPosition.y,
                        angle: 0,
                    });

                    TweenLite.to(card.anchor, 0.4, {
                        x: 0.5,
                        y: 0.5,
                    });
                });

                setTimeout(() => {
                    this.normalizeUserHand(user, true);
                    if (this.validCards) {
                        this.activateUser(user, this.validCards);
                        this.validCards = null;
                    }
                }, 600);
            } else {
                setTimeout(() => {
                    userCards.forEach((card, index) => {
                        TweenLite.to(card, 0.5, {
                            x: 0,
                            y: 95,
                            onComplete: () => {
                                card.angle = 0;
                            },
                        }).delay(index * 0.02);

                        TweenLite.to(card.anchor, 0.5, {
                            x: 0.5,
                            y: 0.5,
                        }).delay(index * 0.02);
                    });

                    setTimeout(() => {
                        this.normalizeUserHand(user, true);
                    }, 600);
                }, userCards.length * 100);
            }
        }, 3 * 140);
    }

    getCardsAngle(user) {
        const userCards = this.getCardsFromContainer(user, true);
        const angleDiff = 3;

        const offset = userCards.length % 2 === 0 ? angleDiff / 2 : 0;

        const startRotation = -(
            Math.floor(userCards.length / 2) * angleDiff -
            offset
        );

        const angles = [];

        for (let i = 0; i < userCards.length; i++) {
            angles.push(startRotation + angleDiff * i);
        }

        return angles;
    }

    flipDealtCards(user, userCardIds, cardIndexes) {
        const userCards = this.getCardsFromContainer(user);
        let index = 0;
        for (let i = 0; i < userCards.length; i++) {
            const card = userCards[i];
            if (card) {
                card.cardIndex = cardIndexes[index];
                const cardId = userCardIds[index];

                TweenLite.to(card.scale, 0.25, {
                    x: 0,
                    onComplete() {
                        card.changeCard(cardId);
                        card.cardId = cardId;
                        TweenLite.to(card.scale, 0.25, {
                            x: 1,
                        });
                    },
                });
            }
            index++;
        }
    }
    deactivateCard(card) {
        card.tint = 0x999999;
        card.mouseover = () => {};
        card.mouseout = () => {};
        card.removeAllListeners();
    }
    activateUser(user, validCards) {
        const userCards = this.getCardsFromContainer(user, true);

        this.validCards = validCards;
        for (const card of userCards) {
            if (!validCards.includes(card.cardId)) {
                this.deactivateCard(card);
            } else {
                card.tint = 0xffffff;
                card.mouseover = () => {
                    card.prevY = Number(card.y);
                    TweenLite.to(card, 0.2, {
                        y: card.y - 10,
                    });
                    // card.y -= 10;
                };

                card.mouseout = () => {
                    if (card.prevY) {
                        TweenLite.to(card, 0.2, {
                            y: Number(card.prevY),
                        });
                        // card.y = Number(card.prevY);
                    } else {
                        TweenLite.to(card, 0.2, {
                            y: 10,
                        });
                        // card.y += 10;
                    }
                };

                card.on('mousedown', () =>
                    this.emitter.emit(pixiCardsEvents.PLAYED_CARD, {
                        user,
                        card,
                    })
                );

                card.on('touchstart', () =>
                    this.emitter.emit(pixiCardsEvents.PLAYED_CARD, {
                        user,
                        card,
                    })
                );
            }
        }
    }

    static visualizeCardPoints(container, points) {
        const g = new PIXI.Graphics();

        for (let i = 1; i < points.length; i++) {
            g.beginFill(0xff0022);
            g.drawCircle(points[i].x, points[i].y, 10);
            g.endFill();
        }

        container.addChild(g);
    }

    getCardsFromContainer(user, notPlayed) {
        const filtered = this.cardContainers[user].children.filter(
            (child) => child instanceof Card
        );

        if (notPlayed) {
            return filtered.filter((card) => !card.played);
        }

        return filtered;
    }

    playCard(user, cardId, handNumber) {
        if (!cardId) {
            return;
        }

        // if (!cardId) {
        //     cardId = 'b';
        // }

        let card;

        if (user === 0) {
            card = this.getCardById(user, cardId);
        } else {
            card = this.getMiddleCard(user);
        }

        if (!card) {
            return;
        }

        card.cardId = cardId;
        card.played = true;
        card.handNumber = handNumber;

        card.changeCard(cardId);

        this.playedCards.push(card);
        card.mouseover = () => {};
        card.mouseout = () => {};

        if (card.activeY) {
            card.y = Number(card.activeY);
        }

        const existingAngle = Number(card.angle);
        const existingScale = Number(card.scale.x);
        const existingAnchorX = Number(card.anchor.x);
        const existingAnchorY = Number(card.anchor.y);

        card.angle = 0;
        card.scale.set(1);
        card.anchor.set(0);

        const position = card.toLocal(
            {
                x: this.app.view.width / 2,
                y: this.app.view.height / 2,
            },
            this.app.stage,
            this.point
        );

        card.anchor.set(existingAnchorX, existingAnchorY);
        card.angle = existingAngle;
        card.scale.set(existingScale);

        this.playedCards.forEach((bitaCard, index) => {
            const userCardsContainer = this.app.stage.children.find(
                (child) => child.user === bitaCard.user
            );

            if (userCardsContainer) {
                userCardsContainer.zIndex = index + 1;
            }
        });

        this.app.stage.children.sort((a, b) => a.zIndex - b.zIndex);

        TweenLite.to(card, 0.3, {
            x: card.x + position.x,
            y: card.y + position.y,
            angle: 0,
        }).delay(0.08);

        TweenLite.to(card.anchor, 0.3, {
            x: 0.45,
            y: 0.2,
        }).delay(0.08);

        TweenLite.to(card.scale, 0.3, {
            x: 1,
            y: 1,
        }).delay(0.08);

        this.normalizeUserHand(user);
    }

    getAngleShift(cardsLength) {
        const shift = {
            x: 1.4,
            y: 0.2,
        };

        if (cardsLength === 5) {
            shift.x = 1.4;
            shift.y = 0.4;
        }

        return shift;
    }

    normalizeUserHand(user, isSecondary) {
        const cardsAngleData = this.getCardsAngle(user);
        const userCards = this.getCardsFromContainer(user, true);

        userCards.reverse().forEach((card, index) => {
            const angle = cardsAngleData[index];
            const cardPosition = this.getCardPoint(90 + angle, 1, user);

            TweenLite.to(card, isSecondary ? 0.6 : 0.2, {
                x: cardPosition.x,
                y: cardPosition.y,
                angle: cardsAngleData[userCards.length - 1 - index],
            });

            TweenLite.to(card.anchor, isSecondary ? 0.6 : 0.2, {
                x: 0.5,
                y: 1,
            });

            if (user !== 0) {
                TweenLite.to(card.scale, isSecondary ? 0.6 : 0.2, {
                    x: this.theirDeckData.scale,
                    y: this.theirDeckData.scale,
                });
            }
        });
    }

    getCardById(user, cardId) {
        const filtered = this.cardContainers[user].children.filter(
            (child) => child.cardId === cardId
        );
        return filtered[0];
    }

    getMiddleCard(user) {
        const cards = this.getCardsFromContainer(user, true);

        if (cards.length) {
            if (cards.length === 1) {
                return cards[0];
            }
            return cards[Math.ceil(cards.length / 2)];
        }

        return undefined;
    }

    moveToBita(user, handNumber) {
        const bitaPositions = [
            { x: this.app.view.width / 2, y: this.app.view.height * 0.9 },
            { x: this.app.view.width - 100, y: this.app.view.height / 2 },
            { x: this.app.view.width / 2, y: 100 },
            { x: 100, y: this.app.view.height / 2 },
        ];

        let currentHandCount = 0;
        this.playedCards.forEach((playedCard) => {
            if (playedCard.handNumber <= handNumber) {
                const localPosition = playedCard.toLocal(
                    bitaPositions[user],
                    this.app.stage,
                    this.point
                );

                TweenLite.to(playedCard, 0.4, {
                    x: playedCard.x + localPosition.x,
                    y: playedCard.y + localPosition.y,
                });

                TweenLite.to(playedCard.scale, 0.4, {
                    x: 0,
                    y: 0,
                });
                currentHandCount++;
            }
        });

        this.playedCards = this.playedCards.filter(
            (card) => card.handNumber !== handNumber
        );

        setTimeout(() => {
            this.removeAllPlayedCards(handNumber);
        }, (currentHandCount + 1) * 400);
    }

    removeAllPlayedCards(handNumber) {
        for (let user = 0; user < this.totalUsers; user++) {
            this.removePlayedCards(user, handNumber);
        }
    }

    removePlayedCards(user, handNumber) {
        this.cardContainers[user].children.forEach((child) => {
            if (
                child.played &&
                (handNumber === undefined || child.handNumber <= handNumber)
            ) {
                this.cardContainers[user].removeChild(child);
            }
        });
    }

    getCardFromPlayedCards(cardId) {
        return this.playedCards[
            this.playedCards.findIndex((card) => card.cardId === cardId)
        ];
    }

    getUserCardsFromDeck(user, cardsCount) {
        const cards = this.deckContainer.children.filter(
            (child) => child.user === user
        );
        if (cardsCount) {
            return cards.slice(0, cardsCount);
        }

        return cards;
    }

    moveChild(child, parent) {
        child.displayObjectUpdateTransform();
        parent.displayObjectUpdateTransform();

        const zero = new PIXI.Point(0, 0);
        const forward = new PIXI.Point(1, 0);

        // child angle in parent (faulty)
        child.worldTransform.apply(forward, forward);
        child.worldTransform.apply(zero, zero);
        parent.worldTransform.applyInverse(forward, forward);
        parent.worldTransform.applyInverse(zero, zero);
        child.rotation = Math.atan2(forward.y - zero.y, forward.x - zero.x);

        // update position
        zero.set(0, 0);
        child.worldTransform.apply(zero, child.position);
        parent.worldTransform.applyInverse(child.position, child.position);

        // reverse transform for child
        let _parent = child.parent;
        while (_parent) {
            child.scale.x *= _parent.scale.x;
            child.scale.y *= _parent.scale.y;
            _parent = _parent.parent;
        }

        // get parent hierarchy
        const parents = [];
        _parent = parent;
        while (_parent) {
            parents.splice(0, 0, _parent);
            _parent = _parent.parent;
        }

        // reapply transforms
        for (let i = 0; i < parents.length; i++) {
            _parent = parents[i];

            child.scale.x /= _parent.scale.x;
            child.scale.y /= _parent.scale.y;
        }

        parent.addChild(child);
    }
    resetGame(cards, cardsLength, currentHand) {
        this.resetCards(cards, cardsLength);
    }
    resetCards(cards, cardsLength) {
        this.clearDeck();
        for (let user = 0; user < cardsLength.length; user++) {
            const container = this.cardContainers[user];
            const totalCardsForUser = cardsLength[user];
            for (
                let cardIndex = 0;
                cardIndex < totalCardsForUser;
                cardIndex++
            ) {
                const card = new Card(user);
                container.addChild(card);
            }
            const cardsAngleData = this.getCardsAngle(user);
            const userCards = this.getCardsFromContainer(user, true);
            userCards.reverse().forEach((card, index) => {
                const angle = cardsAngleData[index];
                const cardPosition = this.getCardPoint(90 + angle, 1, user);
                const neededIndex = totalCardsForUser - (index + 1);
                card.x = cardPosition.x;
                card.y = cardPosition.y;
                card.angle = cardsAngleData[neededIndex];
                card.anchor.set(0.5, 1);
                if (user !== 0) {
                    card.scale.set(this.theirDeckData.scale);
                } else {
                    card.cardId = cards[index];
                    card.changeCard(cards[index]);
                    card.scale.set(1);
                }
            });
        }
    }
}

export default PixiCards;
