class RoomSingleton {
    constructor() {
        this._roomId = '';
        this._bazarState = 0;
        this._playState = 0;
        this._announceState = 0;
        this._roomType = '';
    }

    reset() {
        this._roomId = '';
        this._bazarState = 0;
        this._playState = 0;
        this._announceState = 0;
        this._roomType = '';
    }
    set roomType(_roomType) {
        this._roomType = _roomType;
    }

    get roomType() {
        return this._roomType;
    }

    set roomId(_roomId) {
        this._roomId = _roomId;
    }

    get roomId() {
        return this._roomId;
    }

    set bazarState(_bazarState) {
        this._bazarState = _bazarState;
    }

    get bazarState() {
        return this._bazarState;
    }

    set playState(_playState) {
        this._playState = _playState;
    }

    get playState() {
        return this._playState;
    }
    set announceState(_announceState) {
        this._announceState = _announceState;
    }

    get announceState() {
        return this._announceState;
    }
}

export default new RoomSingleton();
