import React from 'react';
import './button-3d.scss';

const ButtonTreeD = ({ text = '' }) => {
    return (
        <div className="button-tree">
            <button>
                <span>{text}</span>
            </button>
            <div className="button-tree-under"></div>
        </div>
    );
};
export default ButtonTreeD;
