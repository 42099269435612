import { createSlice } from '@reduxjs/toolkit';
export const notEnoughSlice = createSlice({
    name: 'notEnough',
    initialState: {
        goldChips: [],
        vipChips: [],
    },
    reducers: {
        goldChipsData: (state, action) => {
            state.goldChips = action.payload;
        },
        vipChipsData: (state, action) => {
            state.vipChips = action.payload;
        },
    },
});

export const { goldChipsData, vipChipsData } = notEnoughSlice.actions;

export default notEnoughSlice.reducer;
