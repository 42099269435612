export function groupByType(data) {
    const groupedByType = {};

    data &&
        data.forEach((scoreData) => {
            if (!groupedByType[scoreData.type]) {
                groupedByType[scoreData.type] = scoreData.value;
            } else {
                groupedByType[scoreData.type] += scoreData.value;
            }
        });

    return groupedByType;

    // const grouped = [];
    //
    // for (const type in groupedByType) {
    //   grouped.push({
    //     type,
    //     value: groupedByType[type],
    //   });
    // }
    //
    // return grouped;
}
export function groupByAnnounce(data) {
    const announceType = {};
    data.forEach((name) => {
        if (!announceType[name]) {
            announceType[name] = 1;
        } else {
            announceType[name]++;
        }
    });
    return announceType;
}
