import { createSlice } from '@reduxjs/toolkit';
import localeEnum from '../enums/locale.enum';
import routesEnum from '../enums/routes.enum';

export const globalSlice = createSlice({
    name: 'global',
    initialState: {
        locale: localStorage.getItem('locale') || localeEnum.RU,
        route: localStorage.beloteKings ? routesEnum.LOADING : routesEnum.LOGIN,
        connectionSocket: false,
        menuOpened: false,
        initiated: false,
        navigateData: null,
        joined: false,
        currentTab: -1,
        firstUser: 0,
    },
    reducers: {
        changeLocale: (state, action) => {
            state.locale = action.payload;
        },
        navigate(state, action) {
            if (typeof action.payload === 'number') {
                state.route = action.payload;
            } else {
                state.route = action.payload.route;
                state.navigateData = action.payload.navigateData || null;
                state.joined = action.payload.joined || false;
                state.currentTab = action.payload.currentTab || -1;
            }
        },
        openCloseMenu: (state, action) => {
            if (typeof action === 'undefined') {
                state.menuOpened = !state.menuOpened;
            } else {
                state.menuOpened = !state.menuOpened;
            }
        },
        setInitiated: (state, action) => {
            state.initiated = !!action.payload;
        },
        changeSocketState: (state, action) => {
            state.connectionSocket = !state.connectionSocket;
        },
        changeFirstUser: (state, action) => {
            state.firstUser = action.payload;
        },
    },
});

export const {
    changeLocale,
    navigate,
    openCloseMenu,
    setInitiated,
    changeSocketState,
    changeFirstUser,
} = globalSlice.actions;

export default globalSlice.reducer;
