import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TourUserPanel from '../../../components/panels/userPanel/tour-user-panel/tour-user-panel';
import DashboardHeader from './dashboard-header/dashboard-header';
import './tournament-dashboard.scss';
import DashboardContent from './dashboard-content/dashboard-content';
import DashboardGames from './dashboard-games/dashboard-games';
import TournamentDashboardTab from './dashboard-tab/dashboard-tab';
import TournamentBg from '../../../components/background/tournament-bg/tournament-bg';

const TournamentDashboard = () => {
    const dispatch = useDispatch();
    const me = useSelector((state) => state.me.data);
    const [leaderBoard, setLeaderBoard] = useState([]);
    const [tourTabIndex, setTourTabIndex] = useState(0);
    const tournamentData = useSelector((state) => state.global.navigateData);

    const tourData =
        tournamentData && tournamentData.tourData && tournamentData.tourData[0];

    const dashboardTab = (tourTabIndex) => {
        setTourTabIndex(tourTabIndex);
    };
    return (
        <div className="tournament-dashboard-page">
            <TourUserPanel data={tourData} />
            <TournamentBg>
                <div className="tournament-dashboard-wrap">
                    <div className="tournament-dashboard">
                        <DashboardHeader />
                        <div className="dashboard-wrapper">
                            <TournamentDashboardTab
                                tourTabIndex={tourTabIndex}
                                dashboardTab={dashboardTab}
                            />
                            <DashboardContent tourTabIndex={tourTabIndex} />
                        </div>
                    </div>
                    <DashboardGames tourData={tourData} />
                </div>
            </TournamentBg>
        </div>
    );
};

export default TournamentDashboard;
