const PopupsEnum = {
    GREEN_POPUP: 1,
    SETTINGS_POPUP: 2,
    GIFT_FORM_POPUP: 3,
    VIP_POPUP: 4,
    VIP_INFO_POPUP: 5,
    STORE_POPUP: 6,
    GIFT_CANCEL_POPUP: 7,
    VIP_NICKNAME_POPUP: 8,
    LEVEL_UP: 9,
    ADMIN_CHIP_POPUP: 10,
    NOT_ENOUGH_GOLD: 11,
    NOT_ENOUGH_GREEN: 12,
    NOT_ENOUGH_RED: 13,
    EXIT_GAME: 17,
    BUY_CHIP_POPUP: 18,
    TOURNAMENT_UNLOCK: 19,
    TOURNAMENT_TABLE: 20,

    UNFOLLOW_POPUP: 21,
    TOURNAMENT_RESULT: 22,
};

export default PopupsEnum;
