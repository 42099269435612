import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import BackgroundPopup from '../../components/background/background-popup/background-popup';
import ButtonTreeD from '../../components/buttons/button_3d/button-3d';
import CloseButton from '../../components/buttons/close-button/close-button';
import routesEnum from '../../enums/routes.enum';
import { navigate } from '../../redux/global.slice';
import { welcomeUserRefresh } from '../../redux/welcome.user.cb.slice';
import { closePopup } from '../../redux/popup.slice';
import roomSingleton from '../../singletons/room.singleton';
import socketBridge from '../../socket';
import { RoomChannelEmittingEvents } from '../../socket/socket.events';
import './exit-game-popup.scss';
import { cdnImages } from '../../assets/cdn.assets';

const ExitGamePopup = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const partnerIdData = useSelector((state) => state.userInfo.partnerId);
    const exitGame = () => {
        socketBridge.emitToRoomChannel({
            event: RoomChannelEmittingEvents.LEAVE_ROOM,
            partnerId: partnerIdData,
        });
        socketBridge.unwatchRoomChannel();
        roomSingleton.bazarState = 0;
        roomSingleton.playState = 0;
        roomSingleton.reset();
        dispatch(welcomeUserRefresh({ data: null }));
        dispatch(navigate(routesEnum.LOBBY));
        dispatch(closePopup());
    };
    const closeExitGame = () => {
        dispatch(closePopup());
    };
    return (
        <div className="exit-game-popup">
            <BackgroundPopup>
                <CloseButton imageId={cdnImages.close_popup} _onClose={closeExitGame} />
                <div className="exit-game-content">
                    <img src={cdnImages.exit_question} alt="#" />
                    <p>{intl.messages.popups.exitGame.exitGameText}</p>
                </div>
                <div className="exit-game-button-block">
                    <div className="exit-first-button" onClick={exitGame}>
                        <ButtonTreeD text={intl.messages.yes} />
                    </div>
                    <div className="exit-second-button" onClick={closeExitGame}>
                        <ButtonTreeD text={intl.messages.no} />
                    </div>
                </div>
            </BackgroundPopup>
        </div>
    );
};

export default ExitGamePopup;
