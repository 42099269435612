import React, { useState, useEffect } from 'react';
import './user-info.scss';

import { useIntl } from 'react-intl/lib';
import PieChart from '../../components/charts/pie-chart/pie-chart';
import SecondaryLabel from '../../components/labels/secondary-label/secondary-label';
import { useDispatch, useSelector } from 'react-redux';
import { openPopups } from '../../redux/popup.slice';
import PopupsEnum from '../../enums/popups.enum';
import { setCurrentUserInfo } from '../../redux/userinfo.slice';
import { soccialLogin } from '../../redux/social-login.slice';
import { cdnImages } from '../../assets/cdn.assets';

const UserInfo = ({ userInfo }) => {
    const intl = useIntl();
    const [opened, setOpened] = useState(false);
    const [animated, setAnimated] = useState(true);
    const [animating, setAnimating] = useState(false);
    const [animatedPercent, setAnimatedPercent] = useState(0);
    const navigateRoute = useSelector((state) => state.global.route);
    const zoneStorage = localStorage.zone;
    const dispatch = useDispatch();

    const percent = (userInfo.points * 100) / userInfo.edge;
    const me = useSelector((state) => state.me.data);

    useEffect(() => {
        setOpened(true);
        setTimeout(() => {
            setAnimating(true);
            setAnimatedPercent(percent);
            setTimeout(() => {
                setAnimating(false);
            }, 500);
        }, 1000);
    }, []);

    const closeUserInfo = () => {
        setOpened(false);
        setTimeout(() => {
            dispatch(setCurrentUserInfo(null));
        }, 400);
    };
    const openVipPopup = () => {
        dispatch(setCurrentUserInfo(null));
        dispatch(
            openPopups({
                popupIds: [PopupsEnum.VIP_POPUP],
            })
        );
    };
    const loginSocial = (value) => {
        dispatch(soccialLogin(value));
    };

    return (
        <div
            className={` ${
                navigateRoute === 3 ? 'user-info-game' : 'user-info'
            } ${opened ? ' open' : ''}`}
        >
            <div className="user-info-left-side">
                <div className="main-user-info">
                    {/* {me.userId === userInfo.userId && (
                        <div className="edit-username" onClick={openVipPopup}>
                            <p>{intl.messages.userInfo.editUsername}</p>
                            <img src={edit} alt="edit-icon" />
                        </div>
                    )} */}

                    <img
                        src={cdnImages.main_user_info_back}
                        alt="background"
                        className={'main-user-info-back'}
                    />

                    <div className="inner-wrapper">
                        <div className="user-pic-name">
                            <div className="user-img-wrapper">
                                <img
                                    src={
                                        userInfo.avatar
                                            ? userInfo.avatar
                                            : cdnImages.user_avatar
                                    }
                                    alt="user"
                                />
                                <div className="level">{userInfo.level}</div>
                            </div>
                            <div className="user-additional">
                                <span className="user-name">
                                    {userInfo.username}
                                </span>
                                <br />
                                <>
                                    {userInfo.userId === me.userId &&
                                        zoneStorage !== 'fb' && (
                                            <div className="user-id">
                                                {intl.messages.userInfo.user_id}
                                                :{' '}
                                                <span className={'highlighted'}>
                                                    {userInfo.userId}
                                                </span>
                                            </div>
                                        )}

                                    <div className="userinfo-level-bar">
                                        <div
                                            className={`userinfo-level-full${
                                                animating ? ' animating' : ''
                                            }`}
                                            style={{
                                                width: animatedPercent + '%',
                                            }}
                                        />
                                    </div>
                                </>
                            </div>
                        </div>
                        {/* {me.userId === userInfo.userId && (
                            <div className="connect-social">
                                <span>Connect to see achievements</span>
                                <div
                                    className="social-img"
                                    onClick={() => loginSocial('fb')}
                                >
                                    <img src={cdnImages.fb_image} alt="#" />
                                </div>
                            </div>
                        )} */}
                    </div>
                </div>
                <div className="games-played-content">
                    <div className="games-played">
                        <span className="games-played-text">
                            {intl.messages.userInfo.games_played}
                        </span>
                        <div className="games-played-wrapper">
                            <img src={cdnImages.cards} alt="cards" />
                            <div className="games-played-text-wrapper">
                                <span className="games-played-count">
                                    {userInfo.total}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="win-rate">
                    <div className="win-rate-pie-chart">
                        <PieChart
                            slices={[
                                {
                                    percent: (
                                        Number(userInfo.rate) || 0
                                    ).toFixed(1),
                                    color: '#CB820A',
                                },
                            ]}
                            showPercent
                            showAnimation={animated}
                        />
                    </div>
                    <div className="win-rate-info">
                        <span className="rate-text">
                            {intl.messages.userInfo.winRate}
                        </span>
                        <SecondaryLabel
                            text={intl.formatMessage(
                                {
                                    id: `${intl.messages.userInfo.lastGame}`,
                                },
                                {
                                    count: 100,
                                }
                            )}
                            fontSize={14}
                        />
                    </div>
                </div>
            </div>

            <div className="empty-close" onClick={closeUserInfo} />
        </div>
    );
};

export default UserInfo;
