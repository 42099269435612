import { axiosInstance } from './config';

export const getGameConfig_req = async (userId, zone) => {
    const response = await axiosInstance.get(
        `/getGameConfig?userId=${userId}&zone=${zone}`
    );
    return response.data;
};

export const whellData_req = async () => {
    const response = await axiosInstance.post();
    return response.data;
};
