import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { tournamentPlayedGames_req } from '../../../../api/tournament/tournament.api';
import PopupsEnum from '../../../../enums/popups.enum';
import { openPopups } from '../../../../redux/popup.slice';
import './tournament-game.scss';

const TournamentGame = ({ data }) => {
    const intl = useIntl();
    const [gamesData, setGamesData] = useState([]);
    const tournamentGames = async () => {
        const tourId = data.id;
        const tourGamesData = await tournamentPlayedGames_req(tourId);
        setGamesData(tourGamesData?.data);
    };

    useEffect(() => {
        tournamentGames();
    }, []);

    let arr = [];
    arr.length = data.maxGames;
    arr.fill(1);

    const dispatch = useDispatch();
    const tourTable = (item, index) => {
        if (index === gamesData.length) {
            dispatch(
                openPopups({
                    popupIds: [PopupsEnum.TOURNAMENT_TABLE],
                    popupData: [data],
                })
            );
        }
    };

    return (
        <div className="tournament-game-block">
            {arr.map((item, index) => {
                return (
                    <div
                        className={`${
                            index === gamesData.length ? 'abgar' : ''
                        }`}
                    >
                        <div
                            className={`tournament-game ${
                                data.maxGames < 3 ? 'tournament-game-mini' : ''
                            } ${
                                gamesData[index]
                                    ? 'tiko'
                                    : 'tournament-game-active'
                            } `}
                            onClick={() => tourTable(gamesData[index], index)}
                        >
                            <span>
                                {gamesData[index] ? (
                                    <span>
                                        {[gamesData[index].points]}{' '}
                                        {intl.messages.tournament.pts}
                                    </span>
                                ) : (
                                    index + 1
                                )}
                            </span>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
export default TournamentGame;
