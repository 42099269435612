import React, { useEffect, useState } from 'react';
import './user-login.scss';
import { useHistory } from 'react-router-dom';
import Background from '../background/background';
import { useDispatch, useSelector } from 'react-redux';
import { changeFirstUser, navigate } from '../../redux/global.slice';
import routesEnum from '../../enums/routes.enum';
import SplashScreen from '../splash-screen/splash-screen';
import { soccialLogin } from '../../redux/social-login.slice';
import { cdnImages } from '../../assets/cdn.assets';

const UserLogin = () => {
    const [splashScreen, setSplashScreen] = useState(0);
    const history = useHistory();
    const dispatch = useDispatch();
    const goSecondUserLogin = () => {
        dispatch(navigate(routesEnum.LOGIN_USER));
    };
    const firstUser = useSelector((state) => state.global.firstUser);

    const goGuestPage = () => {
        dispatch(changeFirstUser(1));
    };

    useEffect(() => {
        if (firstUser > 0) {
            setSplashScreen(1);
        }
    }, [firstUser]);
    const loginSocial = (value) => {
        dispatch(soccialLogin(value));
        dispatch(navigate(routesEnum.LOADING));
    };
    return (
        <>
            {splashScreen === 0 ? (
                <SplashScreen goGuestPage={goGuestPage} />
            ) : (
                <div className="user-login">
                    <Background>
                        <div className="user-login-content">
                            <div className="left-user-login">
                                <span className="login-text">Log in via</span>
                                <div className="social-btns">
                                    <div
                                        className="play-game fb"
                                        onClick={() => loginSocial('fb')}
                                    >
                                        <img src={cdnImages.facebook} alt="#" />
                                        <span>sign in with facebook</span>
                                    </div>
                                    {/* <div className="login-btn ok">
                                        <img src={ok} alt="#" />
                                        <span>sign in with odnoklassniki</span>
                                    </div>
                                    <div id="vk_auth"></div>
                                   */}
                                    {/* <div
                                        className="login-btn vk"
                                        onClick={() => loginSocial('vk')}
                                    >
                                        <img src={vk} alt="#" />
                                        <span>sign in with vk</span>
                                    </div> */}
                                </div>
                            </div>
                            <div className="right-user-login">
                                <div className="right-blot-logo">
                                    <img src={cdnImages.belot_kings_logo} />
                                </div>
                                <div class="or">or</div>
                                <div
                                    className="play-game"
                                    onClick={goSecondUserLogin}
                                >
                                    <span>play as guest</span>
                                </div>
                            </div>
                        </div>
                    </Background>
                </div>
            )}
        </>
    );
};

export default UserLogin;
