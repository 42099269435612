import React from 'react';

import './primary.ribbon.scss';

import imageLoader from '../../loaders/image.loader';
import { cdnImages } from '../../assets/cdn.assets';

const getRibbonDataForType = (type) => {
    switch (type) {
        case 1:
            return {
                divClass: 'gray',
                img1: cdnImages.ribbon_g,
                img2: cdnImages.ribbon_g_1,
            };
        case 2:
            return {
                divClass: 'red',
                img1: cdnImages.ribbon_r,
                img2: cdnImages.ribbon_r_1,
            };
        case 3:
            return {
                divClass: 'yellow',
                img1: cdnImages.ribbon_y,
                img2: cdnImages.ribbon_y_1,
            };
        default:
            return {
                divClass: 'gray',
                img1: cdnImages.ribbon_g,
                img2: cdnImages.ribbon_g_1,
            };
    }
};

const PrimaryRibbon = ({ type = 1, text }) => {
    const ribbonData = getRibbonDataForType(type);

    return (
        <div className={`primary_ribbon`}>
            <img
                src={ribbonData.img1}
                alt=""
                className={'ribbon_tape left_tape'}
            />
            <div className={`main_ribbon ${ribbonData.divClass}`}>
                <span>{text}</span>
            </div>
            <img
                src={ribbonData.img2}
                alt=""
                className={'ribbon_tape right_tape'}
            />
        </div>
    );
};
export default PrimaryRibbon;
