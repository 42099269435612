import React, { useEffect, createRef } from 'react';

import './chip.info.scss';

import positionsSingleton from '../../../singletons/positions.singleton';

import positionsHelper from '../../../helpers/positions.helper';
import CountUp from 'react-countup';

import { useSelector } from 'react-redux';
import { cdnImages } from '../../../assets/cdn.assets';

function getChipImage(chip) {
    switch (chip) {
        case 'red':
            return cdnImages.red_panel;
        case 'green':
            return cdnImages.green_panel;
        case 'yellow':
            return cdnImages.gold;
        default:
            return cdnImages.red_panel;
    }
}

const ChipInfo = ({
    chip = 'red',
    start,
    end,
    _onClick = () => {},
    discountTimer,
    imageId,
}) => {
    const chipRef = createRef();
    const navigateRoute = useSelector((state) => state.global.route);
    useEffect(() => {
        if (chipRef.current) {
            const relativePositions = positionsHelper.getRelativePosition(
                chipRef.current
            );

            positionsSingleton.setPosition(
                chip,
                relativePositions.left,
                relativePositions.top
            );
        }
    }, [chip, chipRef]);

    return (
        <div
            className={`chip_info${discountTimer > 0 ? '_discount' : ''} ${
                chip === 'red' || chip === 'yellow' ? 'chip-animate' : ''
            } ${navigateRoute === 7 ? 'chip-info-tour' : ''}`}
        >
            <img
                className={discountTimer > 0 ? 'chip' : 'chip_discount'}
                src={getChipImage(chip)}
                alt={chip}
                ref={chipRef}
                onClick={_onClick}
            />
            {chip === 'red' && navigateRoute !== 3 && (
                <img
                    src={imageId}
                    alt="#"
                    className="plus-icon"
                    onClick={_onClick}
                />
            )}
            {chip === 'yellow' && navigateRoute !== 3 && (
                <img
                    src={imageId}
                    alt="#"
                    className="plus-icon"
                    onClick={_onClick}
                />
            )}
            <span className={discountTimer > 0 ? 'amount_discount' : 'amount'}>
                <CountUp
                    start={start}
                    end={end}
                    duration={2}
                    separator=" "
                    decimals={0}
                    decimal=" "
                    delay={0.1}
                />
            </span>
        </div>
    );
};

export default ChipInfo;
