import React, { useEffect, useState } from 'react';
import './tournament-info.scss';
import TournamentBg from '../../../components/background/tournament-bg/tournament-bg';
import TourInfo from './tour-info/tour-info';
import TournamentPartisipation from './tournament-partisipation/tournament-partisipation';
import TournamentRewards from './tournament-rewards/tournament-rewards';
import { useDispatch, useSelector } from 'react-redux';
import TourHeaderInfo from './tour-header-info/tour-header-info';
import userSingleton from '../../../singletons/user.singleton';
import {
    tournamentJoin_req,
    tournamentLeaderboard_req,
    tournamentReward_req,
} from '../../../api/tournament/tournament.api';
import { setUserMeTournament } from '../../../redux/tournament/user-me-tournament.slice';
import ButtonTreeD from '../../../components/buttons/button_3d/button-3d';
import { navigate } from '../../../redux/global.slice';
import routesEnum from '../../../enums/routes.enum';
import { cdnImages } from '../../../assets/cdn.assets';
import UnlockThreeD from '../../../components/buttons/unlock-3d/unlock-3d';
import { useIntl } from 'react-intl';

const TournamentInfo = () => {
    const intl = useIntl();
    const userTourTab = userSingleton._currentTabUser;
    const [tourInfo, setTourInfo] = useState(-1);
    const [leaderBoard, setLeaderBoard] = useState([]);
    const [tourRewards, setTourRewards] = useState([]);
    const [tourCurrentTab, setTourCurrentTab] = useState(userTourTab);
    const dispatch = useDispatch();

    const tourInfoData = useSelector((state) => state.global.navigateData);
    const userTourData = useSelector((state) => state?.global?.navigateData);
    const tourData = tourInfoData.tourData[0];

    const me = useSelector((state) => state.me.data);
    const joined = useSelector((state) => state.global.joined);
    const tournamentName = tourData?.name;
    const id = tourData?.id;

    const tourMoreInfo = () => {
        dispatch(navigate(routesEnum.TOURNAMENT));
    };
    const tournamentPage = () => {
        dispatch(navigate(routesEnum.TOURNAMENT_DASHBOARD));
    };

    const tournamentJoin = async () => {
        const tourId = tourData?.id;
        const tournamentJoinData = await tournamentJoin_req(tourId);
        if (tournamentJoinData) {
            dispatch(
                navigate({
                    route: routesEnum.TOURNAMENT_DASHBOARD,
                    navigateData: {
                        tourData: Array.isArray(tourData)
                            ? tourData
                            : [tourData],
                        userTourData,
                    },
                })
            );
        }
    };

    const tournamentLeaderBoard = async () => {
        const tourLeaderBoard = await tournamentLeaderboard_req(id);

        const userMePostition = tourLeaderBoard?.data?.leaderboard.filter(
            (item) => item.userId === me.userId
        );

        dispatch(setUserMeTournament(userMePostition));
        setLeaderBoard(tourLeaderBoard);
    };
    const tournamentRewards = async () => {
        const templateId = tourData?.templateId;
        const tourRewardsData = await tournamentReward_req(templateId);
        setTourRewards(tourRewardsData);
    };
    useEffect(() => {
        tournamentLeaderBoard();
        tournamentRewards();
    }, []);

    const tournamentTabContent = (tourCurrentTab) => {
        switch (tourCurrentTab + 1) {
            case 1:
                return <TourInfo tourRewards={tourRewards.data} />;
            case 2:
                return <TournamentRewards tourRewards={tourRewards.data} />;
            case 3:
                return (
                    <TournamentPartisipation tourRewards={tourRewards.data} />
                );
            default:
                return <TourInfo setTourInfo={setTourInfo} />;
        }
    };
    return (
        <div className="tournament-info">
            <div className="tour-cup">
                <span>{tournamentName}</span>
                <div className="tour-info-img">
                    <img src={cdnImages.tour_info} alt="#" />
                </div>
            </div>
            <div className="tour-info-content">
                <TourHeaderInfo
                    tourCurrentTab={tourCurrentTab}
                    setTourCurrentTab={setTourCurrentTab}
                />
                <TournamentBg>
                    {tournamentTabContent(tourCurrentTab)}

                    <div className="down-wrapper">
                        <div className="down-block">
                            {tourData?.minLvl > me?.level && (
                                <UnlockThreeD onClick={tourMoreInfo} />
                            )}
                            {joined && (
                                <div
                                    className="tour-info-join"
                                    onClick={tournamentPage}
                                >
                                    <ButtonTreeD
                                        text={intl.messages.tournament.ok}
                                    />
                                </div>
                            )}
                            {me.level >= tourData?.minLvl && !joined && (
                                <div className="tour-info-btn">
                                    <div
                                        className="tour-info-join"
                                        onClick={tournamentJoin}
                                    >
                                        <ButtonTreeD
                                            text={
                                                joined
                                                    ? intl.messages.tournament
                                                          .ok
                                                    : intl.messages.tournament
                                                          .join
                                            }
                                        />
                                    </div>
                                    <div
                                        className="tour-info-more"
                                        onClick={tourMoreInfo}
                                    >
                                        <ButtonTreeD
                                            text={intl.messages.tournament.ok}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </TournamentBg>
            </div>
        </div>
    );
};
export default TournamentInfo;
