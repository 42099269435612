import React from 'react';
import { cdnImages } from '../../../assets/cdn.assets';

import './user-order.scss';
function suitType(contractSuit) {
    switch (contractSuit) {
        case 0:
            return cdnImages.clubs_icon;
        case 1:
            return cdnImages.diamonds_icon;
        case 2:
            return cdnImages.hearts_icon;
        case 3:
            return cdnImages.spade_icon;
        case 4:
            return cdnImages.boy;
        case 5:
            return cdnImages.deep_icon;
        default:
            return '';
    }
}
const UserOrder = ({ contractSuit, seat }) => {
    return (
        <>
            <div className={`user-order user-order-${seat}`}>
                <div className="user-order-value">
                    {contractSuit !== undefined && (
                        <img src={suitType(contractSuit)} alt="#" />
                    )}
                </div>
            </div>
        </>
    );
};

export default UserOrder;
