import React, { useEffect, useState } from 'react';
import './user-image-info.scss';
import UserImageInfoName from '../user-image-info-name/user-image-info-name';
import UserScore from '../user-score/user-score';
import UserGift from '../user-gift/user-gift';
import FirstStart from '../first-start/first-start';
import Tooltip from '../../tooltip/tooltip';
import UserOrder from '../user-order/user-order';
import ButtonTreeD from '../../buttons/button_3d/button-3d';
import socketBridge from '../../../socket';
import { RoomChannelEmittingEvents } from '../../../socket/socket.events';
import roomSingleton from '../../../singletons/room.singleton';
import { useDispatch, useSelector } from 'react-redux';
import Anouncement from '../../../pages/game-play/anouncement/anouncement';
import UserContra from '../user-contra/user-contra';
import {
    setCurrentUserInfo,
    setPartnerIdData,
} from '../../../redux/userinfo.slice';
import { setUserUpdate } from '../../../redux/user.update.slice';
import UserInfo from '../../../global-components/userInfo/user-info';
import UserTiming from '../../../pages/game-play/user-timing/user-timing';
import UserRecontra from '../user-recontra/user-recontra';
import UserChat from '../user-chat/user-chat';
import UserSmile from '../user-smile/user-smile';
import { useIntl } from 'react-intl';
import { userInfo_req } from '../../../api/user.api';
import { cdnImages } from '../../../assets/cdn.assets';

const UserImageInfo = ({
    userInfo,
    imageId,
    seat,
    userSaySuit,
    bazarFinish,
    announcement,
    playIndex,
    stateIndex,
    showAnnouncement,
    showUserAnnouncement,
    startFrom,
    isContra,
    firstPlay,
    isRecontra,
    allowContra,
    isBot,
    partnerId,
    contractSuit,
    contraTooltipUserId,
    score,
    timeout,
    timeoutBazar,
    contraUserId,
    timeoutUserIds,
    team,
    recontraUserId,
    currentBazarUsers,
    declareAnnouncementWords,
    declareAnnouncementUserId,
    firstPlayContraUserId,
    bazarFinishUserId,
    takeChat,
    isBlot,
    anounnceUserId,
}) => {
    const intl = useIntl();
    const [closeAnnounce, setCloseAnnounce] = useState(true);
    const [closeRecontra, setCloseRecontra] = useState(false);
    const [closeContra, setCloseContra] = useState(false);
    const [contraTooltip, setContraTooltip] = useState(isContra);
    const [opened, setOpened] = useState(false);
    const dispatch = useDispatch();
    const me = useSelector((state) => state.me);
    const showMeAnnounce = anounnceUserId === userInfo.userId && seat !== 1;
    const isFirst = startFrom && startFrom[0] === userInfo.userId;
    const isRecontraUserId = recontraUserId === userInfo.userId;
    const isContraUserId = contraUserId === userInfo.userId;
    const tooltipContra = contraTooltipUserId === userInfo.userId;
    const isFirstContraUserId = firstPlayContraUserId === userInfo.userId;
    const roomIdGet = roomSingleton.roomId;
    const currentRecontra = currentBazarUsers.includes(me.data.userId);
    const userInfoData = useSelector((state) => state.userInfo);
    const userUpdateReq = async () => {
        const userId = me && me.data && me.data.userId;
        const userUpdate = await userInfo_req(userId);
        dispatch(setUserUpdate(userUpdate));
    };
    useEffect(() => {
        setTimeout(() => {
            userUpdateReq();
        }, 2000);
    }, []);

    if (partnerId) {
        dispatch(setPartnerIdData(partnerId));
    }

    const anounce = () => {
        socketBridge.emitToRoomChannel({
            event: RoomChannelEmittingEvents.ANNOUNCEMENT,
            cancel: false,
            state: playIndex || 0,
            roomId: roomIdGet,
            userId: me.data.userId,
        });
        setCloseAnnounce(false);
    };

    const cancel = () => {
        socketBridge.emitToRoomChannel({
            event: RoomChannelEmittingEvents.ANNOUNCEMENT,
            cancel: true,
            state: playIndex || 0,
            roomId: roomIdGet,
            userId: me.data.userId,
        });
        setCloseAnnounce(false);
    };
    const recontra = (sayIsRecontra) => {
        socketBridge.emitToRoomChannel({
            event: RoomChannelEmittingEvents.TALK,
            bazarInfo: {
                isRecontra: sayIsRecontra || undefined,
            },
            roomId: roomIdGet,
            userId: me.data.userId,
            state: stateIndex || 0,
        });
        setContraTooltip(false);
    };
    const closeRecontraBtn = () => {
        setCloseRecontra(true);
    };
    const contraBtn = (sayIsContra) => {
        socketBridge.emitToRoomChannel({
            event: RoomChannelEmittingEvents.TALK,
            bazarInfo: {
                isContra: sayIsContra || undefined,
            },
            roomId: roomIdGet,
            userId: me.data.userId,
            state: stateIndex || 0,
        });

        setCloseContra(true);
        setTimeout(() => {
            setContraTooltip(false);
        }, 3000);
    };
    const userInfoFn = () => {
        dispatch(setCurrentUserInfo(userInfo));
    };

    return (
        <>
            <div className={`user_image_info_${seat}`}>
                <div
                    className={`${
                        timeoutUserIds.includes(userInfo.userId) &&
                        (timeout || timeoutBazar)
                            ? 'user-game-time-block'
                            : 'user_game_block'
                    }`}
                >
                    <div
                        className={`${
                            timeoutUserIds.includes(userInfo.userId) &&
                            (timeout || timeoutBazar) &&
                            'user-timing'
                        }`}
                    ></div>
                    {/* {userInfo.vip ? (
                        <img
                            src={vip_game_play}
                            alt="#"
                            className="vip-user-game"
                        />
                    ) : (
                        ''
                    )} */}

                    {timeoutUserIds.includes(userInfo.userId) && timeout && (
                        <>
                            <UserTiming
                                duration={timeout / 1000}
                                percent={100}
                                vip={userInfo.vip}
                            />
                        </>
                    )}
                    {timeoutUserIds.includes(userInfo.userId) && timeoutBazar && (
                        <>
                            <UserTiming
                                duration={timeoutBazar / 1000}
                                percent={100}
                                vip={userInfo.vip}
                            />
                        </>
                    )}
                    <div
                        className={`${
                            timeoutUserIds.includes(userInfo.userId) &&
                            (timeout || timeoutBazar)
                                ? 'user-img-info-img-block'
                                : 'user_image_info_img'
                        }`}
                    >
                        {isBot ? (
                            <img
                                src={cdnImages.user_avatar}
                                className="bot-avatar"
                                alt="#"
                            />
                        ) : (
                            <img
                                src={userInfo.avatar}
                                alt="user"
                                className="user-avatar"
                                onClick={userInfoFn}
                                onError={(ev) => {
                                    ev.target.onerror = null;
                                    ev.target.src = cdnImages.user_avatar;
                                }}
                            />
                        )}
                    </div>
                    {userInfo.vip ? (
                        <div className="vip-user">
                            <img src={cdnImages.vip_game_play} alt="#" />
                        </div>
                    ) : (
                        ''
                    )}
                </div>
                {imageId && <img src={imageId} alt="#" />}
                <UserImageInfoName
                    name={userInfo.name ? userInfo.name : userInfo.username}
                />
                {tooltipContra && userSaySuit && userSaySuit.isContra && (
                    <div className="contra-tooltip">
                        <Tooltip seat={seat} text="contra" />
                    </div>
                )}
                {isBlot && declareAnnouncementUserId === userInfo.userId && (
                    <Tooltip seat={seat} text={intl.messages.roundScore.blot} />
                )}

                {!bazarFinish &&
                !firstPlay &&
                userSaySuit &&
                userSaySuit.suit > -1 ? (
                    <Tooltip seat={seat} userSaySuit={userSaySuit.suit} />
                ) : null}
                {!bazarFinish &&
                    !firstPlay &&
                    userSaySuit &&
                    userSaySuit.pass && (
                        <Tooltip
                            seat={seat}
                            sayPassAnnounce={userSaySuit.pass}
                        />
                    )}
                {(declareAnnouncementWords &&
                    declareAnnouncementWords.length > 0 &&
                    declareAnnouncementUserId === userInfo.userId) ||
                (showAnnouncement &&
                    showAnnouncement.declareAnnouncementWords &&
                    showAnnouncement.declareAnnouncementWords.length > 0 &&
                    userInfo.userId === showAnnouncement.userId) ? (
                    <Tooltip
                        showAnnouncement={
                            declareAnnouncementWords &&
                            declareAnnouncementWords.length > 0
                                ? declareAnnouncementWords
                                : showAnnouncement.declareAnnouncementWords
                        }
                        seat={seat}
                    />
                ) : (
                    <></>
                )}
                {takeChat &&
                    takeChat.chat &&
                    takeChat.userId === userInfo.userId && (
                        <UserChat seat={seat} emojiId={takeChat.emojiId} />
                    )}
                {takeChat &&
                    !takeChat.chat &&
                    takeChat.userId === userInfo.userId && (
                        <UserSmile seat={seat} emojiId={takeChat.emojiId} />
                    )}
                {isFirst && !bazarFinish && <FirstStart seat={seat} />}
                {/* <FirstStart seat={seat} /> */}
                {(isContraUserId && bazarFinish) || isFirstContraUserId ? (
                    <UserContra seat={seat} />
                ) : null}
                {bazarFinish && isRecontraUserId && (
                    <UserRecontra seat={seat} />
                )}
                {(bazarFinish || firstPlay) && seat !== 1 && (
                    <UserGift seat={seat} userInfo={userInfo} />
                )}
                {/* {seat === 1 && <AnimateGift />} */}

                {score && score.length > 0 ? (
                    <UserScore seat={seat} score={score} team={team} />
                ) : null}

                {(announcement || showUserAnnouncement) && showMeAnnounce && (
                    <Anouncement
                        showUserAnnouncement={showUserAnnouncement}
                        seat={seat}
                    />
                )}
                {allowContra &&
                    !isContra &&
                    firstPlay === null &&
                    !closeContra && (
                        <div
                            className="user-contra-btn"
                            onClick={() => contraBtn(true)}
                        >
                            <ButtonTreeD text="contra" />
                        </div>
                    )}
                {currentRecontra &&
                    seat === 1 &&
                    currentBazarUsers &&
                    currentBazarUsers.length > 1 &&
                    isContra &&
                    firstPlay === null &&
                    !isRecontra &&
                    !closeRecontra && (
                        <div className="recontra-button-block">
                            <div
                                className="recontra-button"
                                onClick={() => recontra(true)}
                            >
                                <ButtonTreeD text="recontra" />
                            </div>
                            <div
                                className="pass-button"
                                onClick={closeRecontraBtn}
                            >
                                <ButtonTreeD text="pass" />
                            </div>
                        </div>
                    )}
                {seat === 1 &&
                    announcement &&
                    announcement.length > 0 &&
                    closeAnnounce && (
                        <div className="announce-user-button">
                            <div className="anounse-button-block">
                                <div
                                    className="anounse-button"
                                    onClick={anounce}
                                >
                                    <ButtonTreeD
                                        text={intl.messages.announce}
                                    />
                                </div>
                                <div className="cancel-button" onClick={cancel}>
                                    <ButtonTreeD text={intl.messages.cancel} />
                                </div>
                            </div>
                        </div>
                    )}

                {bazarFinishUserId === userInfo.userId && (
                    <UserOrder contractSuit={contractSuit} seat={seat} />
                )}
            </div>
            <div className="userinfo-open">
                {opened && (
                    <UserInfo
                        opened={opened}
                        setOpened={setOpened}
                        userInfo={userInfoData.data}
                    />
                )}
            </div>
        </>
    );
};

export default UserImageInfo;
