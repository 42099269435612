import React, { useState, useEffect } from 'react';
import './home.page.scss';
import LobbyItem from '../../components/lobby-item/lobby-item';
import Background from '../../components/background/background';
import { useDispatch, useSelector } from 'react-redux';
import { levelUp_req, checkNotification_req } from '../../api/user.api';
import userSingleton from '../../singletons/user.singleton';
import PopupsEnum from '../../enums/popups.enum';
import { openPopups } from '../../redux/popup.slice';
import socketBridge from '../../socket';
import { UserChannelHandlingEvents } from '../../socket/socket.events';
import { tournamentPopup_req } from '../../api/tournament/tournament.api';

const HomePage = () => {
    const dispatch = useDispatch();
    const [shouldAnimate, setShouldAnimate] = useState(0);
    const [levelUp, setLevelUp] = useState([]);

    const me = useSelector((state) => state.me.data);
    const userId = me.userId;

    const checkNotification = async () => {
        const notificationAdmin = await checkNotification_req(userId);

        if (
            notificationAdmin &&
            notificationAdmin.notifications &&
            notificationAdmin.notifications.length > 0
        ) {
            dispatch(
                openPopups({
                    popupIds: [PopupsEnum.ADMIN_CHIP_POPUP],
                    popupData: [notificationAdmin],
                })
            );
        }
    };
    const checkLevelUp = async () => {
        const userLevelUp = await levelUp_req(userId);
        setLevelUp(userLevelUp);
        if (userLevelUp.status) {
            dispatch(
                openPopups({
                    popupIds: [PopupsEnum.LEVEL_UP],
                    popupData: [userLevelUp],
                })
            );
        }
    };
    const tournamentPopups = async () => {
        const tourPopups = await tournamentPopup_req();

        const tourPopup = tourPopups && tourPopups.data && tourPopups.data[0];

        if (tourPopup && tourPopup.type === 'tournament') {
            dispatch(
                openPopups({
                    popupIds: [PopupsEnum.TOURNAMENT_RESULT],
                    popupData: [tourPopups],
                })
            );
        }
    };
    useEffect(() => {
        function watcher({ event, ...args }) {
            switch (event) {
                case UserChannelHandlingEvents.CHARGE_USER:
                    if (args.giftType === 'adminGift') {
                        checkNotification();
                    }
                    if (args.type === 'buy') {
                        dispatch(
                            openPopups({
                                popupIds: [PopupsEnum.BUY_CHIP_POPUP],
                                popupData: [args.data],
                            })
                        );
                    }
                    break;
                default:
                    break;
            }
        }

        socketBridge.watchUserChannel(watcher);

        return () => {
            socketBridge.unwatchUserChannel(watcher);
        };
    }, [dispatch]);
    useEffect(() => {
        checkLevelUp();
        checkNotification();
        tournamentPopups();
    }, []);

    return (
        <React.Fragment>
            <Background />
            <LobbyItem shouldAnimate={shouldAnimate} />

            {/* <GameInfo /> */}
            {/* <GreenPopup /> */}
        </React.Fragment>
    );
};
export default HomePage;
