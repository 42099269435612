import { createSlice } from '@reduxjs/toolkit';

export const userInfoSlice = createSlice({
    name: 'userInfo',
    initialState: {
        data: null,
        partnerId: '',
    },
    reducers: {
        setCurrentUserInfo: (state, { payload }) => {
            state.data = payload;
        },
        setPartnerIdData: (state, action) => {
            state.partnerId = action.payload;
        },
    },
});
export const { setCurrentUserInfo, setPartnerIdData } = userInfoSlice.actions;

export default userInfoSlice.reducer;
