import { createSlice } from '@reduxjs/toolkit';
import { getAllGifts_req } from '../api/all-gifts';

export const giftsSlice = createSlice({
    name: 'gifts',
    initialState: {
        allGifts: [],
    },
    reducers: {
        setAllGifts: (state, action) => {
            state.allGifts = action.payload;
        },
    },
});
export const { setAllGifts } = giftsSlice.actions;

export default giftsSlice.reducer;
