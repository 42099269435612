import React from 'react';
import { cdnImages } from '../../../assets/cdn.assets';

import './last-cards.scss';

const LastCards = ({ id = '1', cardData }) => {
    function getTooltipSuit(suit) {
        const images = [
            cdnImages.hearts_icon,
            cdnImages.diamonds_icon,
            cdnImages.spade_icon,
            cdnImages.clubs_icon,
            cdnImages.boy,
        ];
        return images[suit];
    }
    return (
        <div className={`last_cards last_cards_${id}`}>
            <span>{cardData.value}</span>
            <img src={getTooltipSuit(cardData.suit)} className="suit-img" />
        </div>
    );
};
export default LastCards;
