import React, { useEffect, useState } from 'react';
import './store-page.scss';

import RedTabPopup from './red-tab/red-tab';
import YellowTab from './yellow-tab/yellow-tab';

import ChipNavbarTab from './chip-navbar-tab/chip-navbar-tab';
import Background from '../../components/background/background';
import BackButton from '../../components/buttons/back-button/back-button';
import { useDispatch, useSelector } from 'react-redux';
import routesEnum from '../../enums/routes.enum';
import { navigate } from '../../redux/global.slice';

import { UserChannelHandlingEvents } from '../../socket/socket.events';
import { useIntl } from 'react-intl';
import { checkNotification_req, getUrlParameter } from '../../api/user.api';
import { openPopups } from '../../redux/popup.slice';
import PopupsEnum from '../../enums/popups.enum';
import socketBridge from '../../socket';

import { loadScript } from '../../helpers/scripts.helper';
import userSingleton from '../../singletons/user.singleton';
import { axiosInstance } from '../../api/config';

const StorePage = () => {
    const tabData = useSelector((state) => state.global.currentTab);

    const [currentTab, setCurrentTab] = useState(tabData > -1 ? tabData : 0);
    const [adminChipData, setAdminChipData] = useState([]);
    const intl = useIntl();
    const dispatch = useDispatch();
    const configData = useSelector((state) => state.gameConfig.gameConfigData);
    const me = useSelector((state) => state.me.data);
    useEffect(() => {
        function watcher({ event, ...args }) {
            switch (event) {
                case UserChannelHandlingEvents.ADMIN_CHIP:
                    if (args.type === 'buy') {
                        dispatch(
                            openPopups({
                                popupIds: [PopupsEnum.BUY_CHIP_POPUP],
                                popupData: [args],
                            })
                        );
                    }
                    break;
                default:
                    break;
            }
        }

        socketBridge.watchUserChannel(watcher);

        return () => {
            socketBridge.unwatchUserChannel(watcher);
        };
    }, [dispatch]);
    useEffect(() => {
        VkHandler();
        FbHandler();
        OkHandler();
    }, []);

    const checkNotification = async () => {
        const notificationAdmin = await checkNotification_req(me.userId);
        setAdminChipData(notificationAdmin);
        if (
            notificationAdmin &&
            notificationAdmin.notifications &&
            notificationAdmin.notifications.length > 0
        ) {
            dispatch(
                openPopups({
                    popupIds: [PopupsEnum.ADMIN_CHIP_POPUP],
                    popupData: [notificationAdmin],
                })
            );
        }
    };
    const scriptLoadedFb = function (resolve) {
        (function (d, s, id) {
            var js,
                fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = '//connect.facebook.net/en_US/sdk.js';
            fjs.parentNode.insertBefore(js, fjs);
        })(document, 'script', 'facebook-jssdk');
    };

    const scriptLoaded = function (resolve) {
        window.VK.init(function () {
            let query = document.referrer;
            let inviter_id = query.split('?').join('').split('_')[1];
            window.inviter_id = inviter_id
                ? inviter_id.replace('request', '')
                : null;
            return resolve();
        });
    };

    const VkHandler = function () {
        return new Promise(function (resolve) {
            loadScript(
                '//vk.com/js/api/xd_connection.js?2',
                scriptLoaded.bind(VkHandler, resolve)
            );
        });
    };
    const FbHandler = function () {
        return new Promise(function (resolve, reject) {
            loadScript(
                '//connect.facebook.net/en_US/sdk.js',
                scriptLoadedFb.bind(FbHandler, resolve, reject)
            );
        });
    };

    const OkHandler = function () {
        return new Promise(function (resolve) {
            loadScript(
                '//api.ok.ru/js/fapi5.js',
                scriptLoadedOk.bind(VkHandler, resolve)
            );
        });
    };

    const scriptLoadedOk = function (resolve) {
        let okParams = window.FAPI.Util.getRequestParameters();

        window.FAPI.init(
            okParams['api_server'],
            okParams['apiconnection'],
            function () {
                return resolve();
            },
            function (err) {}
        );
    };

    const buyPurchase = (id, isVip, chipAmount, chipType, amount) => {
        const zone = getUrlParameter('zone');
        // const zone = 'ok';

        const params = {
            type: 'item',
            item: id,
        };

        if (zone === 'vk') {
            const dataPurchase = () => {
                window.VK.callMethod('showOrderBox', params);
                window.VK.addCallback('onOrderSuccess', checkNotification);
            };
            dataPurchase();
        }
        if (zone === 'fb') {
            const dataFBPurchase = () => {
                const prodId = isVip
                    ? 'vip' + id
                    : (chipType === 'yellow' ? 'gold' : '') + chipAmount;
                const reqProduct =
                    'https://bg.blotclub.am/img/products/' +
                    prodId +
                    '-new.html';
                axiosInstance
                    .post('/payment/fb', {
                        act: 'co-buy-pack',
                        access_token:
                            userSingleton?.fbUserData?.authResponse
                                ?.accessToken,
                        item: id,
                    })
                    .then(function (response) {
                        // self.purchaseData = {
                        //     chipType: response.data.type,
                        //     chipAmount: response.data.chips,
                        // };

                        window.FB.ui(
                            {
                                method: 'pay',
                                action: 'purchaseitem',
                                product: reqProduct,
                                request_id: response.data.orderId,
                            },
                            verifyBuyPaymentNew
                        );
                    });

                function verifyBuyPaymentNew(_data) {
                    if (!_data || !_data.signed_request) {
                        // alert("There was an error processing your payment. Please try again!");
                        return;
                    }
                    axiosInstance
                        .post('/payment/fb', {
                            act: 'co-buy-pack-verify',
                            userId: me.userId,
                            status: _data.status,
                            signed_request: _data.signed_request,
                            qtty: _data.quantity,
                            order_id: _data.payment_id,
                            request_id: _data.request_id,
                            access_token: userSingleton.jwt,
                            item: id,
                        })
                        .then(function (response) {
                            // BCWindows.showInAppWindows(data);
                        });
                }
            };
            dataFBPurchase();
        }
        if (zone === 'ok') {
            // window.FAPI.UI.showPayment("Яблоко", "Это очень вкусно!", 777, 1, null, null, "ok", "true");

            window.FAPI.UI.showPayment(
                chipAmount + ' Пакет Фишек',
                chipAmount + ' ' + chipType + ' Фишки',
                +id,
                amount,
                null,
                null,
                'ok',
                'true'

                // 'Яблоко',
                // 'Это очень вкусно!',
                // 777,
                // 1,
                // null,
                // null,
                // 'ok',
                // 'true'
            );
            // function okScriptLoaded(resolve) {

            //
            //
            //     resolve();
            //
            //     //
            //     // if (
            //     //     okParams['refplace'] &&
            //     //     okParams['refplace'].indexOf('friend_invitation') > -1
            //     // ) {
            //     //     let inviter_id = okParams['referer'];
            //     //     window.inviter_id = inviter_id;
            //     // }
            //     //
            //     // if (
            //     //     okParams['refplace'] &&
            //     //     okParams['refplace'].indexOf('notification') > -1
            //     // ) {
            //     // }
            // }

            // const okHandlerScriptLoaded = function (resolve) {
            //     // let query = document.referrer;
            //     // let inviter_id = query.split('?').join('').split('_')[1];
            //     // window.inviter_id = inviter_id
            //     //     ? inviter_id.replace('request', '')
            //     //     : null;
            //     return resolve();
            // };

            // const accesToken = userSingleton.accessToken;
            // const getAccessToken = function () {
            //     return Promise.resolve(accesToken);
            // };
            // window.API_callback = function (method, result, data) {

            //     //BCWindows.showInAppWindows(self.purchuaceData);
            //
            //     if (result !== 'ok') {
            //         alert(method + ' error');
            //         return;
            //     }
            //     const purchaseData = {
            //         chipAmount,
            //         chipType,
            //         id,
            //         amount,
            //     };
            //     if (method === 'showPayment') {

            //         // BCWindows.showInAppWindows(purchaseData);
            //     } else if (method === 'postMediatopic') {
            //         getAccessToken().then(function (response) {
            //             if (response.data.status) {
            //
            //             }
            //         });
            //     }

            // };
            // window.FAPI.UI.showPayment(
            //     chipAmount + ' Chip Pack',
            //     chipAmount + ' ' + chipType + ' Chips',
            //     '' + id,
            //     +amount,
            //     null
            // );
        }
    };

    const tabContent = (currentTab) => {
        switch (currentTab + 1) {
            case 1:
                return (
                    <RedTabPopup data={configData} buyPurchase={buyPurchase} />
                );
            case 2:
                return (
                    <YellowTab data={configData} buyPurchase={buyPurchase} />
                );
            // case 3:
            //     return <WheelTab data={configData.discount} />;
            // return <VipTabPopup data={configData.vipPacks} />;
            // case 4:
            //     return <WheelTab data={configData.discount} />;
        }
    };
    const goLooby = () => {
        dispatch(dispatch(navigate(routesEnum.LOBBY)));
    };

    return (
        <>
            <Background>
                <div className="store_popup">
                    <div className="back-lobby-store" onClick={() => goLooby()}>
                        <BackButton text={intl.messages.lobby} />
                    </div>
                    <ChipNavbarTab
                        setCurrentTab={setCurrentTab}
                        currentTab={currentTab}
                    />
                    <div className="content_store">
                        {tabContent(currentTab)}
                    </div>
                </div>
            </Background>
        </>
    );
};

export default StorePage;
