import React, { useState } from 'react';

import './user.panel.scss';

import LevelBar from '../../bars/level-bar/level.bar';
import ChipInfo from '../../info/chip-info/chip.info';
import { useIntl } from 'react-intl';
import UserPanelAvatar from '../../user/user-panel-avatar/user-panel-avatar';
import { useDispatch, useSelector } from 'react-redux';
import { openPopups } from '../../../redux/popup.slice';
import PopupsEnum from '../../../enums/popups.enum';
import Discount from '../discount/discount';
import {
    openCloseChat,
    openCloseGift,
    openCloseSmile,
    openGamePlayMenu,
} from '../../../redux/menu-game.slice';
import routesEnum from '../../../enums/routes.enum';
import { navigate } from '../../../redux/global.slice';
import { cdnImages } from '../../../assets/cdn.assets';
import { tournamentColor } from '../../../redux/theme.change.slice';
import themeEnum from '../../../enums/theme.enum';

const UserPanel = () => {
    const [discount, setDiscount] = useState(true);

    const dispatch = useDispatch();
    const intl = useIntl();
    const userInfo = useSelector((state) => state.me.data);
    const userUpdate = useSelector((state) => state.userUpdate.data);
    const openedState = useSelector(
        (state) => state.userMenuGame.rightMenu.rightMenuOpenedState
    );
    const navigateRoute = useSelector((state) => state.global.route);
    const chipInfo = useSelector((state) => state.chipInfo);
    if (!chipInfo || !userUpdate || !userInfo) {
        return null;
    }
    const openRightMenu = () => {
        dispatch(openGamePlayMenu(true));
        dispatch(
            openGamePlayMenu({
                rightMenuOpenedState: openedState === 3 ? 1 : 2,
            })
        );
        dispatch(openCloseGift({ openedState: 3 }));
        dispatch(openCloseSmile({ smileOpenedState: 3 }));
        dispatch(openCloseChat({ chatOpenedState: 3 }));
    };

    const goStorePage = (chip) => {
        dispatch(tournamentColor(themeEnum.LOBBY));
        if (navigateRoute !== 3) {
            if (chip.type === 'yellow') {
                dispatch(navigate({ route: routesEnum.STORE, currentTab: 1 }));
            } else {
                dispatch(navigate({ route: routesEnum.STORE, currentTab: 0 }));
                // dispatch(
                //     openPopups({
                //         popupIds: [PopupsEnum.TOURNAMENT_RESULT],
                //     })
                // );
            }
        }
    };

    return (
        <div
            className={`
                ${
                    navigateRoute === 7 ? 'user-panel-tournament' : 'user-panel'
                }`}
        >
            <div className="left_side">
                <UserPanelAvatar
                    userLevel={
                        userUpdate
                            ? userUpdate.level
                            : userInfo && userInfo.level
                    }
                    userInfo={userInfo}
                />

                <div className="user_data">
                    <div className="user_name_points">
                        <span
                            className={`${
                                userInfo && userInfo.vip
                                    ? 'nickname-vip'
                                    : 'nickname'
                            }`}
                        >
                            {userInfo && userInfo.username}
                        </span>
                    </div>
                    <LevelBar
                        vip={userInfo && userInfo.vip}
                        percent={
                            userUpdate &&
                            (userUpdate.points * 100) / userUpdate.edge
                        }
                    />
                    <p className="user_edge">
                        {userUpdate
                            ? userUpdate.points
                            : userInfo && userInfo.points}
                        /
                        {userUpdate
                            ? userUpdate.edge
                            : userInfo && userInfo.edge}
                    </p>
                </div>

                <div
                    className={`chips_wrapper ${
                        discount ? 'discount_chip' : ''
                    }`}
                >
                    {userInfo &&
                        userInfo.discountType > 0 &&
                        userInfo &&
                        userInfo.discountTimer > 0 && (
                            <Discount
                                discountType={userInfo.discountType}
                                discountDate={userInfo.discountDate}
                            />
                        )}
                    <ChipInfo
                        chip={'red'}
                        start={
                            userUpdate && userUpdate.red > -1 && userUpdate.red
                        }
                        // end={
                        //     userChip
                        //         ? userUpdate.red - roomTypeData(userChip)
                        //         : chipInfo && chipInfo.red === null
                        //         ? userUpdate
                        //             ? userUpdate.red
                        //             : userInfo.red
                        //         : chipInfo.red +
                        //           (userUpdate ? userUpdate.red : userInfo.red)
                        // }
                        end={
                            userUpdate && userUpdate.red > -1 && userUpdate.red
                        }
                        imageId={cdnImages.plus_icon}
                        _onClick={() => goStorePage({ type: 'red' })}
                        discountTimer={userInfo.discountTimer}
                    />
                    <ChipInfo
                        chip={'green'}
                        start={
                            userUpdate &&
                            userUpdate.green > -1 &&
                            userUpdate.green
                        }
                        end={
                            userUpdate &&
                            userUpdate.green > -1 &&
                            userUpdate.green
                        }
                    />
                    <ChipInfo
                        chip={'yellow'}
                        start={
                            userUpdate &&
                            userUpdate.yellow > -1 &&
                            userUpdate.yellow
                        }
                        end={
                            userUpdate &&
                            userUpdate.yellow > -1 &&
                            userUpdate.yellow
                        }
                        _onClick={() => goStorePage({ type: 'yellow' })}
                        imageId={cdnImages.plus_icon}
                    />
                </div>
            </div>
            {navigateRoute !== 3 ? (
                <>
                    {/* <div
                        className="user_panel_whell"
                        onClick={() =>
                            dispatch(dispatch(navigate(routesEnum.STORE)))
                        }
                    >
                        <img src={cdnImages.whell_animation_store} alt="#" />
                    </div> */}
                    <div
                        className="user_panel_store"
                        onClick={() =>
                            dispatch(dispatch(navigate(routesEnum.STORE)))
                        }
                    >
                        <img src={cdnImages.store} alt="#" />
                    </div>
                </>
            ) : null}

            {navigateRoute === 3 ? (
                <div className="burger-menu" onClick={openRightMenu}>
                    <img src={cdnImages.game_play_menu} alt="#" />
                </div>
            ) : (
                // <div className="drop-down-menu">
                //     <MenuGamePlay
                //         dropDownMenu={dropDownMenu}
                //         menuOpened={menuOpened}
                //     />
                // </div>

                <div
                    className="burger-menu burger-menu-hover"
                    onClick={() =>
                        dispatch(
                            openPopups({
                                popupIds: [PopupsEnum.SETTINGS_POPUP],
                            })
                        )
                    }
                >
                    <span className="burger-menu-item-first"></span>
                    <span className="burger-menu-item-second"></span>
                    <span className="burger-menu-item-third"></span>
                </div>
            )}
        </div>
    );
};

export default UserPanel;
