import React, { useState } from 'react';
import './lobby-item.scss';
import LobbyName from './lobby-name/lobby-name';
import LobbyCashGame from './lobby-cash-game/lobby-cash-game';

import { useDispatch } from 'react-redux';
import { navigate } from '../../redux/global.slice';
import routesEnum from '../../enums/routes.enum';
import { tournamentColor } from '../../redux/theme.change.slice';
import themeEnum from '../../enums/theme.enum';
import { useIntl } from 'react-intl';
import BackButton from '../buttons/back-button/back-button';
import { cdnImages } from '../../assets/cdn.assets';

const LobbyItem = ({ shouldAnimate }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [renderFirstPage, setRenderFirstPage] = useState(true);
    const [renderSecondPage, setRenderSecondPage] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);

    const handleChangeToFistPage = () => {
        if (!renderFirstPage) {
            setRenderFirstPage(() => true);
            setPageNumber(() => 0);
            setTimeout(() => {
                setRenderSecondPage(() => false);
            }, 500);
        }
    };

    const handleChangeToSecondPage = () => {
        if (!renderSecondPage) {
            setRenderSecondPage(() => true);
            setPageNumber(() => 1);
            setTimeout(() => {
                setRenderFirstPage(() => false);
            }, 500);
        }
    };
    const handleTournamentPage = () => {
        dispatch(navigate(routesEnum.TOURNAMENT));
        dispatch(tournamentColor(themeEnum.TOURNAMENT));
    };
    return (
        <div className="lobby_content">
            <div
                className={`lobby_item${
                    pageNumber ? ' move_to_top' : '' || ' move_to_bottom'
                }`}
            >
                {renderFirstPage && (
                    <div className="lobby_menu">
                        <div
                            className={`lobby_games${
                                shouldAnimate ? ' to_animate' : ''
                            }`}
                        >
                            <div
                                className="lobby_game"
                                onClick={() => handleChangeToSecondPage()}
                            >
                                <img
                                    className="cash_game"
                                    src={cdnImages.cash_game}
                                    alt="tournament"
                                />
                                <LobbyName
                                    text="GAMES"
                                    // text={intl.messages.cashGames}
                                    type={3}
                                />
                            </div>

                            <div
                                className="lobby_game"
                                onClick={() => handleTournamentPage()}
                            >
                                <img
                                    className="cash_game"
                                    src={cdnImages.tournament_lobby}
                                    alt="cashGame"
                                />
                                <LobbyName
                                    text="TOURNAMENT"
                                    // text={intl.messages.lobbyTournament}
                                    type={3}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {renderSecondPage && (
                    <div className="cash_game_menu">
                        <div
                            className={`cash_games${
                                shouldAnimate ? ' to_animate' : ''
                            }`}
                        >
                            <div onClick={() => handleChangeToFistPage()}>
                                <BackButton text={intl.messages.lobby} />
                            </div>
                            <div className="cash_game_menu_block">
                                <LobbyCashGame />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
export default LobbyItem;
