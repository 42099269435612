import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import ButtonTreeD from '../../../../components/buttons/button_3d/button-3d';
import GameChipInfo from '../../../../components/info/game-chip-info/game-chip-info';
import PrimaryRibbon from '../../../../components/ribbons/primary.ribbon';

import routesEnum from '../../../../enums/routes.enum';
import { navigate } from '../../../../redux/global.slice';
import BestScore from './best-score/best-score';
import socketBridge from '../../../../socket';
import './game-end.scss';
import roomSingleton from '../../../../singletons/room.singleton';
import { welcomeUserRefresh } from '../../../../redux/welcome.user.cb.slice';
import { userInfo_req } from '../../../../api/user.api';
import { setUserUpdate } from '../../../../redux/user.update.slice';
import { cdnImages } from '../../../../assets/cdn.assets';

function getContractStatusForTeam(intl, data, team) {
    const normalized = {
        text: '',
        type: 1,
    };
    if (team === 0) {
        if (data.winnerTeam === 0) {
            normalized.text = intl.messages.we_won;
        } else {
            normalized.text = intl.messages.we_lost;
            normalized.type = 3;
        }
    } else {
        if (data.winnerTeam === 1) {
            normalized.text = intl.messages.we_won;
        } else {
            normalized.text = intl.messages.we_lost;
            normalized.type = 3;
        }
    }
    return normalized;
}

const GameEnd = ({ data, replayGame, replayData, leaveRoom }) => {
    const intl = useIntl();

    const dispatch = useDispatch();
    const me = useSelector((state) => state.me.data);

    const usersInfo = useSelector((state) => state.usersInfo.data);

    const myTourPoints = data?.usersInfo?.tournamentResult?.winTeam?.includes(
        me?.userId
    );
    const tourResult = data?.usersInfo?.tournamentResult;

    const dataState = !tourResult
        ? ''
        : Object.keys(tourResult.usersTournaments);
    const userData = dataState ? dataState.includes(me.userId.toString()) : '';

    const mySeat = usersInfo.findIndex((data) => data.userId === me.userId);
    const team = mySeat % 2;
    const usersInfoData = data.usersInfo.usersInfo[me.userId];

    const contractStatusForTeam = getContractStatusForTeam(intl, data, team);
    const userUpdate = async () => {
        const userUpdateChip = await userInfo_req(me.userId);

        dispatch(setUserUpdate(userUpdateChip));
    };
    useEffect(() => {
        userUpdate();
    }, []);
    useEffect(() => {
        leaveRoom(replayData);
    }, [replayData]);

    const leaveGame = () => {
        if (data?.usersInfo?.tournamentResult) {
            dispatch(navigate(routesEnum.TOURNAMENT_DASHBOARD));
        } else {
            dispatch(navigate(routesEnum.LOBBY));
        }
        roomSingleton.reset();
        dispatch(welcomeUserRefresh({ data: null }));
        socketBridge.unwatchRoomChannel();
        roomSingleton.bazarState = 0;
        roomSingleton.playState = 0;
    };

    return (
        <div className="game-end">
            <div className="game-end-ribbon">
                <PrimaryRibbon
                    text={contractStatusForTeam.text}
                    type={contractStatusForTeam.type}
                />
            </div>
            <div className="best-score-block">
                <BestScore bestScore={usersInfoData.bestScore} />
            </div>
            {/* <div className="star-block">
                <Star />
                <Star type="active" />
                <Star type="active" />
            </div> */}

            <div className="final-score">
                {data.forceEnd ? (
                    <div className="game-end-force-end">
                        {intl.messages.gamePlay.endGame.forceEnd}
                    </div>
                ) : (
                    <div>
                        <span className="final-score-text">
                            {intl.messages.gamePlay.endGame.finalScore}
                        </span>
                        <div className="score">
                            <span className="score-me">{data.score[0]}</span>
                            <div className="score-square">
                                <span></span>
                                <span></span>
                            </div>
                            <span className="score-us">{data.score[1]}</span>
                        </div>
                    </div>
                )}
            </div>

            <span className="game-end-rewards">
                {intl.messages.gamePlay.endGame.yourRewards}
            </span>
            <div className="game-end-chip-block">
                <GameChipInfo
                    imageId={cdnImages.green_chip_icon}
                    count={usersInfoData.chipsInfo.green}
                    text={intl.messages.gamePlay.endGame.greenChips}
                />
                {usersInfoData.chipsInfo.red > 0 && (
                    <GameChipInfo
                        imageId={cdnImages.game_end_red_chip}
                        count={usersInfoData.chipsInfo.red}
                        text={intl.messages.gamePlay.endGame.redChips}
                    />
                )}

                <GameChipInfo
                    imageId={cdnImages.xp_icon}
                    count={usersInfoData.gainedPoints}
                    text={intl.messages.gamePlay.endGame.experience}
                />
                {userData && (
                    <GameChipInfo
                        imageId={cdnImages.tournament_lobby}
                        count={
                            myTourPoints
                                ? data?.usersInfo?.tournamentResult
                                      ?.winTeamPoints
                                : data?.usersInfo?.tournamentResult
                                      ?.loseTeamPoints
                        }
                        text={'POINTS'}
                    />
                )}
            </div>
            <div className="game-end-button" onClick={replayGame}>
                <ButtonTreeD text={intl.messages.gamePlay.endGame.replay} />
            </div>
            <span className="leave-game" onClick={leaveGame}>
                {intl.messages.gamePlay.endGame.leaveGame}
            </span>
        </div>
    );
};

export default GameEnd;
