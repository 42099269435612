import { createSlice } from '@reduxjs/toolkit';

export const SocialLoginSlice = createSlice({
    name: 'social',
    initialState: {
        zone: '',
    },
    reducers: {
        soccialLogin: (state, action) => {
            state.zone = action.payload;
        },
    },
});
export const { soccialLogin } = SocialLoginSlice.actions;

export default SocialLoginSlice.reducer;
