import React, { useState } from 'react';
import './tour-info.scss';

import { useDispatch, useSelector } from 'react-redux';
import Timer from '../../../../components/timer/timer';
import { cdnImages } from '../../../../assets/cdn.assets';
import { useIntl } from 'react-intl';

function getVip(id) {
    switch (id) {
        case 111:
            return '1 Week';
        case 112:
            return '1 Month';
        case 113:
            return '1 Year';
        default:
            return;
    }
}
const TourInfo = ({ tourRewards }) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const me = useSelector((state) => state.me.data);
    const tourInfoData = useSelector((state) => state.global.navigateData);
    const tourData = tourInfoData.tourData[0];

    const tournamentName = tourData?.name;

    const lastPos = tourRewards?.prizes
        .map((item) => item)
        .filter((data) => data.min < data.max)
        .map((item) => {
            if (item.min < item.max && item.amount > 0) {
                return (item.max - item.min) * item.amount;
            }
        })
        .filter((item) => item > 0);

    const fund = tourRewards?.prizes
        .map((item) => item.amount)
        .reduce((a, b) => {
            return a + b;
        });
    let fundArr = [];
    fundArr.push(fund);

    const prizeFund = fundArr.concat(lastPos).reduce((a, b) => {
        return a + b;
    });

    return (
        <div className="tour-info-block">
            <div className="tour-info-text">
                <p>
                    {intl.messages.tournament.intro_tour}
                    {tournamentName}
                </p>
                <span>{tourData?.info}</span>
            </div>
            <div className="tour-info-time">
                <div className="tour-time">
                    <img src={cdnImages.tour_time} alt="#" />
                    <div className="tour-time-block">
                        <div className="tour-info-timer">
                            <Timer endDate={tourData?.endDate} />
                        </div>
                        <div className="tour-time-days">
                            <span>{intl.messages.tournament.days}</span>
                            <span className="tour-time-hours">
                                {intl.messages.tournament.hours}
                            </span>
                            <span className="tour-time-min">
                                {intl.messages.tournament.min}
                            </span>
                            <span className="tour-time-sec">
                                {intl.messages.tournament.sec}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="tour-info-cup">
                    <img
                        src={cdnImages.tour_cup}
                        alt="#"
                        className="tour-cup-img"
                    />
                    <div className="tour-prize">
                        <img src={cdnImages.tour_red} alt="#" />
                        <div className="tour-prize-text">
                            <span>{prizeFund}</span>
                            <p>{intl.messages.tournament.prize_fund}</p>
                        </div>
                    </div>
                </div>
                <div className="tour-swords">
                    <img src={cdnImages.tour_knifes} alt="#" />
                    <div className="tour-swords-game">
                        <span>{tourData?.maxGames}</span>
                        <p>{intl.messages.tournament.games}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TourInfo;
