import React from 'react';
import './user-menu.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
    openCloseChat,
    openCloseGift,
    openCloseSmile,
    openGamePlayMenu,
} from '../../../redux/menu-game.slice';
import { cdnImages } from '../../../assets/cdn.assets';

const UserMenu = ({ bazarFinish, firstPlay }) => {
    const userId = useSelector((state) => state.me.data.userId);
    const giftOpenedState = useSelector(
        (state) => state.userMenuGame.gift.openedState
    );
    const chatOpened = useSelector(
        (state) => state.userMenuGame.chat.chatOpenedState
    );
    const smileOpened = useSelector(
        (state) => state.userMenuGame.smile.smileOpenedState
    );
    const dispatch = useDispatch();
    const openCloseGiftMenu = () => {
        if (bazarFinish || firstPlay) {
            dispatch(
                openCloseGift({
                    openedState: giftOpenedState === 3 ? 1 : 2,
                    userId,
                })
            );
            dispatch(openCloseChat({ chatOpenedState: 3 }));
            dispatch(openCloseSmile({ smileOpenedState: 3 }));
            dispatch(openGamePlayMenu({ rightMenuOpenedState: 3 }));
        }
    };
    const openSmileMenu = () => {
        if (bazarFinish || firstPlay) {
            dispatch(
                openCloseSmile({
                    smileOpenedState: smileOpened === 3 ? 1 : 2,
                })
            );
            dispatch(openCloseChat({ chatOpenedState: 3 }));
            dispatch(openCloseGift({ openedState: 3 }));
            dispatch(openGamePlayMenu({ rightMenuOpenedState: 3 }));
        }
    };
    const openChatMenu = () => {
        if (bazarFinish || firstPlay) {
            dispatch(
                openCloseChat({
                    chatOpenedState: chatOpened === 3 ? 1 : 2,
                })
            );
            dispatch(openCloseGift({ openedState: 3 }));
            dispatch(openCloseSmile({ smileOpenedState: 3 }));
            dispatch(openGamePlayMenu({ rightMenuOpenedState: 3 }));
        }
    };

    return (
        <>
            <div className="user-menu">
                <div className="user-menu-icon" onClick={openCloseGiftMenu}>
                    <img
                        src={cdnImages.gift}
                        alt="#"
                        className={`menu-under-icon${
                            bazarFinish || firstPlay ? '' : ' icon-true'
                        }`}
                    />
                </div>
                <div className="user-menu-icon" onClick={openChatMenu}>
                    <img
                        src={cdnImages.chat}
                        alt="#"
                        className={`menu-under-icon${
                            bazarFinish || firstPlay ? '' : ' icon-true'
                        }`}
                    />
                </div>
                <div className="user-menu-icon" onClick={openSmileMenu}>
                    <img
                        src={cdnImages.emoji}
                        alt="#"
                        className={`menu-under-icon${
                            bazarFinish || firstPlay ? '' : ' icon-true'
                        }`}
                    />
                </div>
            </div>

            {/* {openSmileMenu && <Smiles />} */}
        </>
    );
};

export default UserMenu;
