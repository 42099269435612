import React from 'react';
import './user-score.scss';

const UserScore = ({ seat = '', score = 0, team }) => {
    return (
        <div className={`user_score_${seat}`}>
            {!team && team.length === 0 && (seat === 1 || seat === 2) && (
                <span>0</span>
            )}
            {team === 0 && seat === 1 && <span>{score[0]}</span>}
            {team === 0 && seat === 2 && <span>{score[1]}</span>}
            {team === 1 && seat === 1 && <span>{score[1]}</span>}
            {team === 1 && seat === 2 && <span>{score[0]}</span>}
        </div>
    );
};
export default UserScore;
