import React, { useEffect, useState, useRef } from 'react';
import './round-score.scss';
import { useIntl } from 'react-intl';
import RoundScoreLine from './round-score-line/round-score-line';
import RoundScoreContra from './round-score-contra/round-score-contra';
import PrimaryRibbon from '../../../../components/ribbons/primary.ribbon';
import { groupByType } from '../../../../helpers/groupBy.helper';
import { cdnImages } from '../../../../assets/cdn.assets';

function getContractStatusForTeam(intl, data, team) {
    const normalized = {
        text: '',
        type: 1,
    };
    const contra = data.multiplyFactors.contraX2;
    const recontra = data.multiplyFactors.contraX4;
    const contractTeam = data.contractTeam;
    const contractStatus = data.contractStatus;

    if (contra === false && recontra === false) {
        if (team === 0) {
            if (contractTeam === 0) {
                if (contractStatus === true) {
                    normalized.text = intl.messages.we_won;
                    normalized.type = 3;
                } else if (contractStatus === false) {
                    normalized.text = intl.messages.we_lost;
                    normalized.type = 1;
                }
            } else {
                if (contractStatus === true) {
                    normalized.text = intl.messages.they_won;
                    normalized.type = 3;
                } else if (contractStatus === false) {
                    normalized.text = intl.messages.they_lose;
                    normalized.type = 1;
                }
            }
        } else {
            if (contractTeam === 0) {
                if (contractStatus === true) {
                    normalized.text = intl.messages.they_won;
                    normalized.type = 3;
                } else if (contractStatus === false) {
                    normalized.text = intl.messages.they_lose;
                    normalized.type = 1;
                }
            } else {
                if (contractTeam === 1 && contractStatus === true) {
                    normalized.text = intl.messages.we_won;
                    normalized.type = 3;
                } else if (contractTeam === 1 && contractStatus === false) {
                    normalized.text = intl.messages.we_lost;
                    normalized.type = 1;
                }
            }
        }
    }
    if (contra === true && recontra === false) {
        if (team === 0) {
            if (contractTeam === 0) {
                if (contractStatus === true) {
                    normalized.text = intl.messages.contra_won;
                    normalized.type = 2;
                } else {
                    normalized.text = intl.messages.contra_lose;
                    normalized.type = 2;
                }
            } else {
                if (contractStatus === true) {
                    normalized.text = intl.messages.contra_lose;
                    normalized.type = 2;
                } else {
                    normalized.text = intl.messages.contra_won;
                    normalized.type = 2;
                }
            }
        } else {
            if (contractTeam === 0) {
                if (contractStatus === true) {
                    normalized.text = intl.messages.contra_lose;
                    normalized.type = 2;
                } else {
                    normalized.text = intl.messages.contra_won;
                    normalized.type = 2;
                }
            } else {
                if (contractStatus === true) {
                    normalized.text = intl.messages.contra_won;
                    normalized.type = 2;
                } else {
                    normalized.text = intl.messages.contra_lose;
                    normalized.type = 2;
                }
            }
        }
    }
    if (contra === true && recontra === true) {
        if (team === 0) {
            if (contractTeam === 0) {
                if (contractStatus === true) {
                    normalized.text = intl.messages.recontra_won;
                    normalized.type = 2;
                } else {
                    normalized.text = intl.messages.recontra_lose;
                    normalized.type = 2;
                }
            } else {
                if (contractStatus === true) {
                    normalized.text = intl.messages.recontra_lose;
                    normalized.type = 2;
                } else {
                    normalized.text = intl.messages.recontra_won;
                    normalized.type = 2;
                }
            }
        } else {
            if (contractTeam === 0) {
                if (contractStatus === true) {
                    normalized.text = intl.messages.recontra_lose;
                    normalized.type = 2;
                } else {
                    normalized.text = intl.messages.recontra_won;
                    normalized.type = 2;
                }
            } else {
                if (contractStatus === true) {
                    normalized.text = intl.messages.recontra_won;
                    normalized.type = 2;
                } else {
                    normalized.text = intl.messages.recontra_lose;
                    normalized.type = 2;
                }
            }
        }
    }
    return normalized;
}

function contractSuit(suit, intl) {
    switch (suit) {
        case 0:
            return {
                text: intl.messages.club,
                imageId: cdnImages.clubs_icon,
            };
        case 1:
            return {
                text: intl.messages.dimond,
                imageId: cdnImages.diamonds_icon,
            };
        case 2:
            return {
                text: intl.messages.heart,
                imageId: cdnImages.hearts_icon,
            };

        case 3:
            return {
                text: intl.messages.spade,
                imageId: cdnImages.spade_icon,
            };
        case 4:
            return {
                text: intl.messages.noTrump,
                imageId: cdnImages.boy,
            };
        case 5:
            return {
                text: intl.messages.allTrump,
                imageId: cdnImages.deep_icon,
            };
        default:
            break;
    }
}

const RoundScore = ({ data, closeRoundScore, closeRoundGameEnd, team }) => {
    const intl = useIntl();
    const [countTimer, setCountTimer] = useState(5);
    const timeoutRef = useRef();
    useEffect(() => {
        if (countTimer === 0 && typeof closeRoundScore === 'function') {
            clearTimeout(timeoutRef.current);
            closeRoundScore();
            if (data.gameEnded) {
                closeRoundGameEnd();
            }
            return;
        }

        timeoutRef.current = setTimeout(() => {
            setCountTimer(countTimer - 1);
        }, 1000);
    }, [countTimer]);

    const myGrouped = groupByType(
        data && data.extraScore && data.extraScore[0]
    );
    const secondGrouped = groupByType(
        data && data.extraScore && data.extraScore[1]
    );

    const contractStatusForTeam = getContractStatusForTeam(intl, data, team);

    const suitData = contractSuit(data.contractSuit, intl);
    const contractSuitData = data.contractSuit <= 3 ? 16 : 26;

    const contraContractSuit = data.multiplyFactor * contractSuitData;

    return (
        <div className="round_score">
            <div className="round_score_contract">
                <PrimaryRibbon
                    text={contractStatusForTeam.text}
                    type={contractStatusForTeam.type}
                />
            </div>
            <div className="round_score_content">
                <table className="round_score_table_content">
                    <div className="round-img">
                        <span>{suitData.text}</span>
                        <img src={suitData.imageId} alt="#" />
                    </div>
                    <RoundScoreLine
                        scoreUs={intl.messages.gamePlay.us}
                        scoreThem={intl.messages.gamePlay.them}
                    />
                    {data.multiplyFactors.contraX2 === true && (
                        <RoundScoreContra
                            text={intl.messages.roundScore.order}
                            scoreContra={data.multiplyFactor}
                            scoreContraValue={contractSuitData}
                            contraContractSuit={contraContractSuit}
                        />
                    )}
                    {/* {!contraX2 && (
                        <RoundScoreLine
                            text={intl.messages.roundScore.order}
                            value={data.contractValue}
                            team={team}
                        />
                    )} */}
                    <RoundScoreLine
                        text={intl.messages.roundScore.taken}
                        team={team}
                        scoreUs={`${data.collectedScore[team === 0 ? 0 : 1]} (${
                            data.collectedScore[team === 0 ? 2 : 3]
                        })`}
                        scoreThem={`${
                            data.collectedScore[team === 0 ? 1 : 0]
                        } (${data.collectedScore[team === 0 ? 3 : 2]})`}
                    />

                    <RoundScoreLine
                        text={intl.messages.roundScore.tierce}
                        // value={myGrouped.tierce || secondGrouped.tierce}
                        valueUs={
                            team === 0
                                ? myGrouped?.tierce
                                : secondGrouped?.tierce
                        }
                        valueThem={
                            team === 0
                                ? secondGrouped?.tierce
                                : myGrouped?.tierce
                        }
                        team={team}
                    />
                    <RoundScoreLine
                        text={intl.messages.roundScore.blot}
                        // value={myGrouped.blot || secondGrouped.blot}
                        valueUs={
                            team === 0 ? myGrouped?.blot : secondGrouped?.blot
                        }
                        valueThem={
                            team === 0 ? secondGrouped?.blot : myGrouped?.blot
                        }
                        team={team}
                    />
                    <RoundScoreLine
                        text={'50'}
                        valueUs={
                            team === 0
                                ? myGrouped?.quarte
                                : secondGrouped?.quarte
                        }
                        valueThem={
                            team === 0
                                ? secondGrouped?.quarte
                                : myGrouped?.quarte
                        }
                        team={team}
                    />

                    <RoundScoreLine
                        text={intl.messages.roundScore.total}
                        scoreUs={data.roundScore[team === 0 ? 0 : 1]}
                        scoreThem={data.roundScore[team === 0 ? 1 : 0]}
                        team={team}
                    />
                </table>
            </div>
            <span className="start_new_game">
                {intl.messages.roundScore.newGame} {countTimer}
            </span>
        </div>
    );
};

export default RoundScore;
