import React from 'react';
import { cdnImages } from '../../../../../assets/cdn.assets';
import './tour-partisipation-block.scss';
const TournamentPartisipationBlock = ({ imageId, title, text, tourImg }) => {
    return (
        <div className="tour-partisipation">
            <div className="tour-partisipation-img">
                <img src={imageId} alt="#" />
            </div>

            <div className="tour-partisipation-text">
                <span>{title}</span>
                <div className="tour-partisipation-info">
                    {tourImg && <img src={tourImg} alt="#" />}

                    <p>{text}</p>
                </div>
            </div>
        </div>
    );
};
export default TournamentPartisipationBlock;
