import React from 'react';

import './tournament-partisipation.scss';
import TournamentPartisipationBlock from './tour-partisipation-block/tour-partisipation-block';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from '../../../../redux/global.slice';
import routesEnum from '../../../../enums/routes.enum';
import { tournamentJoin_req } from '../../../../api/tournament/tournament.api';
import { cdnImages } from '../../../../assets/cdn.assets';
import { useIntl } from 'react-intl';

const TournamentPartisipation = ({ tourRewards }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const tourData = useSelector((state) => state.global.navigateData);

    const tourInfoData = tourData.tourData[0];
    const lastPos = tourRewards?.prizes
        .map((item) => item)
        .filter((data) => data.min < data.max)
        .map((item) => {
            if (item.min < item.max && item.amount > 0) {
                return (item.max - item.min) * item.amount;
            }
        })
        .filter((item) => item > 0);

    const fund = tourRewards?.prizes
        .map((item) => item.amount)
        .reduce((a, b) => {
            return a + b;
        });
    let fundArr = [];
    fundArr.push(fund);

    const prizeFund = fundArr.concat(lastPos).reduce((a, b) => {
        return a + b;
    });
    const tourMoreInfo = () => {
        dispatch(navigate(routesEnum.TOURNAMENT));
    };

    return (
        <div className="partisipation">
            <div className="tour-partisipation-block">
                <TournamentPartisipationBlock
                    imageId={cdnImages.tour_partisipation}
                    title={intl.messages.tournament.participation}
                    text={tourInfoData.info}
                />
                <TournamentPartisipationBlock
                    imageId={cdnImages.tour_prize_found}
                    title={intl.messages.tournament.prize_fund}
                    tourImg={cdnImages.tour_red}
                    text={prizeFund}
                />
            </div>
        </div>
    );
};
export default TournamentPartisipation;
