import React, { useEffect, useRef } from 'react';

import './user-timing.scss';
function UserTiming({ percent, duration, vip }) {
    let currLeave = 0;
    let currWin = 0;
    let delta = 0.016;
    let past = 0;
    const canvasRef = useRef(null);

    useEffect(() => {
        const leavePie = canvasRef.current;
        const ctx = leavePie.getContext('2d');

        function circleAnimation(
            draw_to,
            degree,
            id,
            finish,

            sit = 'me'
        ) {
            const x = 50;
            const start = 1.5 * Math.PI;
            const circum = Math.PI * 2;
            if (!leavePie) {
                return;
            }
            ctx.beginPath();
            ctx.moveTo(x, x);
            if (vip) {
                ctx.clearRect(0, 0, 110, 110);
                ctx.arc(x, x, 45, start, draw_to, false);
            } else {
                ctx.clearRect(0, 0, 107, 107);
                ctx.arc(x, x, 41, start, draw_to, false);
            }

            // ctx.clearRect(0, 0, 110, 110);
            ctx.lineTo(x, x);

            // ctx.arc(x, x, 45, start, draw_to, false);
            ctx.fillStyle = '#E49706';
            ctx.fill();
            if (id === `leaveCanvas-${sit}`) {
                currLeave++;
                if (currLeave < finish + 1) {
                    requestAnimationFrame(() => {
                        circleAnimation(
                            (circum * currLeave) / 100 + start,
                            degree,
                            id,
                            finish,
                            sit
                        );
                    });
                }
            } else if (currWin < percent / 100) {
                requestAnimationFrame((timestamp = 0) => {
                    currWin += delta / duration;
                    circleAnimation(
                        circum * currWin + start,
                        degree,
                        id,
                        finish,
                        sit
                    );
                    if (past) {
                        delta = (timestamp - past) / 1000;
                        past = timestamp;
                    } else {
                        past = timestamp;
                    }
                });
            }
            ctx.closePath();
        }

        let id;
        let sit;
        if (sit === 'me') {
            id = 'winingRate-0';
        } else {
            id = 'winingRate-0';
        }

        const degree = 0;
        circleAnimation(0, degree, id, percent, sit);
    }, []);
    return (
        <div className="spector-chart">
            <canvas id="id" ref={canvasRef} width={110} height={110} />
            {/* <img
                className="mask"
                data-mask="star.png"
                src={imageLoader.getImage('wins_spectre_center')}
                alt="spec"
                ref={imgRef}
                id="image"
            /> */}
        </div>
    );
}

export default UserTiming;
