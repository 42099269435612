import React from 'react';
import './user-recontra.scss';

const UserRecontra = ({ seat = '1' }) => {
    return (
        <div className={`user-recontra user-recontra-${seat}`}>
            <span>RC</span>
        </div>
    );
};

export default UserRecontra;
