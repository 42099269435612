import React, { useEffect, useState } from 'react';
import './waiting-players.scss';
import WaitingLoader from './waiting-loader/waiting-loader';

import PrimaryRibbon from '../../../../components/ribbons/primary.ribbon';

import UserImageInfoName from '../../../../components/user/user-image-info-name/user-image-info-name';
import ButtonTreeD from '../../../../components/buttons/button_3d/button-3d';
import { useDispatch } from 'react-redux';
import socketBridge from '../../../../socket';
import { UserChannelEmittingEvents } from '../../../../socket/socket.events';
import { navigate } from '../../../../redux/global.slice';
import routesEnum from '../../../../enums/routes.enum';
import roomSingleton from '../../../../singletons/room.singleton';
import { useIntl } from 'react-intl';
import { cdnImages } from '../../../../assets/cdn.assets';

function roomTypeChip(type) {
    switch (type) {
        case 'micro':
            return 4000;
        case 'big':
            return 11000;
        case 'proffesional':
            return 21000;
        case 'bonus':
            return 1000;
        default:
            break;
    }
}

function roomChipDataType(type, intl) {
    switch (type) {
        case 'micro':
            return intl.messages.table.beginners;
        case 'big':
            return intl.messages.table.bigGame;
        case 'proffesional':
            return intl.messages.table.professionals;
        case 'bonus':
            return intl.messages.table.bonusGame;
        default:
            break;
    }
}
const WaitingPlayers = ({ userInfo }) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const [showCancel, setShowCancel] = useState(false);
    const roomChipData = roomSingleton.roomType;
    const cancelGame = () => {
        socketBridge.emitToUserChannel({
            event: UserChannelEmittingEvents.FORCE_LEAVE_USER,
            partnerId: null,
        });
        dispatch(navigate(routesEnum.LOBBY));
    };
    useEffect(() => {
        setTimeout(() => {
            setShowCancel(true);
        }, 3000);
    }, []);

    return (
        <>
            <div className="waiting_players">
                <div className="waiting-popup">
                    <div className="ribbon_wrapper">
                        <PrimaryRibbon
                            type={3}
                            text={intl.messages.gamePlay.waiting.waitingText}
                        />
                    </div>
                    <div className="waiting_text_block">
                        <span className="waiting_text">
                            {intl.messages.gamePlay.waiting.waitingUser}
                        </span>
                    </div>
                    <div className={`waiting_loading_block`}>
                        <div className="waiting_loading">
                            <WaitingLoader />
                        </div>
                        <div
                            className={`waiting_load${
                                showCancel ? ' with_cancel' : ''
                            }`}
                            onClick={cancelGame}
                        >
                            {showCancel && (
                                <ButtonTreeD text={intl.messages.cancel} />
                            )}
                        </div>
                    </div>
                    <div className="waiting_table">
                        <span>{roomChipDataType(roomChipData, intl)}</span>
                        <span>
                            {' '}
                            {intl.messages.gamePlay.waiting.waitingGameReward}
                        </span>
                    </div>
                    <div className="waiting_chip_block">
                        <div className="waiting_under_block">
                            <div className="waiting_chip">
                                <img
                                    src={cdnImages.red_chips_waiting}
                                    alt="red_chips"
                                />
                            </div>
                            <div className="waiting_chip_table">
                                <span className="chip_count">
                                    {roomTypeChip(roomChipData)}
                                </span>
                                <span className="chip_type">
                                    {intl.messages.gamePlay.waiting.waitingRed}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Anouncement
                seat={2}
                showUserAnnouncement={[
                    ['a', 'b', 'c'],
                    ['a', 'b', 'c', 'd'],
                ]}
            />
            
            <Anouncement
                seat={4}
                showUserAnnouncement={[
                    ['a', 'b', 'c'],
                    ['a', 'b', 'c'],
                ]}
            /> */}
            {/* <div className={`user_image_info_${2}`}>
                <Tooltip
                    userSaySuit={{
                        suit: 1,
                        value: 'quarte' 'tierce',
                    }}
                    seat={2}
                />
            </div> */}
            {/* <div className={`user_image_info_${2}`}>
                <Tooltip
                    userSaySuit={{
                        suit: 1,
                        value: 8,
                    }}
                    seat={2}
                />
            </div> */}
            {/* <Anouncement
                seat={2}
                showUserAnnouncement={[['a', 'b', 'c', 'd']]}
            /> */}
            {/* <div className="chit">
                <Player src={chit} autoplay loop />
            </div> */}

            <div className="me-user">
                <div className="me-user-block">
                    <div className="me-user-img">
                        <img
                            src={userInfo.avatar}
                            alt="user"
                            onError={(ev) => {
                                ev.target.onerror = null;
                                ev.target.src = cdnImages.user_avatar;
                            }}
                        />
                    </div>
                </div>
                <UserImageInfoName
                    name={userInfo.username}
                    vip={userInfo.vip}
                />
            </div>
        </>
    );
};
export default WaitingPlayers;
