const lastHandCards = {
    // hearts
    a: {
        suit: 0,
        value: 7,
    },
    b: {
        suit: 0,
        value: 8,
    },
    c: {
        suit: 0,
        value: 9,
    },
    d: {
        suit: 0,
        value: 10,
    },
    e: {
        suit: 0,
        value: 'J',
    },
    f: {
        suit: 0,
        value: 'Q',
    },
    g: {
        suit: 0,
        value: 'K',
    },
    h: {
        suit: 0,
        value: 'A',
    },
    // diamonds
    i: {
        suit: 1,
        value: 7,
    },
    j: {
        suit: 1,
        value: 8,
    },
    k: {
        suit: 1,
        value: 9,
    },
    l: {
        suit: 1,
        value: 10,
    },
    m: {
        suit: 1,
        value: 'J',
    },
    n: {
        suit: 1,
        value: 'Q',
    },
    o: {
        suit: 1,
        value: 'K',
    },
    p: {
        suit: 1,
        value: 'A',
    },
    // spades
    q: {
        suit: 2,
        value: 7,
    },
    r: {
        suit: 2,
        value: 8,
    },
    s: {
        suit: 2,
        value: 9,
    },
    t: {
        suit: 2,
        value: 10,
    },
    u: {
        suit: 2,
        value: 'J',
    },
    v: {
        suit: 2,
        value: 'Q',
    },
    w: {
        suit: 2,
        value: 'K',
    },
    x: {
        suit: 2,
        value: 'A',
    },

    // clubs

    y: {
        suit: 3,
        value: 7,
    },

    z: {
        suit: 3,
        value: 8,
    },
    A: {
        suit: 3,
        value: 9,
    },
    B: {
        suit: 3,
        value: 10,
    },
    C: {
        suit: 3,
        value: 'J',
    },
    D: {
        suit: 3,
        value: 'Q',
    },
    E: {
        suit: 3,
        value: 'K',
    },
    F: {
        suit: 3,
        value: 'A',
    },
};

Object.freeze(lastHandCards);

export default lastHandCards;
