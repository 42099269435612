import React from 'react';
import { useIntl } from 'react-intl';
import './tour-header-info.scss';

const TourHeaderInfo = ({ tourCurrentTab, setTourCurrentTab }) => {

    const intl = useIntl();
    const data = [
        { text: intl.messages.tournament.intro},
        { text: intl.messages.tournament.rewards },
        { text: intl.messages.tournament.conditions },
    ];
    return (
        <div className={`tour-header-info tour-header-info-${tourCurrentTab}`}>
            {data.map((item, i) => (
                <div
                    className={`tour-header-text ${
                        tourCurrentTab === i ? `text-active` : ''
                    }`}
                    onClick={() => setTourCurrentTab(i)}
                >
                    <span>{item.text}</span>
                </div>
            ))}
        </div>
    );
};

export default TourHeaderInfo;
