import React from 'react';
import { useIntl } from 'react-intl';
import './user-chat.scss';

const UserChat = ({ seat, emojiId }) => {
    const intl = useIntl();
    const data = [
        { text: intl.messages.gamePlay.chat.cool, id: 1 },
        { text: intl.messages.gamePlay.chat.oops, id: 2 },
        { text: intl.messages.gamePlay.chat.tooBad, id: 3 },
        { text: intl.messages.gamePlay.chat.goodJob, id: 4 },
        { text: intl.messages.gamePlay.chat.noPoints, id: 5 },
        { text: intl.messages.gamePlay.chat.thanks, id: 6 },
    ];

    return (
        <div className={`user-chat user-chat-${seat} `}>
            {data.map((item) => (
                <div className={`${item.id === 5 ? 'user-chat-id' : ''}`}>
                    {item.id === emojiId ? <span>{item.text}</span> : null}
                </div>
            ))}
            {/* <img src={user_chat} alt="" /> */}
        </div>
    );
};

export default UserChat;
