import React from 'react';

import './loading.bar.scss';
import { useIntl } from 'react-intl';

const LoadingBar = ({ progress }) => {
    const intl = useIntl();
    return (
        <div className="loading_bar">
            <div className="loading_block">
                <div
                    className="loading_bar_fill"
                    style={{
                        width: `${progress - 1}%`,
                    }}
                />
            </div>
            <span>{intl.messages.loading}</span>
        </div>
    );
};

export default LoadingBar;
