import { createSlice } from '@reduxjs/toolkit';

export const popupSlice = createSlice({
    name: 'popup',
    initialState: {
        currentPopupIndex: 0,
        popupIds: [],
        popupData: [],
    },
    reducers: {
        openPopups: (state, { payload }) => {
            if (!payload) {
                return state;
            }

            state.popupIds = payload.popupIds;
            state.popupData = payload.popupData || [];
        },
        nextPopup: (state, { payload }) => {
            const nextIndex = state.currentPopupIndex + 1;

            if (!state.popupIds[nextIndex]) {
                state.currentPopupIndex = 0;
                state.popupIds = 0;
                state.popupData = 0;
                return state;
            }

            const _state = {
                currentPopupIndex: nextIndex,
            };

            if (payload && payload.data) {
                const _popupData = [...state.popupData];
                _popupData[nextIndex] = payload.data;
                _state.popupData = _popupData;
            }

            return {
                ...state,
                ..._state,
            };
        },
        prevPopup: (state, { payload }) => {
            const prevIndex = state.currentPopupIndex - 1;

            if (prevIndex - 1 < 0 || !state.popupIds[prevIndex]) {
                return state;
            }

            const _state = {
                currentPopupIndex: prevIndex,
            };

            if (payload && payload.data) {
                const _popupData = [...state.popupData];
                _popupData[prevIndex] = payload.data;

                _state.popupData = _popupData;
            }

            return {
                ...state,
                ..._state,
            };
        },
        navigatePopup: (state, { payload }) => {
            const _state = {};
            if (payload && payload.popupIndex) {
                _state.currentPopupIndex = payload.popupIndex;
            }

            if (payload && payload.data) {
                const _popupData = [...state.popupData];
                _popupData[payload.popupIndex] = payload.data;
                _state.popupData = _popupData;
            }

            return {
                ...state,
                ..._state,
            };
        },
        closePopup: (state, { payload }) => {
            return {
                ...state,
                currentPopupIndex: 0,
                popupIds: [],
                popupData: [],
            };
        },
    },
});

export const {
    openPopups,
    nextPopup,
    prevPopup,
    navigatePopup,
    closePopup,
} = popupSlice.actions;

export default popupSlice.reducer;
