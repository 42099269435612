import React from 'react';
import './toggle.scss';

const Toggle = ({
    musicToggleActive,
    soundToggleActive,
    _onClick = () => {},
}) => {
    return (
        <div className="toggle">
            <div
                className={`toggle_in${
                    musicToggleActive || soundToggleActive ? ' active' : ''
                }`}
                onClick={_onClick}
            >
                <div
                    className={`toggle_active${
                        musicToggleActive || soundToggleActive ? ' icon' : ''
                    }`}
                />
            </div>
        </div>
    );
};
export default Toggle;
