import React from 'react';
import './suit.scss';

const Suit = ({
    imageId,

    text,
    item,
    bazarSuit,
    onClick = () => {},
}) => {
    return (
        <div className="block-suit">
            <div
                className={
                    bazarSuit >= item ? 'suit-button-selected' : 'suit-button'
                }
                onClick={onClick}
            >
                <div className="suit-block">
                    {imageId && <img src={imageId} alt="#" />}
                </div>
            </div>
            <span>{text}</span>
        </div>
    );
};

export default Suit;
