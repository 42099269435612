class PositionsSingleton {
  constructor() {
    this.positions = {};
  }

  setPosition(positionName, x = 0, y = 0) {
    if (this.positions[positionName]) {
      this.positions[positionName].x = x;
      this.positions[positionName].y = y;
    } else {
      this.positions[positionName] = {
        x,
        y,
      };
    }
  }

  getPosition(positionName) {
    return this.positions[positionName];
  }
}

const positionsSingleton = new PositionsSingleton();
Object.freeze(positionsSingleton);

export default positionsSingleton;
