import React from 'react';
import { useIntl } from 'react-intl';
import './tournament-result-user.scss';

const TournamentResultUser = ({ data }) => {
    const intl = useIntl();

    if (!data) {
        return null;
    }
    return (
        <>
            {data?.map((item, index) => (
                <div className="tournament-result-user-item">
                    <div
                        className={`user-number-block user-number-block-${item.position}`}
                    >
                        <span>{item.position}</span>
                    </div>
                    <div className="user-result-img-block">
                        {item.avatar ? (
                            <img src={item.avatar} alt="#" />
                        ) : (
                            <img
                                src="https://cdn.blotclub.am/avatars/enky1.png"
                                alt="#"
                            />
                        )}
                    </div>

                    <span className="user-result-name">{item.username}</span>
                    <div className="tournament-result-user-score">
                        <span className="result-user-score-text">
                            {intl.messages.tournament.scores}
                        </span>
                        <span className="result-user-score">{item.score}</span>
                    </div>
                </div>
            ))}
        </>
    );
};

export default TournamentResultUser;
