const cardNames = {
    // hearts
    y: {
        suit: 0,
        value: 0,
    },
    z: {
        suit: 0,
        value: 1,
    },
    A: {
        suit: 0,
        value: 2,
    },
    B: {
        suit: 0,
        value: 3,
    },
    C: {
        suit: 0,
        value: 4,
    },
    D: {
        suit: 0,
        value: 5,
    },
    E: {
        suit: 0,
        value: 6,
    },
    F: {
        suit: 0,
        value: 7,
    },
    // diamonds
    i: {
        suit: 1,
        value: 0,
    },
    j: {
        suit: 1,
        value: 1,
    },
    k: {
        suit: 1,
        value: 2,
    },
    l: {
        suit: 1,
        value: 3,
    },
    m: {
        suit: 1,
        value: 4,
    },
    n: {
        suit: 1,
        value: 5,
    },
    o: {
        suit: 1,
        value: 6,
    },
    p: {
        suit: 1,
        value: 7,
    },
    // hearts
    a: {
        suit: 2,
        value: 0,
    },
    b: {
        suit: 2,
        value: 1,
    },
    c: {
        suit: 2,
        value: 2,
    },
    d: {
        suit: 2,
        value: 3,
    },
    e: {
        suit: 2,
        value: 4,
    },
    f: {
        suit: 2,
        value: 5,
    },
    g: {
        suit: 2,
        value: 6,
    },
    h: {
        suit: 2,
        value: 7,
    },

    // spades
    q: {
        suit: 3,
        value: 0,
    },

    r: {
        suit: 3,
        value: 1,
    },
    s: {
        suit: 3,
        value: 2,
    },
    t: {
        suit: 3,
        value: 3,
    },
    u: {
        suit: 3,
        value: 4,
    },
    v: {
        suit: 3,
        value: 5,
    },
    w: {
        suit: 3,
        value: 6,
    },
    x: {
        suit: 3,
        value: 7,
    },
};

Object.freeze(cardNames);

export default cardNames;
