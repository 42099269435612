/* eslint-disable import/first */

import * as PIXI from 'pixi.js';

window.PIXI = PIXI;

require('pixi-spine');

// delete window.PIXI;

export default PIXI;
