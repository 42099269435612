import userSingleton from '../singletons/user.singleton';
import { axiosInstance } from './config';

export const getUrlParameter = (name) => {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(window.location.search);
    return results === null
        ? ''
        : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export const getUser_req = async () => {
    const zone = getUrlParameter('zone');

    const guestUserId = localStorage.beloteKingsUserId;
    if (zone === 'vk') {
        const accessToken = getUrlParameter('viewer_id');
        const response = await axiosInstance.post('/auth/vk-old', {
            zone: zone,
            access_token: accessToken,
            newLvlupRequired: true,
            platform: 'web',
        });
        if (response.data === 'Invalid params') {
            throw new Error('Please provide userId in the URL');
        }

        return response.data;
    } else if (zone === 'ok') {
        const accessToken = getUrlParameter('session_key');

        const response = await axiosInstance.post('/auth/ok', {
            access_token: accessToken,
            newLvlupRequired: true,
            from: 'WEB',
        });
        if (response.data === 'Invalid params') {
            throw new Error('Please provide userId in the URL');
        }

        return response.data;
    } else if (zone === 'fb') {
        const accessToken =
            userSingleton?.fbUserData?.authResponse?.accessToken;
        const uid = userSingleton?.fbUserData?.authResponse?.userID;

        if (!accessToken || !uid) {
            throw new Error('Cannot get params from Facebook');
        }

        const response = await axiosInstance.post('/auth/fb', {
            zone: zone,
            invitedBy: null,
            access_token: accessToken,
            newLvlupRequired: true,
            platform: 'web',
            uid: uid,
        });
        if (response.data === 'Invalid params') {
            throw new Error('Please provide userId in the URL');
        }

        return response.data;
    }

    if (!zone) {
        const uid = getUrlParameter('user_id');
        // const uid = window.location.search.replace('?user_id=', '');
        const response = await axiosInstance.post('/dev/auth', {
            zone: 'guest',
            uid: guestUserId ? guestUserId : uid,
        });
        if (response.data === 'Invalid params') {
            throw new Error('Please provide userId in the URL');
        }

        return response.data;
    }

    // Don't ask why :D
};

export const fbLogin_req = async (accessToken, uid) => {
    const response = await axiosInstance.post(
        '/auth/fb',
        {
            zone: 'fb',
            invitedBy: null,
            access_token: accessToken,
            newLvlupRequired: true,
            platform: 'web',
            uid,
        },
        {
            headers: {
                from: 'website',
            },
        }
    );
    return response.data;
};

export const userInfo_req = async (userId) => {
    const response = await axiosInstance.post('/userUpdates', {
        userId: userId,
        platform: 'web',
    });
    return response.data;
};
export const levelUp_req = async (userId) => {
    const response = await axiosInstance.post('/checkLevelUp', {
        userId,
    });
    return response.data;
};
export const checkNotification_req = async (userId) => {
    const response = await axiosInstance.get(
        `/checkNotifications?userId=${userId}`
    );
    return response.data;
};
