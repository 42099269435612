import { Player } from '@lottiefiles/react-lottie-player';
import React from 'react';
import chit from '../../../assets/smiles/chit.json';
import cry from '../../../assets/smiles/cry.json';
import glasses from '../../../assets/smiles/glasses.json';
import hand from '../../../assets/smiles/hand.json';
import hura from '../../../assets/smiles/hura.json';
import hypnotize from '../../../assets/smiles/hypnotize.json';
import party from '../../../assets/smiles/party.json';
import sleep from '../../../assets/smiles/sleep.json';
import smile from '../../../assets/smiles/smile.json';
import squint from '../../../assets/smiles/squint.json';
import sweat from '../../../assets/smiles/smile.json';
import what from '../../../assets/smiles/squint.json';
import './user-smile.scss';

const UserSmile = ({ seat, emojiId }) => {
    const data = [
        { smileImg: chit, id: 1 },
        { smileImg: cry, id: 2 },
        { smileImg: glasses, id: 3 },
        { smileImg: hand, id: 4 },
        { smileImg: hura, id: 5 },
        { smileImg: hypnotize, id: 6 },
        { smileImg: party, id: 7 },
        { smileImg: sleep, id: 8 },
        { smileImg: smile, id: 9 },
        { smileImg: squint, id: 10 },
        { smileImg: sweat, id: 11 },
        { smileImg: what, id: 12 },
    ];
    return (
        <div className={`user-smile user-smile-${seat}`}>
            {data.map((item) => (
                <div>
                    {emojiId === item.id ? (
                        <Player src={item.smileImg} alt="#" autoplay loop />
                    ) : (
                        ''
                    )}
                </div>
            ))}
        </div>
    );
};

export default UserSmile;
