import React, { useState } from 'react';
import TourRewardsLine from './tour-rewards-line/tour-rewards-line';

import './tournament-rewards.scss';
import { useSelector } from 'react-redux';

const TournamentRewards = ({ tourRewards }) => {
    const tourData = useSelector((state) => state.global.navigateData);

    const tourInfoData = tourData.tourData[0];

    const joined = useSelector((state) => state.global.joined);
    if (!tourInfoData) {
        return null;
    }

    return (
        <div className="tournament-rewards">
            <div className="tournament-rewards-content">
                <>
                    {tourRewards?.prizes?.map((item, index) =>
                        item.amount !== 0 ? (
                            <TourRewardsLine
                                item={item}
                                joined={joined}
                                index={index}
                            />
                        ) : null
                    )}
                </>
            </div>
        </div>
    );
};

export default TournamentRewards;
