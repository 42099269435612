import React from 'react';
import { cdnImages } from '../../../../../assets/cdn.assets';

import './waiting-loader.scss';

const WaitingLoader = () => {
    return (
        <img
            src={cdnImages.full_circle}
            alt="waiting_loader"
            className="waiting_loader_img"
        />
    );
};

export default WaitingLoader;
