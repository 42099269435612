const cdnUrl = 'https://cdn.belotkings.com';

export const cdnImages = {
    arrow_icon: cdnUrl + '/games/icons/arrow_icon.svg',
    green_panel: cdnUrl + '/games/user-panel/green_panel.svg',
    red_panel: cdnUrl + '/games/user-panel/red_panel.svg',
    gold: cdnUrl + '/games/store/gold.svg',
    redchip_small: cdnUrl + '/games/chips/redchip_small.svg',
    greenchip_small: cdnUrl + '/games/chips/greenchip_small.svg',
    big_game: cdnUrl + '/games/lobby_item/big-game.png',
    micro_game: cdnUrl + '/games/lobby_item/micro-game.png',
    bonus_game: cdnUrl + '/games/lobby_item/bonus-game.png',
    proffesional: cdnUrl + '/games/lobby_item/proffesional.png',
    lock: cdnUrl + '/games/lobby_item/lock.svg',
    discount_gradLine: cdnUrl + '/games/discount/discount_gradLine.svg',
    discount_offer: cdnUrl + '/games/discount/discount_offer.svg',
    discount_tournament: cdnUrl + '/games/discount/discount_tournament.svg',
    x3_icon: cdnUrl + '/games/discount/x3_icon.svg',
    x2_icon: cdnUrl + '/games/discount/x2_icon.svg',
    x4_icon: cdnUrl + '/games/discount/x4_icon.svg',
    x5_icon: cdnUrl + '/games/discount/x5_icon.svg',
    whell_animation_store: cdnUrl + '/games/discount/whell-animation-store.svg',
    game_play_menu: cdnUrl + '/games/icons/game-play-menu.svg',
    store: cdnUrl + '/games/user-panel/store.svg',
    plus_icon: cdnUrl + '/games/icons/plus-icon.svg',
    ace_heart: cdnUrl + '/games/splash-screen/ace-heart.svg',
    app_store_icon: cdnUrl + '/games/splash-screen/app-store-icon.svg',
    app_store: cdnUrl + '/games/splash-screen/app-store.png',
    candy_wings_icon: cdnUrl + '/games/splash-screen/candy-wings-icon.svg',
    coins_chips: cdnUrl + '/games/splash-screen/coins-chips.png',
    google_play_icon: cdnUrl + '/games/splash-screen/google-play-icon.svg',
    google_play: cdnUrl + '/games/splash-screen/google-play.png',
    jack_black_heart: cdnUrl + '/games/splash-screen/jack-black-heart.svg',
    jack_heart: cdnUrl + '/games/splash-screen/jack-heart.svg',
    king_brick: cdnUrl + '/games/splash-screen/king-brick.svg',
    nine_black_heart: cdnUrl + '/games/splash-screen/nine-black-heart.svg',
    splash_candy: cdnUrl + '/games/splash-screen/splash-candy.svg',
    splash_question: cdnUrl + '/games/splash-screen/splash-question.svg',
    user_login_bg: cdnUrl + '/games/splash-screen/user-login-bg.svg',
    belot_kings_logo: cdnUrl + '/games/logo/belot-kings-logo.svg',
    facebook: cdnUrl + '/games/social-media/facebook.svg',
    ok: cdnUrl + '/games/social-media/ok.svg',
    vk: cdnUrl + '/games/social-media/vk.svg',
    diamonds_icon: cdnUrl + '/games/suits/diamonds-icon.svg',
    boy: cdnUrl + '/games/suits/boy.svg',
    clubs_icon: cdnUrl + '/games/suits/clubs-icon.svg',
    clubs: cdnUrl + '/games/suits/clubs.svg',
    deep_icon: cdnUrl + '/games/suits/deep-icon.png',
    hearts_icon: cdnUrl + '/games/suits/hearts-icon.svg',
    hearts: cdnUrl + '/games/suits/hearts.svg',
    spade_icon: cdnUrl + '/games/suits/spade-icon.svg',
    spade: cdnUrl + '/games/suits/spade.svg',
    x2_suit_icon: cdnUrl + '/games/suits/x2-suit-icon.png',
    x4_suit_icon: cdnUrl + '/games/suits/x4-suit-icon.png',
    user_avatar: cdnUrl + '/games/icons/user-avatar.png',
    vip_game_play: cdnUrl + '/games/icons/vip-game-play.svg',
    chat: cdnUrl + '/games/user-menu-icon/chat.svg',
    emoji: cdnUrl + '/games/user-menu-icon/emoji.svg',
    gift: cdnUrl + '/games/user-menu-icon/gift.svg',
    giftImg: cdnUrl + '/games/icons/send_gift.svg',
    vip: cdnUrl + '/games/menu/vip.svg',
    menu_angle: cdnUrl + '/games/menu/menu-angle.svg',
    menu_chat: cdnUrl + '/games/menu/menu_chat.svg',
    menu_home: cdnUrl + '/games/menu/menu_home.svg',
    music_dis: cdnUrl + '/games/menu/music_dis.svg',
    sound_dis: cdnUrl + '/games/menu/sound_dis.svg',
    music: cdnUrl + '/games/menu/music.svg',
    sound: cdnUrl + '/games/menu/sound.svg',
    fb_image: cdnUrl + '/games/user_info/fb-image.svg',
    main_user_info_back: cdnUrl + '/games/user_info/main-user-info-back.png',
    cards: cdnUrl + '/games/user_info/cards.svg',
    green_chip_icon: cdnUrl + '/games/icons/green_chip_icon.svg',
    xp_icon: cdnUrl + '/games/icons/xp_icon.svg',
    game_end_red_chip: cdnUrl + '/games/chips/game-end-red-chip.svg',
    full_circle: cdnUrl + '/games/loader/full_circle.svg',
    red_chips_waiting: cdnUrl + '/games/game-info/red_chips_waiting.svg',
    yellow_small: cdnUrl + '/games/badges/yellow-small.svg',
    micro: cdnUrl + '/games/bg/micro.png',
    bazar_blot_logo: cdnUrl + '/games/logo/bazar-blot-logo.png',
    red_tabs: cdnUrl + '/games/popups/store/tabs/red_tabs.svg',
    red_tab: cdnUrl + '/games/popups/store/red_tab/red_tab.svg',
    chip_1000: cdnUrl + '/games/store/red/chip-1000.png',
    chip_15500: cdnUrl + '/games/store/red/chip-15500.png',
    chip_2500: cdnUrl + '/games/store/red/chip-2500.png',
    chip_5500: cdnUrl + '/games/store/red/chip-5500.png',
    store_x2: cdnUrl + '/games/store/store_x2.svg',
    store_x3: cdnUrl + '/games/store/store_x3.svg',
    store_x4: cdnUrl + '/games/store/store_x4.svg',
    store_x5: cdnUrl + '/games/store/store_x5.svg',
    vip_year: cdnUrl + '/games/store/vip/vip-year.png',
    vip_week: cdnUrl + '/games/store/vip/vip-week.png',
    vip_month: cdnUrl + '/games/store/vip/vip-month.png',
    vip_tab: cdnUrl + '/games/popups/store/vip_tab/vip_tab.png',
    green: cdnUrl + '/games/chips/green.svg',
    yellow_bg: cdnUrl + '/games/store/yellow/yellow_bg.svg',
    red: cdnUrl + '/games/chips/red.svg',
    wheel_tab: cdnUrl + '/games/wheel/wheel-tab.png',
    wheel_not_active: cdnUrl + '/games/wheel/wheel-not-active.png',
    yellow_30: cdnUrl + '/games/store/yellow/yellow-30.png',
    yellow_110: cdnUrl + '/games/store/yellow/yellow-110.png',
    yellow_chest: cdnUrl + '/games/store/yellow/yellow-chest.png',
    settings_close: cdnUrl + '/games/popups/settings/settings_close.svg',
    wheel_arrow: cdnUrl + '/games/wheel/wheel-arrow.png',
    wheel_icon: cdnUrl + '/games/wheel/wheel-icon.png',
    wheel_spin: cdnUrl + '/games/wheel/wheel-spin.svg',
    wheel_bottom: cdnUrl + '/games/wheel/wheel-bottom.png',
    admin_chip: cdnUrl + '/games/popups/admin-chip/admin-chip.png',
    level_up_bg: cdnUrl + '/games/popups/level-up/level-up-bg.png',
    level_up_circle: cdnUrl + '/games/popups/level-up/level-up-circle.png',
    level_up_ribbon: cdnUrl + '/games/popups/level-up/level-up-ribbon.png',
    yellow: cdnUrl + '/games/chips/yellow.svg',
    wreath: cdnUrl + '/games/popups/level-up/wreath.png',
    exit_question: cdnUrl + '/games/icons/exit-question.svg',
    error: cdnUrl + '/games/icons/error.svg',
    gift_card: cdnUrl + '/games/popups/gifts_form/gift_card.png',
    ribbon_gifts: cdnUrl + '/games/popups/gifts_form/ribbon_gifts.png',
    green_popup: cdnUrl + '/games/chips/green_popup.png',
    red_popup: cdnUrl + '/games/chips/red_popup.png',
    vip_big: cdnUrl + '/games/popups/vip/vip_big.png',
    noun_check: cdnUrl + '/games/popups/vip/noun_check.svg',
    star_active: cdnUrl + '/games/icons/star_active.svg',
    star_inActive: cdnUrl + '/games/icons/star_inActive.svg',
    close_popup: cdnUrl + '/games/popups/close_popup.svg',
    greenChip: cdnUrl + '/games/popups/greenChip.svg',
    monthVip: cdnUrl + '/games/popups/monthVip.png',
    ribbon_g: cdnUrl + '/games/ribbon/ribbon_g.svg',
    ribbon_g_1: cdnUrl + '/games/ribbon/ribbon_g_1.svg',
    ribbon_r: cdnUrl + '/games/ribbon/ribbon_r.svg',
    ribbon_r_1: cdnUrl + '/games/ribbon/ribbon_r_1.svg',
    ribbon_y: cdnUrl + '/games/ribbon/ribbon_y.svg',
    ribbon_y_1: cdnUrl + '/games/ribbon/ribbon_y_1.svg',
    tournament: cdnUrl + '/games/tournament/tournament.svg',
    tour_time_member: cdnUrl + '/games/tournament/tour-time-member.svg',
    tour_time: cdnUrl + '/games/tournament/tour-time.svg',
    tour_cup: cdnUrl + '/games/tournament/tour-cup.svg',
    tour_red: cdnUrl + '/games/tournament/tour-red.svg',
    tour_knifes: cdnUrl + '/games/tournament/tour-knifes.svg',
    tour_member: cdnUrl + '/games/tournament/tour-member.svg',
    tour_reward: cdnUrl + '/games/tournament/tour-reward.svg',
    tour_locked: cdnUrl + '/games/tournament/tour-locked.svg',
    tour_partisipation: cdnUrl + '/games/tournament/tour-partisipation.svg',
    tour_prize_found: cdnUrl + '/games/tournament/tour-prize-found.svg',
    reward_first: cdnUrl + '/games/tournament/reward-first.svg',
    reward_second: cdnUrl + '/games/tournament/reward-second.svg',
    reward_third: cdnUrl + '/games/tournament/reward-third.svg',
    reward_four: cdnUrl + '/games/tournament/reward-four.svg',
    red: cdnUrl + '/games/tournament/red.svg',
    user_navigator: cdnUrl + '/games/tournament/user-navigator.svg',
    tour_vip: cdnUrl + '/games/tournament/user-navigator.svg',
    gold_rec: cdnUrl + '/games/tournament/gold-rec.png',
    silver_rec: cdnUrl + '/games/tournament/silver-rec.png',
    bronze_rec: cdnUrl + '/games/tournament/bronze-rec.png',
    grey_rec: cdnUrl + '/games/tournament/grey-rec.png',
    black_rec: cdnUrl + '/games/tournament/black-rec.png',
    cash_game: cdnUrl + '/games/tournament/cash_game.png',
    tournament_lobby: cdnUrl + '/games/tournament/tournament-lobby.png',
    tour_info: cdnUrl + '/games/tournament/tour-info.png',
    tour_panel: cdnUrl + '/games/tournament/tour-panel.png',
    tour_home_icon: cdnUrl + '/games/tournament/tour-home-icon.svg',
    tour_game: cdnUrl + '/games/tournament/tour-game.png',
    table_bg: cdnUrl + '/games/tournament/table-bg.svg',
    tour_timer_yellow: cdnUrl + '/games/tournament/tour-time-yellow.svg',
    tour_timer_red: cdnUrl + '/games/tournament/tour-time-red.png',
    tour_timer_green: cdnUrl + '/games/tournament/tour-time-green.png',
    tournamentPrizeGold: cdnUrl + '/games/tournament/gold_medal.png',
    tournamentPrizeCopper: cdnUrl + '/games/tournament/copper_medal.png',
    tournamentPrizeSilver: cdnUrl + '/games/tournament/silver_medal.png',
    tournamentPrizeIron: cdnUrl + '/games/tournament/iron_medal.png',
};
