class PositionsHelper {
  getRelativePosition(element) {
    let offsetLeft = 0;
    let offsetTop = 0;
    do {
      if (!isNaN(element.offsetLeft)) {
        offsetLeft += element.offsetLeft;
      }
      if (!isNaN(element.offsetTop)) {
        offsetTop += element.offsetTop;
      }
    } while ((element = element.offsetParent));

    return {
      left: offsetLeft,
      top: offsetTop,
    };
  }
}

export default new PositionsHelper();
