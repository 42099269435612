import React,{ useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FollowedContent from '../followed-content/followed-content';
import LeaderBoardContent from '../leaderboard-content/leaderboard-content';
import { openPopups } from '../../../../redux/popup.slice';
import PopupsEnum from '../../../../enums/popups.enum';

import './dashboard-content.scss';

function groupFn(group, itemIndex) {
    let obj = { divClass: '' };
    for (const item of group) {
        if (
            item.min === item.max &&
            itemIndex + 1 <= item.max &&
            item.max <= 4
        ) {
            if (itemIndex + 1 === 1) {
                return { divClass: 'orange' };
            } else if (itemIndex + 1 === 2) {
                return { divClass: 'grey' };
            } else if (itemIndex + 1 === 3) {
                return { divClass: 'bronze' };
            } else {
                return { divClass: 'hardGrey' };
            }
        } else {
            obj = { divClass: 'black' };
        }
    }
    return obj;
}
const DashboardContent = ({ tourTabIndex }) => {
    const tourData = useSelector((state) => state.global.navigateData);

    const tourGames = tourData.tourData[0];
    const me = useSelector((state) => state.me.data);

    const dispatch = useDispatch();
    const [success, setSuccess] = useState(false);

    const unFollowingUser = async (tourId, userId) => {
        dispatch(
            openPopups({
                popupIds: [PopupsEnum.UNFOLLOW_POPUP],
                popupData: [
                    {
                        userId: userId,
                        tourId: tourId,
                        setSuccess: setSuccess,
                        success: success
                    },
                ],
            })
        );
    };

    return (
        <>
            {tourTabIndex === 0 ? (
                <LeaderBoardContent 
                    tournament={tourGames} 
                    unfollow={unFollowingUser} 
                    success={success}
                />
            ) : (
                <FollowedContent 
                    tournament={tourGames} 
                    unfollow={unFollowingUser} 
                    success={success}
                />
            )}
        </>
    );
};
export default DashboardContent;
