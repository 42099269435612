import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl/lib';
import { useDispatch, useSelector } from 'react-redux';
import { cdnImages } from '../../assets/cdn.assets';

import PopupsEnum from '../../enums/popups.enum';
import routesEnum from '../../enums/routes.enum';
import { navigate } from '../../redux/global.slice';
import { openGamePlayMenu } from '../../redux/menu-game.slice';
import { openPopups } from '../../redux/popup.slice';
import roomSingleton from '../../singletons/room.singleton';
import socketBridge from '../../socket';
import { UserChannelEmittingEvents } from '../../socket/socket.events';
import MenuItem from '../menu/menu-item/menu-item';
import './menu-game-play.scss';

const MenuGamePlay = () => {
    const intl = useIntl();
    const [menuOpened, setMenuOpened] = useState(false);
    const [musicToggleActive, setMusicToggleActive] = useState(false);
    const [soundToggleActive, setSoundToggleActive] = useState(false);
    const [toggleActiveMusic, setToggleActiveMusic] = useState(false);
    const [toggleActiveSound, setToggleActiveSound] = useState(false);
    const dispatch = useDispatch();
    const me = useSelector((state) => state.me);
    const partnerIdData = useSelector((state) => state.userInfo.partnerId);
    const openedState = useSelector(
        (state) => state.userMenuGame.rightMenu.rightMenuOpenedState
    );
    const musicToggle = () => {
        setMusicToggleActive(!musicToggleActive);
    };
    const soundToggle = () => {
        setSoundToggleActive(!soundToggleActive);
    };
    useEffect(() => {
        if (openedState === 1) {
            setMenuOpened(true);
        } else if (openedState === 2) {
            setMenuOpened(false);
            setTimeout(() => {
                dispatch(openGamePlayMenu({ rightMenuOpenedState: 3 }));
            }, 500);
        }
    }, [openedState]);
    const closeMenuGamePlay = () => {
        dispatch(openGamePlayMenu({ rightMenuOpenedState: 3 }));
    };
    const openExitPopup = () => {
        if (!roomSingleton.roomId) {
            socketBridge.emitToUserChannel({
                event: UserChannelEmittingEvents.FORCE_LEAVE_USER,
                partnerId: partnerIdData,
            });

            dispatch(openGamePlayMenu({ rightMenuOpenedState: 3 }));
            dispatch(navigate(routesEnum.LOBBY));
            return;
        } else {
            dispatch(
                openPopups({
                    popupIds: [PopupsEnum.EXIT_GAME],
                })
            );
            dispatch(openGamePlayMenu({ rightMenuOpenedState: 3 }));
        }
    };

    return (
        <div className={`menu_game_play${menuOpened ? ' open' : ''}`}>
            <div className="menu-game-content">
                <img
                    src={cdnImages.menu_angle}
                    alt="menu-angle"
                    className="menu_angle"
                />
                <p className="menu_game_play_text">fx</p>
                <div className="menu_game_play_item">
                    <MenuItem
                        imageId={
                            toggleActiveMusic
                                ? cdnImages.music
                                : cdnImages.music_dis
                        }
                        text={intl.messages.popups.settings.music}
                        toggleActive={toggleActiveMusic}
                        setToggleActive={setToggleActiveMusic}
                    />
                    <MenuItem
                        imageId={`${
                            toggleActiveSound
                                ? cdnImages.sound
                                : cdnImages.sound_dis
                        }`}
                        text={intl.messages.popups.settings.sound}
                        toggleActive={toggleActiveSound}
                        setToggleActive={setToggleActiveSound}
                    />
                </div>
                <div className="menu_game_play_home" onClick={openExitPopup}>
                    <img src={cdnImages.menu_home} alt="menu_home" />
                    <span className="menu_game_play_leave">
                        {intl.messages.gamePlay.endGame.leaveGame}
                    </span>
                </div>
            </div>

            <div
                className="close-menu-game-play"
                onClick={closeMenuGamePlay}
            ></div>
        </div>
    );
};

export default MenuGamePlay;
