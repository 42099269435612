import React from 'react';
import PopupsEnum from '../enums/popups.enum';
import ExitGamePopup from './exit-game-popup/exit-game-popup';
import NotEnoughRed from './not-enough/not-enough-red/not-enough-red';
import './popups.base.scss';
import TournamentResult from './tournament/tournament-result/tournament-result';

const GreenPopup = React.lazy(() =>
    import('./green-chip-popup/green-chip-popup')
);
const SettingsPopup = React.lazy(() =>
    import('./settings-popup/settings-popup')
);
const GiftFormPopup = React.lazy(() =>
    import('./gift-form-popup/gift-form-popup')
);
const GiftCancelPopup = React.lazy(() =>
    import('./gift-form-popup/gift-cancel-popup/gift-cancel-popup')
);
const VipPopup = React.lazy(() => import('./vip-popup/vip-popup'));
const VipInfoPopup = React.lazy(() =>
    import('./vip-popup/vip-info-popup/vip-info-popup')
);
const VipNicknamePopup = React.lazy(() =>
    import('./vip-popup/vip-nickname-popup/vip-nickname-popup')
);
const LevelUpPopup = React.lazy(() =>
    import('./level-up-popup/level-up-popup')
);

const AdminChipPopup = React.lazy(() =>
    import('./admin-chip-popup/admin-chip-popup')
);
const NotEnoughGold = React.lazy(() =>
    import('./not-enough/not-enough-gold/not-enough-gold')
);
const NotEnoughGreen = React.lazy(() =>
    import('./not-enough/not-enough-green/not-enough-green')
);
const BuyChipPopup = React.lazy(() =>
    import('./buy-chip-popup/buy-chip-popup')
);
const TournamentUnlock = React.lazy(() =>
    import('./tournament-unlock-popup/tournament-unlock-popup')
);
const TournamentTable = React.lazy(() =>
    import('./tournament/tournament-table/tournament-table')
);
const UnfollowPopup = React.lazy(() =>
    import('./tournament/unfollow-popup/unfollow-popup')
);
const ExitGame = React.lazy(() => import('./exit-game-popup/exit-game-popup'));

const _onClose = (closePopup) => {
    closePopup();
};

const getPopup = ({ popupId, popupData, closePopup }) => {
    switch (popupId) {
        case PopupsEnum.GREEN_POPUP:
            return <GreenPopup _onClose={() => _onClose(closePopup)} />;
        case PopupsEnum.SETTINGS_POPUP:
            return <SettingsPopup _onClose={() => _onClose(closePopup)} />;
        case PopupsEnum.GIFT_FORM_POPUP:
            return <GiftFormPopup _onClose={() => _onClose(closePopup)} />;
        case PopupsEnum.VIP_POPUP:
            return <VipPopup _onClose={() => _onClose(closePopup)} />;
        case PopupsEnum.VIP_INFO_POPUP:
            return <VipInfoPopup _onClose={() => _onClose(closePopup)} />;
        case PopupsEnum.GIFT_CANCEL_POPUP:
            return <GiftCancelPopup _onClose={() => _onClose(closePopup)} />;
        case PopupsEnum.VIP_NICKNAME_POPUP:
            return <VipNicknamePopup _onClose={() => _onClose(closePopup)} />;
        case PopupsEnum.LEVEL_UP:
            return (
                <LevelUpPopup
                    _onClose={() => _onClose(closePopup)}
                    popupData={popupData}
                />
            );
        case PopupsEnum.ADMIN_CHIP_POPUP:
            return (
                <AdminChipPopup
                    _onClose={() => _onClose(closePopup)}
                    popupData={popupData}
                />
            );
        case PopupsEnum.NOT_ENOUGH_GOLD:
            return <NotEnoughGold _onClose={() => _onClose(closePopup)} />;
        case PopupsEnum.NOT_ENOUGH_GREEN:
            return <NotEnoughGreen _onClose={() => _onClose(closePopup)} />;
        case PopupsEnum.NOT_ENOUGH_RED:
            return <NotEnoughRed _onClose={() => _onClose(closePopup)} />;

        case PopupsEnum.EXIT_GAME:
            return <ExitGamePopup _onClose={() => _onClose(closePopup)} />;
        case PopupsEnum.BUY_CHIP_POPUP:
            return (
                <BuyChipPopup
                    _onClose={() => _onClose(closePopup)}
                    popupData={popupData}
                />
            );
        case PopupsEnum.TOURNAMENT_UNLOCK:
            return <TournamentUnlock _onClose={() => _onClose(closePopup)} />;
        case PopupsEnum.TOURNAMENT_TABLE:
            return (
                <TournamentTable
                    _onClose={() => _onClose(closePopup)}
                    popupData={popupData}
                />
            );
        case PopupsEnum.UNFOLLOW_POPUP:
            return (
                <UnfollowPopup
                    _onClose={() => _onClose(closePopup)}
                    popupData={popupData}
                />
            );
        case PopupsEnum.TOURNAMENT_RESULT:
            return (
                <TournamentResult
                    _onClose={() => _onClose(closePopup)}
                    popupData={popupData}
                />
            );
        default:
            return <React.Fragment />;
    }
};

const PopupBase = (props) => {
    return (
        <div className="popup_base">
            <div className="dark_back" onClick={props.closePopup} />
            <div className="popup_base_inner">
                <div className="popup">
                    <React.Suspense fallback={<React.Fragment />}>
                        {getPopup(props)}
                    </React.Suspense>
                </div>
            </div>
        </div>
    );
};

export default PopupBase;
