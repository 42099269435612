import React from 'react';
import { useIntl } from 'react-intl';
import './first-start.scss';

const FirstStart = ({ seat = '1' }) => {
    const intl = useIntl();
    return (
        <div className={`first_start_${seat}`}>
            <span>{intl.messages.first}</span>
        </div>
    );
};

export default FirstStart;
