import { createSlice } from '@reduxjs/toolkit';

export const themeColorChange = createSlice({
    name: 'themeChange',
    initialState: {
        theme: 0,
    },
    reducers: {
        tournamentColor: (state, action) => {
            state.theme = action.payload;
        },
    },
});

export const { tournamentColor } = themeColorChange.actions;

export default themeColorChange.reducer;
