import { createSlice } from '@reduxjs/toolkit';
export const gamePlayChipSlice = createSlice({
    name: 'gamePlayChip',
    initialState: {
        red: null,
        yellow: null,
        green: null,
    },
    reducers: {
        setGamePlayChip: (state, action) => {
            action.payload.map((item) => {
                state[item.chip] = item.value;
            });
        },
    },
});
export const { setGamePlayChip } = gamePlayChipSlice.actions;

export default gamePlayChipSlice.reducer;
