import React from 'react';
import './say-button.scss';

const SayButton = ({
    type = 'button',
    text,
    allowUser,
    onClick = () => {},
}) => {
    return (
        <button
            type={type}
            className={`${!allowUser ? 'in-active' : 'say-button'}`}
            onClick={onClick}
        >
            <span>{text}</span>
        </button>
    );
};
export default SayButton;
