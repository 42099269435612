import { createSlice } from '@reduxjs/toolkit';

export const usersInfoSlice = createSlice({
    name: 'usersInfo',
    initialState: {
        data: [],
    },
    reducers: {
        setUsersInfo: (state, { payload }) => {
            state.data = payload;
        },
    },
});
export const { setUsersInfo } = usersInfoSlice.actions;

export default usersInfoSlice.reducer;
