import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fbLogin_req } from '../api/user.api';
import routesEnum from '../enums/routes.enum';
import { loadScript } from '../helpers/scripts.helper';
import { navigate } from '../redux/global.slice';
import { getSuccess } from '../redux/me.slice';
import userSingleton from '../singletons/user.singleton';

const SocialWrapper = () => {
    // const zone = getUrlParameter('zone');

    const dispatch = useDispatch();
    const zone = useSelector((state) => state.social.zone);
    const zoneStorage = localStorage.zone;

    const getNeededSocial = useCallback(() => {
        if (zone === 'fb' || zoneStorage === 'fb') {
            const appId = '1703484273232304';
            loadScript('//connect.facebook.net/en_US/sdk.js', () => {
                if (window.FB) {
                    window.FB.init({
                        appId,
                        xfbml: true,
                        version: 'v10.0',
                    });

                    window.FB.getLoginStatus(async (loginStatus) => {
                        if (loginStatus.status === 'connected') {
                            userSingleton.fbUserData = loginStatus;
                            const fbLogin = await fbLogin_req(
                                loginStatus.authResponse.accessToken,
                                loginStatus.authResponse.userID
                            );
                            if (fbLogin) {
                                localStorage.zone = 'fb';
                                localStorage.beloteKings = fbLogin.jwt;
                                dispatch(getSuccess(fbLogin));
                                dispatch(navigate(routesEnum.LOADING));
                            }
                            return;
                        }

                        window.FB.login(
                            (response) => {
                                userSingleton.fbUserData = response;
                            },
                            {
                                scope: 'email,public_profile',
                            }
                        );
                    });
                }
            });
        }
        if (zone === 'vk' || zoneStorage === 'vk') {
            window.location.href =
                'https://api.vk.com/authorize?client_id=6500060&display=page&redirect_uri=http://localhost:3000&scope=friends&response_type=token&v=5.131';

            // loadScript('https://vk.com/js/api/openapi.js?169', () => {
            //     if (window.VK) {
            //         console.log('window', window.VK);
            //         window.VK.init({
            //             apiId: 6500060,
            //         });
            //         window.VK.Widgets.Auth('vk_auth', {
            //             onAuth: function (data) {
            //                 // alert('user ' + data['uid'] + ' authorized');
            //                 console.log('data', data);
            //             },
            //         });
            //     }
            // });
        }
        if (zone === 'ok') {
            const clientId = '1275143424';
            loadScript('//api.ok.ru/js/fapi5.js', () => {
                if (window.OK) {
                    window.OK.init({
                        client_id: clientId,
                        inviter_id: null,
                    });
                }
            });
        }
    }, [zone]);

    useEffect(() => {
        getNeededSocial();
    }, [zone]);

    return <></>;
};

export default SocialWrapper;
