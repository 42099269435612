import React from 'react';
import './static-gift.scss';
import { useSelector } from 'react-redux';

export default function StaticGift({ toUser, giftId }) {
    const allGifts = useSelector((state) => state.gifts.allGifts.gifts);
    const data = allGifts && allGifts.filter((el) => el.id === giftId);
    if (!data || !data.length) {
        return <></>;
    }

    return (
        <div className={'static-gift seat-' + toUser}>
            <img src={data[0].src} alt="gift" />
        </div>
    );
}
