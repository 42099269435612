import React from 'react';
import './red-tab.scss';

import { useIntl } from 'react-intl';
import { cdnImages } from '../../../assets/cdn.assets';

const RedTabPopup = ({ data, buyPurchase }) => {
    const intl = useIntl();
    if (!data || data.length < 1) {
        return null;
    }

    const filter = data.packs.chips.filter((el) => el.chipType === 'red');

    return (
        <div className="red-tab-popup">
            {filter.map((item, index) => (
                <div className="red-tab-item" key={item.id}>
                    <div className="red-tab-bg">
                        <img
                            src={cdnImages.red_tab}
                            className={`${
                                data &&
                                data.discount &&
                                data.discount.discountTimer > 0
                                    ? 'red-tab-discount-img'
                                    : 'red-tab-chip'
                            }`}
                        />
                        <div className="red-tab-chip-block">
                            {data &&
                            data.discount &&
                            data.discount.discountTimer > 0 ? (
                                <div className="store-discount">
                                    {data &&
                                        data.discount &&
                                        data.discount.discountType === 2 && (
                                            <img
                                                src={cdnImages.store_x2}
                                                alt="#"
                                                className="red-tab-discount-img"
                                            />
                                        )}
                                    {data &&
                                        data.discount &&
                                        data.discount.discountType === 3 && (
                                            <img
                                                src={cdnImages.store_x3}
                                                alt="#"
                                                className="red-tab-discount-img"
                                            />
                                        )}
                                    {data &&
                                        data.discount &&
                                        data.discount.discountType === 4 && (
                                            <img
                                                src={cdnImages.store_x4}
                                                alt="#"
                                                className="red-tab-discount-img"
                                            />
                                        )}
                                    {data &&
                                        data.discount &&
                                        data.discount.discountType === 5 && (
                                            <img
                                                src={cdnImages.store_x5}
                                                alt="#"
                                                className="red-tab-discount-img"
                                            />
                                        )}
                                    <div className="red-tab-text-block">
                                        <div className="red-tab-after-block">
                                            <p className="red-tab-text-after">
                                                {item.chipAmount}
                                            </p>
                                            <div className="red-tab-border-block"></div>
                                        </div>
                                        <div className="red-tab-before-block">
                                            <p className="red-tab-text">
                                                {item.chipAmount * data &&
                                                    data.discount &&
                                                    data.discount.discountType}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <p className="red-tab-text">
                                    {item.chipAmount}
                                </p>
                            )}

                            {index === 0 && (
                                <img
                                    src={cdnImages.chip_1000}
                                    className="chip-1000"
                                />
                            )}

                            {index === 1 && <img src={cdnImages.chip_2500} />}
                            {index === 2 && <img src={cdnImages.chip_5500} />}
                            {index === 3 && <img src={cdnImages.chip_15500} />}
                            <div
                                className="red-tab-item-buy-now"
                                onClick={() =>
                                    buyPurchase(
                                        item.id,
                                        true,
                                        item.chipAmount,
                                        item.chipType,
                                        item.amount,
                                        item.currency
                                    )
                                }
                            >
                                <p>{intl.messages.store.buyNow}</p>
                                <span>
                                    {item.currency} {item.amount}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default RedTabPopup;
