import React from 'react';
import { cdnImages } from '../../../assets/cdn.assets';
import { glow_green, glow_red, title_grow } from '../../../assets/image.assets';
import imageLoader from '../../../loaders/image.loader';
import './lobby-name.scss';

const getLobbyRectangle = (type) => {
    switch (type) {
        case 1:
            return {
                img1: glow_green,
            };
        case 2:
            return {
                img1: glow_red,
            };
        case 3:
            return {
                img1: title_grow,
            };
        default:
            return {
                img2: title_grow,
            };
    }
};
const LobbyName = ({ type = 1, text }) => {
    const rectangleLobby = getLobbyRectangle(type);
    return (
        <div className="lobby_name">
            <img
                src={rectangleLobby.img1}
                alt="#"
                className="yellow_rectangle"
            />
            <span className="lobby_text">{text}</span>
        </div>
    );
};

export default LobbyName;
