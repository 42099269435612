import React from 'react';

import './user-panel-avatar.scss';
import { useDispatch } from 'react-redux';
import { setCurrentUserInfo } from '../../../redux/userinfo.slice';

import { cdnImages } from '../../../assets/cdn.assets';
const UserPanelAvatar = ({ userInfo, userLevel }) => {
    const dispatch = useDispatch();
    const openUserInfoMenu = () => {
        dispatch(setCurrentUserInfo(userInfo));
    };

    return (
        <div className={'user_image_info'} onClick={openUserInfoMenu}>
            <img
                className={`user_img${userInfo.vip ? '' : ' img-border'}`}
                src={userInfo.avatar ? userInfo.avatar : cdnImages.user_avatar}
                alt="fb"
            />

            {userInfo.vip && (
                <img className="user-vip-img" src={cdnImages.vip} alt="fb" />
            )}
            <div className="level_wrapper">
                <span>{userLevel}</span>
            </div>
        </div>
    );
};

export default UserPanelAvatar;
