import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import BackgroundPopup from '../../../components/background/background-popup/background-popup';
import { openCloseChat } from '../../../redux/menu-game.slice';
import { RoomChannelEmittingEvents } from '../../../socket/socket.events';
import socketBridge from '../../../socket';
import './chat.scss';

const Chat = () => {
    const [chatOpen, setChatOpen] = useState(false);
    const intl = useIntl();
    const dispatch = useDispatch();
    const openedState = useSelector(
        (state) => state.userMenuGame.chat.chatOpenedState
    );
    const data = [
        { text: intl.messages.gamePlay.chat.cool, id: 1 },
        { text: intl.messages.gamePlay.chat.oops, id: 2 },
        { text: intl.messages.gamePlay.chat.tooBad, id: 3 },
        { text: intl.messages.gamePlay.chat.goodJob, id: 4 },
        { text: intl.messages.gamePlay.chat.noPoints, id: 5 },
        { text: intl.messages.gamePlay.chat.thanks, id: 6 },
    ];
    useEffect(() => {
        if (openedState === 1) {
            setChatOpen(true);
        } else if (openedState === 2) {
            setChatOpen(false);
            setTimeout(() => {
                dispatch(openCloseChat({ chatOpenedState: 3 }));
            }, 500);
        }
    }, [openedState]);
    const chatUser = (id) => {
        socketBridge.emitToRoomChannel({
            event: RoomChannelEmittingEvents.SEND_EMOJI,
            emojiId: id,
            chat: true,
        });
        dispatch(openCloseChat({ chatOpenedState: 3 }));
    };
    const closeChat = () => {
        dispatch(
            openCloseChat({
                chatOpenedState: openedState === 3 ? 1 : 2,
            })
        );
    };
    return (
        <>
            <div className="chat-close" onClick={closeChat}></div>
            <div className={`chat${chatOpen ? ' open' : ''}`}>
                <div className="chat-user">
                    <BackgroundPopup>
                        <p>{intl.messages.gamePlay.chatUser}</p>
                        <div className="menu-gradline"></div>
                        <div className="chat-block">
                            {data.map((item) => (
                                <div
                                    className="chat-data"
                                    key={item.id}
                                    onClick={() => chatUser(item.id)}
                                >
                                    <span> {item.text}</span>
                                </div>
                            ))}
                        </div>
                    </BackgroundPopup>
                </div>
            </div>
        </>
    );
};

export default Chat;
