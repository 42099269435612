import React from 'react';
import { useIntl } from 'react-intl';
import Timer from '../../../../components/timer/timer';
import './tournament-register-timer.scss';

const TournamentRegisterTimer = ({ endDate }) => {
    const intl = useIntl();
    return (
        <div className="tournament-register-timer">
            <div className="tournament-timer-block">
                <Timer endDate={endDate} />
            </div>
            <div className="tournament-text-block">
                <span className="tournament-day-text">
                    
                    {intl.messages.tournament.days}
                </span>
                <span>
                    {intl.messages.tournament.hours}
                </span>
                <span className="tournament-min-text">
                    {intl.messages.tournament.min}
                </span>
                <span>
                    {intl.messages.tournament.sec}
                </span>
            </div>
        </div>
    );
};
export default TournamentRegisterTimer
